import React, { useEffect,useState } from 'react';
import PaymentHistory from '../PaymentMore/PaymentCalender';

const PaymentModal = () => {
  const [paymentData, setPaymentData] = useState([]);
  const [currentYear, setCurrentYear] = useState(null);
  const [history48Months,setHistory48Months] = useState([]);
  const [accounts,setAccounts] = useState([]);
  console.log(accounts)
  useEffect(() => {
    // fetch('/sample_payment_history.json')
    //   .then(response => response.json())
    //   .then(data => {
    //     console.log(data);
    //     const years = [...new Set(data.map(item => item.year))];
    //     setCurrentYear(years[0]);
    //     setPaymentData(data);
    //   });
      fetch('/CibilScore.json')
      .then(response => response.json())
      .then(data => {
        const filterAccounts = data.credit_report.CCRResponse.CIRReportDataLst[0].CIRReportData.RetailAccountDetails.map((item)=>({
            AccountNumber:item.AccountNumber,
            BankName:item.Institution,
            AccountType:item.AccountType,
            Active:item.Open,
            PendingAmount:item.Balance,
            LastPaymentDate:item.LastPaymentDate,

        }))
        const filterAccounts1 = data.credit_report.CCRResponse.CIRReportDataLst[0].CIRReportData.RetailAccountDetails.filter((item) => item.Open == "Yes").map((item)=>({
          AccountNumber:item.AccountNumber,
          tenure:item.RepaymentTenure,
          emi:item.InstallmentAmount,
          BankName:item.Institution,
          AccountType:item.AccountType,
          Active:item.Open,
          PendingAmount:item.Balance,
          LastPaymentDate:item.LastPaymentDate,
          issueOn:item.DateOpened,
          TotalLoan:item.SanctionAmount,
          interestRate:item.InterestRate,
          history:item.History48Months
      }))
        setPaymentData(filterAccounts1);
        setAccounts(filterAccounts);

      });
  }, []);

  return (
    <div className="modal fade" id="paymentmodal" tabIndex="-1" aria-labelledby="paymentModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="paymentModalLabel">Payment Details</h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          {paymentData.map((paymentData, index) => (
              <div key={index} className="loan-summary">
                <div className="row border-bottom pb-3">
                  <div className="col-lg-6">
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <img src="https://placehold.co/80x80/EEE/31343C" alt="..." />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h4 className="mb-0">{paymentData.BankName}</h4>
                        <p className="mb-0 text-muted">{paymentData.loanType}: {paymentData.AccountNumber}</p>
                        <p className="text-muted mb-0">Interest Rate: <strong className="text-primary">{paymentData.interestRate}</strong></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 text-lg-end pt-2 pt-lg-0">
                    <h5 className="mb-0">{paymentData.AccountType}</h5>
                    <p className="mb-0 text-muted">Issued on: {paymentData.issueOn}</p>
                    <button type="button" className="btn btn-primary btn-sm">{paymentData.Active=="Yes" ? "Active" : "closed"}</button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-3 mb-3">
                    <h4>Loan Particulars</h4>
                  </div>
                  <div className="col-lg-6">
                    <h5 className="text-muted">Outstanding</h5>
                    <p>{paymentData.PendingAmount}</p>
                  </div>
                  <div className="col-lg-6">
                    <h5 className="text-muted">Loan Amount</h5>
                    <p>{paymentData.TotalLoan}</p>
                  </div>
                  <div className="col-lg-6">
                    <h5 className="text-muted">EMI Amount</h5>
                    <p>{paymentData.emi}</p>
                  </div>
                  <div className="col-lg-6">
                    <h5 className="text-muted">Tenure</h5>
                    <p>{paymentData.tenure} Months</p>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <p className="text-muted">Last Updated on <strong>{paymentData.LastPaymentDate}</strong> as reported by <strong> CIBIL bureau </strong></p>
                  </div>
                </div>
                <PaymentHistory historyData = {paymentData.history}/>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
