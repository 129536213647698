import React,{useState} from 'react';
import axios from 'axios'
const PaymentComponent=()=>{
    const [paymentId, setPaymentId] = useState(null);
    const initializeRazorpay = async () => {
        // Load Razorpay script dynamically
        // await loadScript('https://checkout.razorpay.com/v1/checkout.js');
        // Fetch a demo order from the server
        const data=await axios.post('http://localhost:3004/api/orders',{amount:1});
        console.log(data.data);  
        initPayment(data.data);  
      };

    const initPayment = (data) =>{
        const options ={
            key:'rzp_live_MpsNui8coFW1Ft',
            amount:data.data.amount,
            currency: data.data.currency,
            name:'books',
            description:'Study',
            order_id:data.data.id,
            handler:async(response)=>{
                try{
                const verifyUrl="http://localhost:3004/api/verifypayment";
                const {data} =await axios.post(verifyUrl,response);
                console.log(data);
                } catch(err){
                    console.log(err);
                }
            },
            theme:{
                color:"#ff7529",
            }
        };
        const rzp1=new window.Razorpay(options);
        rzp1.open();
    }
    return (
        <div className="price-card">
      <h2>Demo Product</h2>
      <p>Price: 500</p>
      {paymentId && <p>Payment successful. Payment ID: {paymentId}</p>}
      <button onClick={initializeRazorpay}>Pay Now</button>
    </div>
    )
}
export default PaymentComponent;