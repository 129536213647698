import React, { useRef, useState} from "react";
import bgLogo from "./../../../images/logo-bg.png";
import bgLogoWhite from "./../../../images/logo-white-bg.png";
import { useSelector, useDispatch } from "react-redux";
import VerifyButton from "./verifyButton";
import http from "./../../../services/httpService";
import auth from "./../../../services/authService";
import { changeStage } from "../../../features/verificationStage/verificationSlice";
import useLanguage from "../../../local/saveLanguage";

const MidEmailOTP = ({ children }) => {
  // const theme = useSelector((state) => state.theme.theme);
  const email = useSelector((state) => state.email.email);
  const [otpEr, setOtpEr] = useState(false);

  const dispatch = useDispatch();
  const translate = useLanguage.getLang();
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  console.log(inputRefs);

  const handleKeyDown = (index, e) => {
    // console.log(inputRefs[0].current.value);
    setOtpEr(false);
    if (e.key === "Backspace" && index > 0 && e.target.value === "") {
      const prevIndex = index-1;
      inputRefs[prevIndex].current.focus();
    } else if (e.key >= "0" && e.key <= "9") {
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.length) {
        inputRefs[index].current.value = e.key;
        inputRefs[nextIndex].current.focus();
      }
    } 
    else if (
      index === 0 &&
      e.key !== "Backspace" &&
      (e.key < "0" || e.key > "9")
    ) {
      // Prevent typing in the first input field if it's not a number or Backspace
      inputRefs[0] = e.key;
      e.preventDefault();
    }
  };

  const handleSubmit = async() => {
    // console.log(email);
    // dispatch(changeStage("pan"));
    // e.preventDefault();
    let otp=inputRefs.map(ref=>ref.current.value).join("");
    console.log(otp);
    try {
      const response = await http.post2("/signinUser", {
        email,
        otp,
      });
      console.log("try part");
      auth.login(response.data.token);
      // setUser(auth.getUser().UserLogin);
      console.log("If condition");
       dispatch(changeStage("pan"));

      // if (auth.getUser().UserLogin.accountStatus === "active") {
      //   console.log(auth.getUser().UserLogin);
      //   // navigate("/ndashboard");
      //   window.location.reload();
      // }
    } catch (error) {
      console.log("errorpart");
      setOtpEr(true);
      console.error("Error:", error);
    }
  };
  console.log(email);

  return (
    <div className="login-mid">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 login-mid-left">
            <h2>
              <span data-translate="tran-1">
                {translate?.["Good Afternoon"] ?? "Good Afternoon"}
              </span>{" "}
              Ankit!
            </h2>
            <p data-translate="tran-2">
              {translate?.["Account security starts with verification"] ??
                "Account security starts with verification"}
            </p>
          </div>
          {/* <!-- /.col-lg-6 --> */}
          <div className="col-xl-5 col-lg-6 offset-xl-1">
            <div className="card pb-normal">
              <div className="vf-content">
                <h1 data-translate="tran-3">
                  {translate?.["OTP Validation for Account Access"] ??
                    "OTP Validation for Account Access"}
                </h1>
                <p data-translate="tran-4">
                  {translate?.[
                    "Kindly check your inbox, and enter the OTP in the designated field to complete the verification process."
                  ] ??
                    "Kindly check your inbox, and enter the OTP in the designated field to complete the verification process."}
                </p>
                <p data-translate="tran-7">
                  {translate?.[
                    "For any queries or assistance, feel free to call us at +91-9699900111."
                  ] ??
                    "For any queries or assistance, feel free to call us at +91-9699900111."}
                </p>
                <p>
                  <strong data-translate="tran-8">
                    {translate?.[
                      "Thank you for entrusting PrivateCourt. Your security is our priority."
                    ] ??
                      "Thank you for entrusting PrivateCourt. Your security is our priority."}
                  </strong>
                </p>
              </div>
              <div className="otp-boxs d-flex flex-row justify-content-center">
                {[0, 1, 2, 3, 4, 5].map((index) => (
                  <input
                    key={index}
                    className="text-center form-control rounded"
                    onKeyUp={(e) => handleKeyDown(index, e)}
                    type="text"
                    id={`digit-${index}`}
                    maxLength="1"
                    ref={inputRefs[index]}
                  />
                ))}
              </div>
              <p className="text-end mb-0 pt-2">
                <span data-translate="tran-5">
                  {translate?.["Didn't receive OTP?"] ?? "Didn't receive OTP?"}
                </span>{" "}
                <strong className="pe-auto" data-translate="tran-6">
                  {translate?.["Request a Resend"] ?? "Request a Resend"}
                </strong>
              </p>
              {
                otpEr && <div class="alert alert-danger vfe-content" role="alert">
                    <div class="text-center">
                      <i class="bi bi-exclamation-triangle-fill text-danger"></i>
                    </div>
                    <h4 class="alert-heading" data-translate="tran-10">Error: Incorrect OTP</h4>
                    <p data-translate="tran-11">We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.</p>
                    <p data-translate="tran-12">If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team  at +91-9699900111.</p>
                    <p data-translate="tran-13">Double-check the email and try entering the OTP again.</p>
                    <p><strong data-translate="tran-14">Thank you for entrusting PrivateCourt. Your security is our priority.</strong></p>
              </div>
              }

              <div className="ms-auto">
                <VerifyButton btnName="Verify" onSubmit={handleSubmit} />
              </div>
            </div>
          </div>
          {/* <!-- /.col-lg-6 --> */}
        </div>
        {/* <!-- /.row --> */}
      </div>
      {/* <!-- /.container --> */}
    </div>
  );
};

export default MidEmailOTP;
