import {createSlice} from "@reduxjs/toolkit";

const initialState={
    theme:true,//true represent white theme
}

//createSlice is a function that accepts an initial state, an object of reducer functions, and a "slice name", and automatically generates action creators and action types that correspond to the reducers and state.
export const themeSlice=createSlice({
    name:"theme",
    initialState,
    reducers:{
        changeTheme:(state, action)=>{
            state.theme=!state.theme;
        }
    }
});

export const {changeTheme}=themeSlice.actions;

export default themeSlice.reducer;