import Profile from "../../images/icons/profile.svg";
import Share from "../../images/icons/share.svg";
import ProfileUser from "./components/ProfileUser";
import "../../style.css";
import MainGraph from "./components/MainGraph";
import { useEffect, useState } from "react";
import axios from "axios";
import PaymentHistory from "./PaymentMore/PaymentCalender";
import PaymentModal from "./components/PaymentModal";
import AccountModal from "./components/AccountsModal";
import AgeModal from "./components/AgeModal";
import ProfileModal from "./components/ProfileModal";
import GSTUser from "./components/GSTUser";
import GSTPaymentModal from "./components/GSTPaymentModal";
import companyGraph from './components/CompanyGraph'
import BarChart from "./components/CompanyGraph";
import CompanyProfileModal from "./components/CompanyProfile";
import CompanyAgeModal from "./components/CompanyAgeModal";
import CompanyAccounts from "./components/CompanyAccounts";
const UserProfile = () => {
  const [data, setData] = useState({});
  const [active,setActive] =useState('');
  const [filingData,setFilingData] = useState([]);
  const [date,setDate] = useState('');
  const [companyData,setCompanyData]=useState([]);
  const calculateDateDifference = (dateString) => {
    console.log(dateString)
    const regex = /(\d{2}-\d{2}-\d{4})/;
    const match = dateString.match(regex);
  
    if (match) {
      const dateParts = match[0].split('-');
      const date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
  
      const now = new Date();
      let years = now.getFullYear() - date.getFullYear();
      let months = now.getMonth() - date.getMonth();
      let days = now.getDate() - date.getDate();
  
      if (days < 0) {
        months -= 1;
        days += new Date(now.getFullYear(), now.getMonth(), 0).getDate();
      }
  
      if (months < 0) {
        years -= 1;
        months += 12;
      }
  
      return { years, months, days };
    }
  
    return { years: 0, months: 0, days: 0 };
  };
  const callData = async () => {
    try {
      const response = await axios.post("http://localhost:3004/api/readJson");
      setData(response.data); // Update state with fetched data
      const data = response.data.companies_list.filter((item) => item.companyStatus == "Active");
      setCompanyData(data);
    } catch (error) {
      alert("Something went wrong"); // Handle error
    }
  };
  // useEffect to call callData() when component mounts
  const BasicDetailsVerified = () => {
    const { aadhaar, address, bank, phone, pan_number } = data;
    console.log(aadhaar, address, bank, phone, pan_number);
    const missingFields = [];
    if (!aadhaar) missingFields.push('aadhaar');
    if (!address) missingFields.push('address');
    if (!bank) missingFields.push('bank');
    if (!phone) missingFields.push('phone');
    if (!pan_number) missingFields.push('pan_number');
  
    if (missingFields.length > 0) {
      return {
        status: `The following fields are missing: ${missingFields.join(', ')}`,
        allFieldsPresent: false,
        missingFields: missingFields
      };
    } else {
      return {
        status: 'All fields are present.',
        allFieldsPresent: true,
        missingFields: []
      };
    }
  };
  const VerifiedStatus = BasicDetailsVerified();
  useEffect(() => {
    callData();
  }, []);

  useEffect(() => {
    fetch('/CibilScore.json')
    .then(response => response.json())
    .then(data => {
      const filterAccounts = data.credit_report.CCRResponse.CIRReportDataLst[0].CIRReportData.RetailAccountDetails.map((item)=>({
          history:item.History48Months
      }))
      setFilingData(filterAccounts);
      const filterAccounts1 = data.credit_report.CCRResponse.CIRReportDataLst[0].CIRReportData.RetailAccountsSummary.OldestAccount;
      setActive(data.credit_report.CCRResponse.CIRReportDataLst[0].CIRReportData.RetailAccountsSummary.NoOfActiveAccounts);
      setDate(filterAccounts1);
    });
}, []);
const combinedFilingData = filingData?.flatMap(item => Object.values(item).flat());
console.log(combinedFilingData)
const calculatePayment = () => {
   let totalcount = 0;
   let paidcount = 0;
   combinedFilingData.map((item,index)=>{
    if(item.PaymentStatus == "STD" || item.PaymentStatus == "000" || item.PaymentStatus == "CLSD"){
      paidcount++;
    }
    totalcount++;
   })
   const percent = (paidcount/totalcount) * 100;
   return {percent,paidcount,totalcount};
}
const dateDifference = calculateDateDifference(date);
  return (
    <div class="main-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-3">
            <div class="left-wrapper">
              <nav class="navbar top-menu p-0">
                <div class="container-fluid">
                  <h1 class="logo">
                    PRIVATE<span>COURT</span>
                  </h1>
                  <div>
                    <div class="dropdown">
                      <a
                        class="dropdown-toggle shadow show"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-expanded="true"
                        style={{
                          backgroundImage: `url('https://placehold.co/100x100/EEE/31343C')`,
                        }}
                      >
                        <span class="position-absolute p-2 offline rounded-circle">
                          <span class="visually-hidden">New alerts</span>
                        </span>
                      </a>
                      <ul
                        class="dropdown-menu dropdown-menu-end shadow border-0"
                        data-bs-popper="static"
                      >
                        <li>
                          <a class="dropdown-item" href="#">
                            Dashboard
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            Cases
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            Sales
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
              <h2 class="admin-name">uxnishu@gmail.com</h2>
              <button type="button" class="btn btn-light btn-sm back-btn mb-3">
                <i class="bi bi-arrow-left"></i> Back
              </button>
              <h3 class="small-des">You’ve an upcoming hearing in 3 hrs</h3>
              <button
                class="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none mb-3"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Menu
              </button>
              <div class="collapse d-lg-block" id="collapseExample">
                <div class="dot-bg">
                  <h2 class="d-lg-block d-none">Notifications</h2>
                  <p>We have updated your profile on 10th of June 2024.</p>
                  <div class="row row-cols-2">
                    <div class="col">
                      <a href="#" class="white-circle">
                        <img src={Profile} alt="" />
                        <h2>Update Your Profile</h2>
                      </a>
                    </div>
                    <div class="col">
                      <a href="#" class="white-circle">
                        <img src={Share} alt="" />
                        <h2>Share Your Profile</h2>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-9">
            <div class="right-wrapper">
              <div class="profile-business-info profile-business-info-2">
                <div class="row">
                  <div class="col-lg-12">
                    <ProfileUser user={data} />
                  </div>
                  <div class="col-lg-12">
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header details-analysis-header">
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Details Analysis
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          class="accordion-collapse collapse show"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <div class="row">
                              <div class="col-lg-6">
                                <ul class="list-unstyled">
                                  <li class="list-inline-item">
                                    {
                                      VerifiedStatus.allFieldsPresent ?                   
                                      <i class="bi bi-patch-check-fill"></i>
                                      :
                                      <i class="bi bi-patch-check text-muted"></i>
                                    }
                                     &nbsp; Basic Details Verified
                                  </li>
                                  <li class="list-inline-item">
                                    <i class={`bi ${VerifiedStatus.allFieldsPresent ? 'bi-patch-check-fill' : 'bi-patch-check text-muted'}`}></i>
                                     &nbsp;{VerifiedStatus.status}
                                  </li>
                                </ul>
                                <MainGraph cibil={data.cibil} />
                              </div>
                              <div class="col-lg-6">
                                <div class="pt-5">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="card border-0 shadow details-analysis-card">
                                        <div class="card-body">
                                          <h5 class="card-title mb-0">
                                            Payment
                                          </h5>
                                          <p class="card-text">Highly Impact</p>
                                          <a
                                            href="javascript: void(0)"
                                            data-bs-toggle="modal"
                                            data-bs-target="#paymentmodal"
                                            class="stretched-link card-payment"
                                          ></a>
                                        </div>
                                        <div class="card-footer">
                                          <p style={{color:"#fff"}}>Timely payments</p>
                                          <h3>{calculatePayment().percent}%</h3>
                                          <i class="bi bi-chevron-right"></i>
                                          <a
                                            href="javascript: void(0)"
                                            data-bs-toggle="modal"
                                            data-bs-target="#paymentmodal"
                                            class="stretched-link card-payment"
                                          ></a>
                                          <PaymentModal />
                                        </div>
                                      </div>
                                    </div>
                                  <div class="col-md-6">
                                      <div class="card border-0 shadow details-analysis-card">
                                        <div class="card-body">
                                          <h5 class="card-title mb-0">
                                            Age
                                          </h5>
                                          <p class="card-text">Highly Impact</p>
                                          <a
                                            href="javascript: void(0)"
                                            data-bs-toggle="modal"
                                            data-bs-target="#ageModel"
                                            class="stretched-link card-payment"
                                          ></a>
                                        </div>
                                        <div class="card-footer">
                                          <p style={{color:"#fff"}}>Timely payments</p>
                                          <h3>{dateDifference.years > 15 ? '90%' : dateDifference.years > 10 ? '80%' : dateDifference.years > 5 ? '60%' : 'less than 60%' }</h3>
                                          <i class="bi bi-chevron-right"></i>
                                          <a
                                            href="javascript: void(0)"
                                            data-bs-toggle="modal"
                                            data-bs-target="#ageModel"
                                            class="stretched-link card-payment"
                                          ></a>
                                          <AgeModal />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="card border-0 shadow details-analysis-card">
                                        <div class="card-body">
                                          <h5 class="card-title mb-0">
                                            Accounts
                                          </h5>
                                          <p class="card-text">{active > 2 ? 'High' : active > 1 ? 'Medium' : 'Low'} Impact</p>
                                        </div>
                                        <div class="card-footer">
                                          <p style={{color:"#fff"}}>Timely payments</p>
                                          <h3>{active} Active</h3>
                                          <i class="bi bi-chevron-right"></i>
                                          <a
                                            href="javascript: void(0)"
                                            data-bs-toggle="modal"
                                            data-bs-target="#accountsmodal"
                                            class="stretched-link card-accounts"
                                          ></a>
                                          <AccountModal PaymentStatus={calculatePayment()} />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="card border-0 shadow details-analysis-card">
                                        <div class="card-body">
                                          <h5 class="card-title mb-0">
                                            Profile
                                          </h5>
                                          <p class="card-text">Low Impact</p>
                                        </div>
                                        <div class="card-footer">
                                          <p style={{color:"#fff"}}>Timely payments</p>
                                          <h3>{active}</h3>
                                          <i class="bi bi-chevron-right"></i>
                                          <a
                                           href="javascript: void(0)"
                                            data-bs-toggle="modal"
                                            data-bs-target="#profileModal"
                                            class="stretched-link card-accounts"
                                          ></a>
                                          <ProfileModal active = {active}/>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               {companyData.map((item,index) => (
                <div class="row">
                  <div class="col-lg-12">
                    <GSTUser user={item} />
                  </div>
                  <div class="col-lg-12">
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header details-analysis-header">
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapseOne${index}`}
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Details Analysis
                          </button>
                        </h2>
                        <div
                          id={`collapseOne${index}`}
                          class="accordion-collapse collapse show"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <div class="row">
                              <div class="col-lg-6">
                                <ul class="list-unstyled">
                                  <li class="list-inline-item">
                                    <i class="bi bi-patch-check-fill"></i> Basic
                                    Details Verified
                                  </li>
                                  <li class="list-inline-item">
                                    <i class="bi bi-patch-check text-muted"></i>{" "}
                                    Need to Verify Address
                                  </li>
                                </ul>
                                <BarChart user={item} />
                              </div>
                              <div class="col-lg-6">
                                <div class="pt-5">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="card border-0 shadow details-analysis-card">
                                        <div class="card-body">
                                          <h5 class="card-title mb-0">
                                            Payment
                                          </h5>
                                          <p class="card-text">Highly Impact</p>
                                          <a
                                            href="javascript: void(0)"
                                            data-bs-toggle="modal"
                                            data-bs-target="#paymentmodal"
                                            class="stretched-link card-payment"
                                          ></a>
                                        </div>
                                        <div class="card-footer">
                                          {/* <p style={{color:"#fff"}}>Timely payments</p> */}
                                          <h6 style={{color:"#fff"}}>Check Status..</h6>
                                          <i class="bi bi-chevron-right"></i>
                                          <a
                                            href="javascript: void(0)"
                                            data-bs-toggle="modal"
                                            data-bs-target="#gstpaymentmodal"
                                            class="stretched-link card-payment"
                                          ></a>
                                          <GSTPaymentModal companyData={companyData}/>
                                        </div>
                                      </div>
                                    </div>
                                  <div class="col-md-6">
                                      <div class="card border-0 shadow details-analysis-card">
                                        <div class="card-body">
                                          <h5 class="card-title mb-0">
                                            Age
                                          </h5>
                                          <p class="card-text">Highly Impact</p>
                                          <a
                                            href="javascript: void(0)"
                                            data-bs-toggle="modal"
                                            data-bs-target="#companyAgeModel"
                                            class="stretched-link card-payment"
                                          ></a>
                                        </div>
                                        <div class="card-footer">
                                          {/* <p style={{color:"#fff"}}>Timely payments</p> */}
                                          <h6>Check Age..</h6>
                                          <i class="bi bi-chevron-right"></i>
                                          <a
                                            href="javascript: void(0)"
                                            data-bs-toggle="modal"
                                            data-bs-target="#companyAgeModel"
                                            class="stretched-link card-payment"
                                          ></a>
                                          <CompanyAgeModal user={item} />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="card border-0 shadow details-analysis-card">
                                        <div class="card-body">
                                          <h5 class="card-title mb-0">
                                            Accounts
                                          </h5>
                                          <p class="card-text">Low Impact</p>
                                        </div>
                                        <div class="card-footer">
                                          {/* <p style={{color:"#fff"}}>Timely payments</p> */}
                                          <h6 style={{color:"#fff"}}>Check Accounts..</h6>
                                          <i class="bi bi-chevron-right"></i>
                                          <a
                                            href="javascript: void(0)"
                                            data-bs-toggle="modal"
                                            data-bs-target="#companyAccount"
                                            class="stretched-link card-accounts"
                                          ></a>
                                          <CompanyAccounts user={item} />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="card border-0 shadow details-analysis-card">
                                        <div class="card-body">
                                          <h5 class="card-title mb-0">
                                            Profile
                                          </h5>
                                          <p class="card-text">Low Impact</p>
                                        </div>
                                        <div class="card-footer">
                                          {/* <p style={{color:"#fff"}}>Timely payments</p> */}
                                          <h6 style={{color:"#fff"}}>Check Profile..</h6>
                                          <i class="bi bi-chevron-right"></i>
                                          <a
                                           href="javascript: void(0)"
                                            data-bs-toggle="modal"
                                            data-bs-target="#companyprofile"
                                            class="stretched-link card-accounts"
                                          ></a>
                                          <CompanyProfileModal user={item} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
               ))}
                
              </div>
            </div>
          </div>
        </div>
        <footer class="dashboard-footer">
          <div class="container-fluid">
            <div class="d-flex justify-content-xl-between">
              <div class="ask-person d-xl-inline d-none">
                <div class="profile-pic">
                  <img src="images/ask-pic.png" alt="" />
                </div>
                <button type="button">
                  Ask <span>Murthy</span>
                </button>
              </div>
              <div class="logo-search align-self-baseline d-xl-flex d-none">
                <div class="d-flex">
                  <div class="text-logo">
                    <h1>
                      PRIVATE<span>COURT</span>
                    </h1>
                    <p>
                      The International Court{" "}
                      <span class="text-primary">of</span>{" "}
                      <strong>ARBITRATION</strong>
                    </p>
                  </div>
                  <form action="#" class="search">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search Documents / Knowledgebase"
                    />
                  </form>
                </div>
              </div>
              <div class="d-flex justify-content-xl-between">
                <a href="#" class="logout-btn">
                  <img src="images/icons/logout.svg" alt="" />
                </a>
                <a
                  href="javascript:void(0)"
                  class="float-end menu-logo"
                  onclick="openNav()"
                >
                  <img src="images/mobile-menu.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default UserProfile;
