import Hindi from './TranslationLang/Hindi'
import Bengali from './TranslationLang/Bengali';
import Gujarati from './TranslationLang/Gujarati';
import Kannada from './TranslationLang/Kannada';
import Malayalam from './TranslationLang/Malayalam';
import Marathi from './TranslationLang/Marathi';
import Odia from './TranslationLang/Odia';
import Tamil from './TranslationLang/Tamil';
import Telugu from './TranslationLang/Telugu';
import English from './TranslationLang/English(UK)';

const getLanguage=(lang)=>{
    if(lang=="Hindi"){
       return Hindi;
    } 
    else if(lang=="Bengali"){
        return Bengali;
    }
    else if(lang=="Gujarati"){
        return Gujarati;
    }
    else if(lang=="Kannada"){
        return Kannada;
    }
    else if(lang=="Malayalam"){
        return Malayalam;
    }
    else if(lang=="Marathi"){
        return Marathi;
    }
    else if(lang=="Telugu"){
        return Telugu;
    }
    else if(lang=="Tamil"){
        return Tamil;
    }
    else if(lang=="Odia"){
        return Odia;
    }
    else if(lang=='English(UK)'){
        return English;
    }
}


export default getLanguage;