import React, { useRef, useState } from "react";
import VerifyButton from "./verifyButton";
import { useDispatch } from "react-redux";
import { changeStage } from "../../../features/verificationStage/verificationSlice";
import useLanguage from "../../../local/saveLanguage";
import http from "./../../../services/httpService";
import auth from "./../../../services/authService";
// import { useRef } from 'react';

const MidPan = () => {
  const translate = useLanguage.getLang();
  const dispatch = useDispatch();
  const [panEr, setPanEr] = useState(false);
  const [user, setUser] = useState(auth.getUser().UserLogin);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  console.log(inputRefs);

  const handleKeyDown = (index, e) => {
    // console.log(inputRefs[0].current.value);
    setPanEr(false);
    const key = e.key.toUpperCase(); // Convert the pressed key to uppercase
    const isNumberOrCapitalLetter = /^[0-9A-Z]$/i.test(key); // Check if the key is a number or a capital letter

    if (e.key === "Backspace" && index > 0 && e.target.value === "") {
      const prevIndex = index - 1;
      inputRefs[prevIndex].current.focus();
    } else if (isNumberOrCapitalLetter) {
      // Check if the key is a number or a capital letter
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.length) {
        inputRefs[index].current.value = key; // Set the value of the current input to the pressed key
        inputRefs[nextIndex].current.focus();
      }
    } else if (
      index === 0 &&
      e.key !== "Backspace" &&
      !isNumberOrCapitalLetter // Prevent typing in the first input field if it's not a number or capital letter
    ) {
      e.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setLoading("Loading...");
    let panno = inputRefs.map((ref) => ref.current.value).join("");
    try {
      const response = await http.post2("/verifyPanGstLinkVerify", {
        panno,
        mid: user.mid,
      });
      //   setLoading("");
      auth.login(response.data.token);
        dispatch(changeStage("aadhaar"));
      //   setUser(auth.getUser().UserLogin);
      //   setWrongAttempts(0);
      // navigate("/dashboard"); // If you are using React Router, you can uncomment this line
    } catch (error) {
      setPanEr(true);
      console.error("Error:", error);
    }
  };

//   const onlyAtoZ = (e) => {
//     const charCode = e.which ? e.which : e.keyCode;
//     if ((charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
//       e.preventDefault();
//     }
//   };

//   const restrictInput = (e) => {
//     const charCode = e.which ? e.which : e.keyCode;
//     if (charCode < 48 || charCode > 57) {
//       e.preventDefault();
//     }
//   };

//   const openUrl = () => {
//     const urlToOpen = "pan-verify-wrong-input.html";
//     window.open(urlToOpen, "_self");
//   };
  // const handleSubmit = () => {
  //     dispatch(changeStage("aadhaar"));
  // }

  return (
    <div className="login-mid">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 login-mid-left">
            <h2 data-translate="tran-1">
              {translate?.["Secure PAN Validation Process"] ??
                "Secure PAN Validation Process"}
            </h2>
            <p data-translate="tran-2">
              {translate?.[
                "This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience."
              ] ??
                "This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience."}
            </p>
          </div>
          <div className="col-lg-6">
            <div className="card pb-normal">
              <div className="vf-content">
                <h1 data-translate="tran-3">
                  {translate?.["Enter Your PAN Number"] ??
                    "Enter Your PAN Number"}
                </h1>
                <p data-translate="tran-4">
                  {translate?.[
                    "Please enter your correct PAN number in the format XXXXX-0000-X."
                  ] ??
                    "Please enter your correct PAN number in the format XXXXX-0000-X."}
                </p>
                <p data-translate="tran-5">
                  {translate?.[
                    "PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings."
                  ] ??
                    "PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings."}
                </p>
                <p data-translate="tran-6">
                  {translate?.[
                    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN."
                  ] ??
                    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN."}
                </p>
                <p data-translate="tran-7">
                  {translate?.[
                    "For any queries or assistance, feel free to call us at +91-9699900111."
                  ] ??
                    "For any queries or assistance, feel free to call us at +91-9699900111."}
                </p>
                <p>
                  <strong data-translate="tran-8">
                    {translate?.[
                      "Thank you for entrusting PrivateCourt. Your security is our priority."
                    ] ??
                      "Thank you for entrusting PrivateCourt. Your security is our priority."}
                  </strong>
                </p>
              </div>
              <div className="otp-boxs d-flex flex-wrap justify-content-center pan-number-box mb-2">
                {[...Array(10)].map((_, index) => (
                  <input
                    key={index}
                    className="text-center text-uppercase form-control rounded"
                    type="text"
                    // onKeyPress={index < 5 ? onlyAtoZ : restrictInput}
                    onKeyUp={(e) => handleKeyDown(index, e)}
                    maxLength="1"
                    ref={inputRefs[index]}
                  />
                ))}
              </div>

              {panEr && (
                <div
                  class="alert alert-danger text-danger vfe-content"
                  role="alert"
                >
                  <div class="text-center">
                    <i class="bi bi-exclamation-triangle-fill text-danger"></i>
                  </div>
                  <h4 class="alert-heading" data-translate="tran-10">
                  {translate?.["Error: Invalid PAN Format"] ??
                    "Error: Invalid PAN Format"}
                  </h4>
                  <p data-translate="tran-11">

                    We regret to inform you that the PAN number entered does not
                    match the required format (XXXXX-0000-X). Please ensure you
                    have entered the correct PAN details and follow the
                    specified format for accurate verification.
                  </p>
                  <p data-translate="tran-12">
                    If you continue to face issues, kindly double-check your PAN
                    card and re-enter the details. For further queries or
                    assistance, feel free to call us at +91-9699900111.
                  </p>
                  <p>
                    <strong data-translate="tran-15">
                      Thank you for entrusting PrivateCourt. Your security is
                      our priority.
                    </strong>
                  </p>
                </div>
              )}
              <div className="ms-auto">
                <VerifyButton btnName="Verify Pan" onSubmit={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MidPan;
