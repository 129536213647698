const kannadaTranslation ={
    'Email Security Check': 'ಇಮೇಲ್ ಭದ್ರತಾ ಪರಿಶೀಲನೆ',
    'Verify Your Email for Account Access': 'ಖಾತೆ ಪ್ರವೇಶಕ್ಕಾಗಿ ನಿಮ್ಮ ಇಮೇಲ್ ಅನ್ನು ಪರಿಶೀಲಿಸಿ',
    "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system": 'ನಿಮ್ಮ ಖಾತೆಯ ಸುರಕ್ಷತೆಯನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು, ದಯವಿಟ್ಟು ನಿಮ್ಮ ಮಾನ್ಯವಾದ ಇಮೇಲ್ ಐಡಿಯನ್ನು ನಿರ್ದಿಷ್ಟಪಡಿಸಿದ ಸ್ವರೂಪದಲ್ಲಿ ನಮೂದಿಸಿ- username@domain.com. ನಿಮ್ಮ ಇಮೇಲ್ ವಿಳಾಸವು ನಮ್ಮ ಸಿಸ್ಟಂನಲ್ಲಿ ಅಸ್ತಿತ್ವದಲ್ಲಿಲ್ಲದಿದ್ದರೆ, ಎ',
    'NEW USER': 'ಹೊಸ ಬಳಕೆದಾರ',
    'account will be created for you': 'ಖಾತೆಯನ್ನು ನಿಮಗಾಗಿ ರಚಿಸಲಾಗುವುದು.',
    'Once submitted, you will receive a Verification OTP (One-Time Password) sent to your provided email address.': 'ಒಮ್ಮೆ ಸಲ್ಲಿಸಿದ ನಂತರ, ನೀವು ಒದಗಿಸಿದ ಇಮೇಲ್ ವಿಳಾಸಕ್ಕೆ ಕಳುಹಿಸಲಾದ ಪರಿಶೀಲನೆ OTP (ಒಂದು-ಬಾರಿ ಪಾಸ್‌ವರ್ಡ್) ಅನ್ನು ನೀವು ಸ್ವೀಕರಿಸುತ್ತೀರಿ.',
    'For any queries or assistance, feel free to call us at +91-9699900111.': 'ಯಾವುದೇ ಪ್ರಶ್ನೆಗಳು ಅಥವಾ ಸಹಾಯಕ್ಕಾಗಿ, +91-9699900111 ನಲ್ಲಿ ನಮಗೆ ಕರೆ ಮಾಡಲು ಮುಕ್ತವಾಗಿರಿ.',
    'Thank you for entrusting PrivateCourt. Your security is our priority.': 'ಖಾಸಗಿ ನ್ಯಾಯಾಲಯವನ್ನು ವಹಿಸಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ನಿಮ್ಮ ಭದ್ರತೆ ನಮ್ಮ ಆದ್ಯತೆಯಾಗಿದೆ.',
    'Verify': 'ಪರಿಶೀಲಿಸಿ',
    'Invalid Email Address': 'ಅಮಾನ್ಯ ಇಮೇಲ್ ವಿಳಾಸ',
    'We regret to inform you that the email address entered is invalid. Please ensure that you have entered a correct and properly formatted email address': 'ನಮೂದಿಸಿದ ಇಮೇಲ್ ವಿಳಾಸವು ಅಮಾನ್ಯವಾಗಿದೆ ಎಂದು ನಿಮಗೆ ತಿಳಿಸಲು ನಾವು ವಿಷಾದಿಸುತ್ತೇವೆ. ದಯವಿಟ್ಟು ನೀವು ಸರಿಯಾದ ಮತ್ತು ಸರಿಯಾಗಿ ಫಾರ್ಮ್ಯಾಟ್ ಮಾಡಲಾದ ಇಮೇಲ್ ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿರುವಿರಿ ಎಂಬುದನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ.',
    'If the issue persists, double-check for any typos or errors in the email format (e.g., username@example.com).': 'ಸಮಸ್ಯೆಯು ಮುಂದುವರಿದರೆ, ಇಮೇಲ್ ಫಾರ್ಮ್ಯಾಟ್‌ನಲ್ಲಿ ಯಾವುದೇ ಮುದ್ರಣದೋಷಗಳು ಅಥವಾ ದೋಷಗಳಿಗಾಗಿ ಎರಡು ಬಾರಿ ಪರಿಶೀಲಿಸಿ (ಉದಾ., username@example.com).',
    'For further queries or assistance, feel free to call us at +91-9699900111.': 'ಹೆಚ್ಚಿನ ಪ್ರಶ್ನೆಗಳು ಅಥವಾ ಸಹಾಯಕ್ಕಾಗಿ, +91-9699900111 ನಲ್ಲಿ ನಮಗೆ ಕರೆ ಮಾಡಲು ಮುಕ್ತವಾಗಿರಿ.',
    'Thank you for entrusting PrivateCourt. Your security is our priority.s': 'ಖಾಸಗಿ ನ್ಯಾಯಾಲಯವನ್ನು ವಹಿಸಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ನಿಮ್ಮ ಭದ್ರತೆ ನಮ್ಮ ಆದ್ಯತೆಯಾಗಿದೆ.',
    'Secure UDYAM Number Validation':'ಸುರಕ್ಷಿತ ಉದ್ಯಮ ಸಂಖ್ಯೆ ಮೌಲ್ಯೀಕರಣ',
    'Enter Your Udyam Number':'ನಿಮ್ಮ ಉದ್ಯಮ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ',
    'Please enter your correct UDYAM Number.':'ದಯವಿಟ್ಟು ನಿಮ್ಮ ಸರಿಯಾದ ಉದ್ಯಮ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ.',
    'Your UDYAM Number allows us to collect crucial details associated with your business. This includes your business address, current status, service details, and organization type.':'ನಿಮ್ಮ ಉದ್ಯಮ ಸಂಖ್ಯೆಯು ನಿಮ್ಮ ವ್ಯಾಪಾರಕ್ಕೆ ಸಂಬಂಧಿಸಿದ ನಿರ್ಣಾಯಕ ವಿವರಗಳನ್ನು ಸಂಗ್ರಹಿಸಲು ನಮಗೆ ಅನುಮತಿಸುತ್ತದೆ. ಇದು ನಿಮ್ಮ ವ್ಯಾಪಾರದ ವಿಳಾಸ, ಪ್ರಸ್ತುತ ಸ್ಥಿತಿ, ಸೇವಾ ವಿವರಗಳು ಮತ್ತು ಸಂಸ್ಥೆಯ ಪ್ರಕಾರವನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ.',
    'Ensuring the accuracy of your UDYAM Number is vital for a seamless verification process.':'ತಡೆರಹಿತ ಪರಿಶೀಲನೆ ಪ್ರಕ್ರಿಯೆಗೆ ನಿಮ್ಮ UDYAM ಸಂಖ್ಯೆಯ ನಿಖರತೆಯನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳುವುದು ಅತ್ಯಗತ್ಯ.',
    'UDYAM':'ಉದ್ಯಮ',
    'Error: Invalid UDYAM Number':'ದೋಷ: ಪರಿಶೀಲಿಸಿ ಸಂಖ್ಯೆ ಅಮಾನ್ಯವಾಗಿದೆ',
    'We regret to inform you that the UDYAM number entered is invalid or incorrect. Please ensure you have entered the correct UDYAM details and double-check for any typos.':'ನಮೂದಿಸಿದ UDYAM ಸಂಖ್ಯೆಯು ಅಮಾನ್ಯವಾಗಿದೆ ಅಥವಾ ತಪ್ಪಾಗಿದೆ ಎಂದು ನಿಮಗೆ ತಿಳಿಸಲು ನಾವು ವಿಷಾದಿಸುತ್ತೇವೆ. ದಯವಿಟ್ಟು ನೀವು ಸರಿಯಾದ UDYAM ವಿವರಗಳನ್ನು ನಮೂದಿಸಿರುವಿರಿ ಮತ್ತು ಯಾವುದೇ ಮುದ್ರಣದೋಷಗಳಿಗಾಗಿ ಎರಡು ಬಾರಿ ಪರಿಶೀಲಿಸಿ.',
    'If you continue to face issues, kindly verify your UDYAM certificate and re-enter the details.':'ನೀವು ಸಮಸ್ಯೆಗಳನ್ನು ಎದುರಿಸುವುದನ್ನು ಮುಂದುವರಿಸಿದರೆ, ದಯವಿಟ್ಟು ನಿಮ್ಮ UDYAM ಪ್ರಮಾಣಪತ್ರವನ್ನು ಪರಿಶೀಲಿಸಿ ಮತ್ತು ವಿವರಗಳನ್ನು ಮರು-ನಮೂದಿಸಿ.',
    'Secure PAN Validation Process':'ಸುರಕ್ಷಿತ PAN ಮೌಲ್ಯೀಕರಣ ಪ್ರಕ್ರಿಯೆ',
    'This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.':'ಈ ಪರಿಶೀಲನಾ ಪ್ರಕ್ರಿಯೆಯನ್ನು ನಮ್ಮ ಸೇವೆಗಳ ಸಮಗ್ರತೆಯನ್ನು ಎತ್ತಿಹಿಡಿಯಲು ವಿನ್ಯಾಸಗೊಳಿಸಲಾಗಿದೆ, ವಿಶ್ವಾಸಾರ್ಹ ಮತ್ತು ಸುರಕ್ಷಿತ ಅನುಭವವನ್ನು ಖಾತ್ರಿಪಡಿಸುತ್ತದೆ.',
    'Enter Your PAN Number':'ನಿಮ್ಮ ಪ್ಯಾನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ',
    'Please enter your correct PAN number in the format XXXXX-0000-X.':'ದಯವಿಟ್ಟು ನಿಮ್ಮ ಸರಿಯಾದ ಪ್ಯಾನ್ ಸಂಖ್ಯೆಯನ್ನು XXXXX-0000-X ಫಾರ್ಮ್ಯಾಟ್‌ನಲ್ಲಿ ನಮೂದಿಸಿ.',
    'PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.':'ನಿಮ್ಮ ಪೂರ್ಣ ಹೆಸರು, ಹುಟ್ಟಿದ ದಿನಾಂಕ, ವಸತಿ ವಿಳಾಸ ಮತ್ತು ಸಂಪರ್ಕ ವಿವರಗಳಂತಹ ನಿಮ್ಮ ಪ್ಯಾನ್‌ಗೆ ಸಂಬಂಧಿಸಿದ ಅಗತ್ಯ ಮಾಹಿತಿಯನ್ನು ಖಾಸಗಿ ನ್ಯಾಯಾಲಯವು ಸೂಕ್ಷ್ಮವಾಗಿ ಸಂಗ್ರಹಿಸುತ್ತದೆ. ವ್ಯಾಪಾರ ಮಾಲೀಕರಿಗಾಗಿ, GST ವಿವರಗಳು, MCA ನಿರ್ದಿಷ್ಟತೆಗಳು ಮತ್ತು GST ರಿಟರ್ನ್ ಫೈಲಿಂಗ್‌ಗಳ ದಾಖಲೆಗಳಂತಹ ನಿಮ್ಮ PAN ಗೆ ಸಂಬಂಧಿಸಿದ ಎಲ್ಲಾ ಸಂಬಂಧಿತ ವ್ಯಾಪಾರ ವಿವರಗಳನ್ನು ಒಳಗೊಳ್ಳಲು ನಾವು ನಮ್ಮ ಡೇಟಾ ಸಂಗ್ರಹಣೆಯನ್ನು ವಿಸ್ತರಿಸುತ್ತೇವೆ.',
    'Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.':'ಹಣಕಾಸಿನ ಒಳನೋಟಕ್ಕಾಗಿ ನಮ್ಮ ಸಿಸ್ಟಂ ನಿಮ್ಮ CIBIL ಸ್ಕೋರ್ ಅನ್ನು ಮೌಲ್ಯಮಾಪನ ಮಾಡುತ್ತದೆ ಮತ್ತು ನಿಮ್ಮ PAN ಗೆ ಸಂಬಂಧಿಸಿದ ಯಾವುದೇ ನಡೆಯುತ್ತಿರುವ ನ್ಯಾಯಾಲಯದ ಪ್ರಕರಣಗಳಿಗೆ ಅಡ್ಡ-ಪರಿಶೀಲಿಸುತ್ತದೆ.',
    'Error: Invalid PAN Format':'ದೋಷ: ಅಮಾನ್ಯ PAN ಫಾರ್ಮ್ಯಾಟ್',
    'We regret to inform you that the PAN number entered does not match the required format (XXXXX-0000-X). Please ensure you have entered the correct PAN details and follow the specified format for accurate verification.':'ನಮೂದಿಸಿದ PAN ಸಂಖ್ಯೆಯು ಅಗತ್ಯವಿರುವ ಸ್ವರೂಪಕ್ಕೆ ಹೊಂದಿಕೆಯಾಗುವುದಿಲ್ಲ ಎಂದು ನಿಮಗೆ ತಿಳಿಸಲು ನಾವು ವಿಷಾದಿಸುತ್ತೇವೆ (XXXXX-0000-X). ದಯವಿಟ್ಟು ನೀವು ಸರಿಯಾದ PAN ವಿವರಗಳನ್ನು ನಮೂದಿಸಿರುವಿರಿ ಎಂಬುದನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ ಮತ್ತು ನಿಖರವಾದ ಪರಿಶೀಲನೆಗಾಗಿ ನಿರ್ದಿಷ್ಟಪಡಿಸಿದ ಸ್ವರೂಪವನ್ನು ಅನುಸರಿಸಿ.',
    'If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111.':'ನೀವು ಸಮಸ್ಯೆಗಳನ್ನು ಎದುರಿಸುವುದನ್ನು ಮುಂದುವರಿಸಿದರೆ, ದಯವಿಟ್ಟು ನಿಮ್ಮ ಪ್ಯಾನ್ ಕಾರ್ಡ್ ಅನ್ನು ಎರಡು ಬಾರಿ ಪರಿಶೀಲಿಸಿ ಮತ್ತು ವಿವರಗಳನ್ನು ಮರು ನಮೂದಿಸಿ. ಹೆಚ್ಚಿನ ಪ್ರಶ್ನೆಗಳು ಅಥವಾ ಸಹಾಯಕ್ಕಾಗಿ, +91-9699900111 ನಲ್ಲಿ ನಮಗೆ ಕರೆ ಮಾಡಲು ಮುಕ್ತವಾಗಿರಿ.',
    'Welcome XXXX':'ಸ್ವಾಗತ XXXX',
    'Account security starts with verification':'ಖಾತೆಯ ಭದ್ರತೆಯು ಪರಿಶೀಲನೆಯೊಂದಿಗೆ ಪ್ರಾರಂಭವಾಗುತ್ತದೆ',
    'OTP Validation for Account Access':'ಖಾತೆ ಪ್ರವೇಶಕ್ಕಾಗಿ OTP ಮೌಲ್ಯೀಕರಣ',
    'Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.':'ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇನ್‌ಬಾಕ್ಸ್ ಅನ್ನು ಪರಿಶೀಲಿಸಿ ಮತ್ತು ಪರಿಶೀಲನೆ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು ಗೊತ್ತುಪಡಿಸಿದ ಕ್ಷೇತ್ರದಲ್ಲಿ OTP ಅನ್ನು ನಮೂದಿಸಿ.',
    "Didn't receive OTP?":'OTP ಸ್ವೀಕರಿಸಲಿಲ್ಲವೇ?',
    'Request a Resend':'ಮರುಕಳುಹಿಸಲು ವಿನಂತಿಸಿ',
    'Verify OTP':'OTP ಪರಿಶೀಲಿಸಿ',
    'Error: Incorrect OTP':'ದೋಷ: ತಪ್ಪಾದ OTP',
    'We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.':'ಅನಾನುಕೂಲತೆಗಾಗಿ ನಾವು ಕ್ಷಮೆಯಾಚಿಸುತ್ತೇವೆ. ನಮೂದಿಸಿದ ಒನ್-ಟೈಮ್ ಪಾಸ್‌ವರ್ಡ್ (OTP) ತಪ್ಪಾಗಿದೆ. ನಿಮ್ಮ ಇಮೇಲ್‌ನಲ್ಲಿ ಸ್ವೀಕರಿಸಿದ OTP ಯನ್ನು ನೀವು ನಿಖರವಾಗಿ ನಮೂದಿಸಿದ್ದೀರಿ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ.',
    'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.':'ಸಮಸ್ಯೆಯು ಮುಂದುವರಿದರೆ, ನೀವು ಮರುಕಳುಹಿಸುವ ಲಿಂಕ್ ಅನ್ನು ಕ್ಲಿಕ್ ಮಾಡುವ ಮೂಲಕ ಅಥವಾ ನಮ್ಮ ಬೆಂಬಲ ತಂಡವನ್ನು +91-9699900111 ನಲ್ಲಿ ಸಂಪರ್ಕಿಸುವ ಮೂಲಕ ಹೊಸ OTP ಯನ್ನು ವಿನಂತಿಸಬಹುದು.',
    'Double-check the email and try entering the OTP again.':'ಇಮೇಲ್ ಅನ್ನು ಎರಡು ಬಾರಿ ಪರಿಶೀಲಿಸಿ ಮತ್ತು OTP ಅನ್ನು ಮತ್ತೆ ನಮೂದಿಸಲು ಪ್ರಯತ್ನಿಸಿ.',
    'Choose Profile':'ಪ್ರೊಫೈಲ್ ಆಯ್ಕೆಮಾಡಿ',
    'Setting Your Default Profile':'ನಿಮ್ಮ ಡೀಫಾಲ್ಟ್ ಪ್ರೊಫೈಲ್ ಅನ್ನು ಹೊಂದಿಸಲಾಗುತ್ತಿದೆ',
    'Please choose the default profile for a seamless experience.':'ತಡೆರಹಿತ ಅನುಭವಕ್ಕಾಗಿ ದಯವಿಟ್ಟು ಡೀಫಾಲ್ಟ್ ಪ್ರೊಫೈಲ್ ಅನ್ನು ಆಯ್ಕೆಮಾಡಿ.',
    "If you manage multiple companies, select the profile you'd like to set as the default. This ensures that every time you log in, you'll have quick access to the information relevant to your preferred company.":'ನೀವು ಬಹು ಕಂಪನಿಗಳನ್ನು ನಿರ್ವಹಿಸಿದರೆ, ನೀವು ಡಿಫಾಲ್ಟ್ ಆಗಿ ಹೊಂದಿಸಲು ಬಯಸುವ ಪ್ರೊಫೈಲ್ ಅನ್ನು ಆಯ್ಕೆ ಮಾಡಿ. ನೀವು ಪ್ರತಿ ಬಾರಿ ಲಾಗ್ ಇನ್ ಮಾಡಿದಾಗ, ನಿಮ್ಮ ಆದ್ಯತೆಯ ಕಂಪನಿಗೆ ಸಂಬಂಧಿಸಿದ ಮಾಹಿತಿಗೆ ನೀವು ತ್ವರಿತ ಪ್ರವೇಶವನ್ನು ಹೊಂದಿರುವಿರಿ ಎಂದು ಇದು ಖಚಿತಪಡಿಸುತ್ತದೆ.',
    'Making the right selection now simplifies your future logins and provides a tailored experience based on your primary business entity.':'ಇದೀಗ ಸರಿಯಾದ ಆಯ್ಕೆಯನ್ನು ಮಾಡುವುದರಿಂದ ನಿಮ್ಮ ಭವಿಷ್ಯದ ಲಾಗಿನ್‌ಗಳನ್ನು ಸರಳಗೊಳಿಸುತ್ತದೆ ಮತ್ತು ನಿಮ್ಮ ಪ್ರಾಥಮಿಕ ವ್ಯಾಪಾರ ಘಟಕದ ಆಧಾರದ ಮೇಲೆ ಅನುಗುಣವಾದ ಅನುಭವವನ್ನು ಒದಗಿಸುತ್ತದೆ.',
    'Go to Dashboard':'ಡ್ಯಾಶ್ಬೋರ್ಡ್ಗೆ ಹೋಗಿ',
    'Data Compilation in Progress':'ಡೇಟಾ ಸಂಕಲನ ಪ್ರಗತಿಯಲ್ಲಿದೆ:',
    'Please Allow Processing Time':'ದಯವಿಟ್ಟು ಪ್ರಕ್ರಿಯೆಯ ಸಮಯವನ್ನು ಅನುಮತಿಸಿ',
    "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.":'ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ಒದಗಿಸಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ನಮ್ಮ ಸಿಸ್ಟಂ ಪ್ರಸ್ತುತ ನಿಮ್ಮ ಸಮಗ್ರ ಪ್ರೊಫೈಲ್ ಅನ್ನು ಸ್ಥಾಪಿಸಲು PAN, ಆಧಾರ್, GST, ಇಮೇಲ್ ಮತ್ತು ಹೆಚ್ಚಿನ ವಿವರಗಳನ್ನು ಒಳಗೊಂಡಂತೆ ನೀವು ನಮೂದಿಸಿದ ಡೇಟಾವನ್ನು ವಿಶ್ಲೇಷಿಸುವ ಮತ್ತು ಕಂಪೈಲ್ ಮಾಡುವ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿದೆ.',
    "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.":'ಈ ಕಾರ್ಯಾಚರಣೆಯು ಕೆಲವು ಕ್ಷಣಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಬಹುದು. ಈ ಸಮಯದಲ್ಲಿ, ನಿಮ್ಮ ಪರದೆಯು ಕನಿಷ್ಠ ಚಟುವಟಿಕೆಯನ್ನು ತೋರಿಸಬಹುದು. ಇದು ನಮ್ಮ ಡೇಟಾ ಸಂಕಲನ ಪ್ರಕ್ರಿಯೆಯ ವಾಡಿಕೆಯ ಭಾಗವಾಗಿದೆ ಎಂದು ದಯವಿಟ್ಟು ಖಚಿತವಾಗಿರಿ.',
    'Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.':'ನಿಖರತೆ ಮತ್ತು ಸುರಕ್ಷತೆಯನ್ನು ಎತ್ತಿಹಿಡಿಯಲು ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಅನ್ನು ನಿಖರವಾಗಿ ರಚಿಸಲಾಗಿದೆ. ನಿಮ್ಮ ಅನುಭವವನ್ನು ಹೆಚ್ಚಿಸಲು ನಾವು ಕೆಲಸ ಮಾಡುವಾಗ ನಿಮ್ಮ ತಾಳ್ಮೆ ಮತ್ತು ತಿಳುವಳಿಕೆಯನ್ನು ನಾವು ಪ್ರಾಮಾಣಿಕವಾಗಿ ಪ್ರಶಂಸಿಸುತ್ತೇವೆ.',
    'Start Your Bank Account Verification':'ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಖಾತೆ ಪರಿಶೀಲನೆಯನ್ನು ಪ್ರಾರಂಭಿಸಿ',
    'Bank Verification':'ಬ್ಯಾಂಕ್ ಪರಿಶೀಲನೆ',
    'Ensuring the accuracy of your bank account information is crucial for a seamless verification process. Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.':'ತಡೆರಹಿತ ಪರಿಶೀಲನೆ ಪ್ರಕ್ರಿಯೆಗೆ ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಖಾತೆಯ ಮಾಹಿತಿಯ ನಿಖರತೆಯನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳುವುದು ಮುಖ್ಯವಾಗಿದೆ. ದಯವಿಟ್ಟು ನಿಮ್ಮ ಖಾತೆ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ ಮತ್ತು ನಿಮ್ಮ ಖಾತೆಗೆ ಸಂಬಂಧಿಸಿದ ಸರಿಯಾದ IFSC ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ.',
    'This verification step is designed to enhance the security of our services and provide you with a reliable experience.':'ಈ ಪರಿಶೀಲನಾ ಹಂತವನ್ನು ನಮ್ಮ ಸೇವೆಗಳ ಸುರಕ್ಷತೆಯನ್ನು ಹೆಚ್ಚಿಸಲು ಮತ್ತು ನಿಮಗೆ ವಿಶ್ವಾಸಾರ್ಹ ಅನುಭವವನ್ನು ಒದಗಿಸಲು ವಿನ್ಯಾಸಗೊಳಿಸಲಾಗಿದೆ.',
    'Account Number':'',
    'IFSC code':'',
    'Error: Invalid Bank Account or IFSC Code':'ದೋಷ: ಅಮಾನ್ಯ ಬ್ಯಾಂಕ್ ಖಾತೆ ಅಥವಾ IFSC ಕೋ',
    'We regret to inform you that the Bank Account or IFSC Code entered is invalid or incorrect. Please ensure you have accurately entered both details and double-check for any typos.':'ನಮೂದಿಸಿದ ಬ್ಯಾಂಕ್ ಖಾತೆ ಅಥವಾ IFSC ಕೋಡ್ ಅಮಾನ್ಯವಾಗಿದೆ ಅಥವಾ ತಪ್ಪಾಗಿದೆ ಎಂದು ನಿಮಗೆ ತಿಳಿಸಲು ನಾವು ವಿಷಾದಿಸುತ್ತೇವೆ. ದಯವಿಟ್ಟು ನೀವು ಎರಡೂ ವಿವರಗಳನ್ನು ನಿಖರವಾಗಿ ನಮೂದಿಸಿರುವಿರಿ ಮತ್ತು ಯಾವುದೇ ಮುದ್ರಣದೋಷಗಳಿಗಾಗಿ ಎರಡು ಬಾರಿ ಪರಿಶೀಲಿಸಿ.',
    'If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.':'ನೀವು ಸಮಸ್ಯೆಗಳನ್ನು ಎದುರಿಸುವುದನ್ನು ಮುಂದುವರಿಸಿದರೆ, ದಯವಿಟ್ಟು ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಸ್ಟೇಟ್‌ಮೆಂಟ್ ಅನ್ನು ಪರಿಶೀಲಿಸಿ ಅಥವಾ ಒದಗಿಸಿದ ಮಾಹಿತಿಯ ನಿಖರತೆಯನ್ನು ಖಚಿತಪಡಿಸಲು ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಅನ್ನು ಸಂಪರ್ಕಿಸಿ.',
    'Aadhaar Details Verification':'ಆಧಾರ್ ವಿವರಗಳ ಪರಿಶೀಲನೆ',
    'Enter Your Aadhaar Number':'ನಿಮ್ಮ ಆಧಾರ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ',
    'Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.':'ದಯವಿಟ್ಟು ನಿಮ್ಮ ಆಧಾರ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ. ಈ ಪ್ರಕ್ರಿಯೆಯು ನಿಮ್ಮ ಸುರಕ್ಷತೆಗಾಗಿ ನಿಖರವಾದ ಕ್ರಾಸ್-ಚೆಕಿಂಗ್ ಅನ್ನು ಖಚಿತಪಡಿಸುತ್ತದೆ. ನಿಮ್ಮ ಸಹಕಾರಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು.',
    'Enter Your Aadhaar OTP Here':'ನಿಮ್ಮ ಆಧಾರ್ OTP ಅನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ',
    'Error: Incorrect Aadhaar OTP':'ದೋಷ: ತಪ್ಪಾದ ಆಧಾರ್ OTP',
    'We regret to inform you that the One-Time Password (OTP) entered for Aadhaar verification is incorrect. Please ensure that you have accurately entered the OTP received on your registered mobile number.':'ಆಧಾರ್ ಪರಿಶೀಲನೆಗಾಗಿ ನಮೂದಿಸಿದ ಒನ್-ಟೈಮ್ ಪಾಸ್‌ವರ್ಡ್ (OTP) ತಪ್ಪಾಗಿದೆ ಎಂದು ನಿಮಗೆ ತಿಳಿಸಲು ನಾವು ವಿಷಾದಿಸುತ್ತೇವೆ. ನಿಮ್ಮ ನೋಂದಾಯಿತ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯಲ್ಲಿ ಸ್ವೀಕರಿಸಿದ OTP ಯನ್ನು ನೀವು ನಿಖರವಾಗಿ ನಮೂದಿಸಿದ್ದೀರಿ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ.',
    'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.':'ಸಮಸ್ಯೆಯು ಮುಂದುವರಿದರೆ, ನೀವು ಮರುಕಳುಹಿಸುವ ಲಿಂಕ್ ಅನ್ನು ಕ್ಲಿಕ್ ಮಾಡುವ ಮೂಲಕ ಅಥವಾ ನಮ್ಮ ಬೆಂಬಲ ತಂಡವನ್ನು +91-9699900111 ನಲ್ಲಿ ಸಂಪರ್ಕಿಸುವ ಮೂಲಕ ಹೊಸ OTP ಯನ್ನು ವಿನಂತಿಸಬಹುದು.',
    'Double-check the email and try entering the OTP again.':'ಇಮೇಲ್ ಅನ್ನು ಎರಡು ಬಾರಿ ಪರಿಶೀಲಿಸಿ ಮತ್ತು OTP ಅನ್ನು ಮತ್ತೆ ನಮೂದಿಸಲು ಪ್ರಯತ್ನಿಸಿ.',
    'Error: Invalid Aadhaar Number':'ದೋಷ: ಅಮಾನ್ಯವಾದ ಆಧಾರ್ ಸಂಖ್ಯೆ',
    'We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the correct 12-digit Aadhaar number.':'ಅನಾನುಕೂಲತೆಗಾಗಿ ನಾವು ಕ್ಷಮೆಯಾಚಿಸುತ್ತೇವೆ. ನಮೂದಿಸಿದ ಆಧಾರ್ ಸಂಖ್ಯೆ ಅಮಾನ್ಯವಾಗಿದೆ. ದಯವಿಟ್ಟು ಎರಡು ಬಾರಿ ಪರಿಶೀಲಿಸಿ ಮತ್ತು ನೀವು ಸರಿಯಾದ 12-ಅಂಕಿಯ ಆಧಾರ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿರುವಿರಿ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ.',
    'If you continue to face issues, verify your Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.':'ನೀವು ಸಮಸ್ಯೆಗಳನ್ನು ಎದುರಿಸುವುದನ್ನು ಮುಂದುವರಿಸಿದರೆ, ನಿಮ್ಮ ಆಧಾರ್ ಕಾರ್ಡ್ ಅನ್ನು ಪರಿಶೀಲಿಸಿ ಅಥವಾ ಹೆಚ್ಚಿನ ಸಹಾಯಕ್ಕಾಗಿ ನಮ್ಮ ಬೆಂಬಲ ತಂಡವನ್ನು +91-9699900111 ನಲ್ಲಿ ಸಂಪರ್ಕಿಸಿ. ನಿಮ್ಮ ಸಹಕಾರವನ್ನು ನಾವು ಪ್ರಶಂಸಿಸುತ್ತೇವೆ.',
    
    //newly added
    "Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "ನೀವು ಸರಿಯಾದ GST ವಿವರಗಳನ್ನು ನಮೂದಿಸಿರುವಿರಿ ಮತ್ತು ನಿಖರವಾದ ಪರಿಶೀಲನೆಗಾಗಿ ನಿರ್ದಿಷ್ಟಪಡಿಸಿದ ಸ್ವರೂಪವನ್ನು ಅನುಸರಿಸಿ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ.",
    "If you continue to face issues, kindly double-check your PAN card and re-enter the details.": "ನೀವು ಸಮಸ್ಯೆಗಳನ್ನು ಎದುರಿಸುವುದನ್ನು ಮುಂದುವರಿಸಿದರೆ, ದಯವಿಟ್ಟು ನಿಮ್ಮ ಪ್ಯಾನ್ ಕಾರ್ಡ್ ಅನ್ನು ಎರಡು ಬಾರಿ ಪರಿಶೀಲಿಸಿ ಮತ್ತು ವಿವರಗಳನ್ನು ಮರು ನಮೂದಿಸಿ.",
    "The GST provided already exists in our records. Please try using a different GST for verification.": "ಒದಗಿಸಿದ GST ಈಗಾಗಲೇ ನಮ್ಮ ದಾಖಲೆಗಳಲ್ಲಿ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ. ಪರಿಶೀಲನೆಗಾಗಿ ಬೇರೆ GST ಬಳಸಲು ಪ್ರಯತ್ನಿಸಿ.",
    "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.": "ಒದಗಿಸಿದ ಪ್ಯಾನ್ ವಿವರಗಳು ಕಂಪನಿಗೆ ಸೇರಿದೆ ಎಂದು ತೋರುತ್ತಿದೆ. ಪರಿಶೀಲನೆ ಉದ್ದೇಶಗಳಿಗಾಗಿ ನಮಗೆ ನಿಮ್ಮ ವೈಯಕ್ತಿಕ PAN ವಿವರಗಳು ಬೇಕಾಗುತ್ತವೆ.",
    "If you continue to face issues, kindly double-check your PAN card and re-enter the details.For further queries or assistance, feel free to call us at +91-9699900111.": "ನೀವು ಸಮಸ್ಯೆಗಳನ್ನು ಎದುರಿಸುವುದನ್ನು ಮುಂದುವರಿಸಿದರೆ, ದಯವಿಟ್ಟು ನಿಮ್ಮ PAN ಕಾರ್ಡ್ ಅನ್ನು ಎರಡು ಬಾರಿ ಪರಿಶೀಲಿಸಿ ಮತ್ತು ವಿವರಗಳನ್ನು ಮರು-ನಮೂದಿಸಿ. ಹೆಚ್ಚಿನ ಪ್ರಶ್ನೆಗಳು ಅಥವಾ ಸಹಾಯಕ್ಕಾಗಿ, ನಮಗೆ +91-9699900111 ಗೆ ಕರೆ ಮಾಡಲು ಮುಕ್ತವಾಗಿರಿ.",
    "The PAN provided already exists in our records. Please try using a different PAN for verification.": "ಒದಗಿಸಿದ PAN ಈಗಾಗಲೇ ನಮ್ಮ ದಾಖಲೆಗಳಲ್ಲಿ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ. ದಯವಿಟ್ಟು ಪರಿಶೀಲನೆಗಾಗಿ ಬೇರೆ PAN ಅನ್ನು ಬಳಸಲು ಪ್ರಯತ್ನಿಸಿ.",
    "New User Registration": "ಹೊಸ ಬಳಕೆದಾರರ ನೋಂದಣಿ",
    "To begin your journey with us, we kindly request all new users to register by following the simple steps outlined below:": "ನಮ್ಮೊಂದಿಗೆ ನಿಮ್ಮ ಪ್ರಯಾಣವನ್ನು ಪ್ರಾರಂಭಿಸಲು, ಕೆಳಗೆ ವಿವರಿಸಿರುವ ಸರಳ ಹಂತಗಳನ್ನು ಅನುಸರಿಸುವ ಮೂಲಕ ಎಲ್ಲಾ ಹೊಸ ಬಳಕೆದಾರರನ್ನು ನೋಂದಾಯಿಸಲು ನಾವು ದಯೆಯಿಂದ ವಿನಂತಿಸುತ್ತೇವೆ:",
    "Registration": "ನೋಂದಣಿ",
    "To register and gain access to our comprehensive verification services, please proceed by making a one-time payment of": "ನಮ್ಮ ಸಮಗ್ರ ಪರಿಶೀಲನಾ ಸೇವೆಗಳಿಗೆ ನೋಂದಾಯಿಸಲು ಮತ್ತು ಪ್ರವೇಶವನ್ನು ಪಡೆಯಲು, ದಯವಿಟ್ಟು ಒಂದು ಬಾರಿ ಪಾವತಿ ಮಾಡುವ ಮೂಲಕ ಮುಂದುವರಿಯಿರಿ",
    "Rs. 500": "ರೂ. 500",
    "GST": "ಜಿಎಸ್ಟಿ",
    "done": "ಮಾಡಲಾಗಿದೆ",
    "Please note that the registration fee is subject to 18% GST (Goods and Services Tax) as per government regulations.": "ಸರ್ಕಾರದ ನಿಯಮಗಳ ಪ್ರಕಾರ ನೋಂದಣಿ ಶುಲ್ಕವು 18% GST (ಸರಕು ಮತ್ತು ಸೇವಾ ತೆರಿಗೆ) ಕ್ಕೆ ಒಳಪಟ್ಟಿರುತ್ತದೆ ಎಂಬುದನ್ನು ದಯವಿಟ್ಟು ಗಮನಿಸಿ.",
    "Total Amount Due:": "ಒಟ್ಟು ಬಾಕಿ ಮೊತ್ತ:",
    "Rs. 590": "ರೂ. 590",
    "Total Registration Fee + 18% GST": "ಒಟ್ಟು ನೋಂದಣಿ ಶುಲ್ಕ + 18% GST",
    "Secure Payment Gateway": "ಸುರಕ್ಷಿತ ಪಾವತಿ ಗೇಟ್‌ವೇ",
    "Rest assured, your payment and personal information are handled with the utmost security through our encrypted payment gateway.": "ಖಚಿತವಾಗಿರಿ, ನಿಮ್ಮ ಪಾವತಿ ಮತ್ತು ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಯನ್ನು ನಮ್ಮ ಎನ್‌ಕ್ರಿಪ್ಟ್ ಮಾಡಿದ ಪಾವತಿ ಗೇಟ್‌ವೇ ಮೂಲಕ ಅತ್ಯಂತ ಭದ್ರತೆಯೊಂದಿಗೆ ನಿರ್ವಹಿಸಲಾಗುತ್ತದೆ.",
    "Registration Complete - Thank You!": "ನೋಂದಣಿ ಪೂರ್ಣಗೊಂಡಿದೆ - ಧನ್ಯವಾದಗಳು!",
    "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "ಅಭಿನಂದನೆಗಳು! ನೀವು ನೋಂದಣಿ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ್ದೀರಿ. ಅಗತ್ಯ ಮಾಹಿತಿಯನ್ನು ಒದಗಿಸುವ ನಿಮ್ಮ ಸಮರ್ಪಣೆಯು ನಿಮಗೆ ಪರಿಣಾಮಕಾರಿಯಾಗಿ ಸೇವೆ ಸಲ್ಲಿಸಲು ನಮ್ಮನ್ನು ಒಂದು ಹೆಜ್ಜೆ ಹತ್ತಿರಕ್ಕೆ ತಂದಿದೆ.",
    "With your registration finalized, our team is fully equipped to handle your requirements efficiently. We assure you that your case will be handled with the utmost care and attention to detail.": "ನಿಮ್ಮ ನೋಂದಣಿಯನ್ನು ಅಂತಿಮಗೊಳಿಸುವುದರೊಂದಿಗೆ, ನಿಮ್ಮ ಅವಶ್ಯಕತೆಗಳನ್ನು ಸಮರ್ಥವಾಗಿ ನಿರ್ವಹಿಸಲು ನಮ್ಮ ತಂಡವು ಸಂಪೂರ್ಣವಾಗಿ ಸಜ್ಜಾಗಿದೆ. ನಿಮ್ಮ ಪ್ರಕರಣವನ್ನು ಅತ್ಯಂತ ಎಚ್ಚರಿಕೆಯಿಂದ ಮತ್ತು ವಿವರಗಳಿಗೆ ಗಮನದಿಂದ ನಿರ್ವಹಿಸಲಾಗುವುದು ಎಂದು ನಾವು ನಿಮಗೆ ಭರವಸೆ ನೀಡುತ್ತೇವೆ.",
    "Should you ever require assistance or have any queries, please feel free to contact us at any time. We are here to address your concerns and ensure a smooth journey throughout the process.": "ನಿಮಗೆ ಎಂದಾದರೂ ಸಹಾಯದ ಅಗತ್ಯವಿದ್ದರೆ ಅಥವಾ ಯಾವುದೇ ಪ್ರಶ್ನೆಗಳನ್ನು ಹೊಂದಿದ್ದರೆ, ದಯವಿಟ್ಟು ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಲು ಮುಕ್ತವಾಗಿರಿ. ನಿಮ್ಮ ಕಾಳಜಿಗಳನ್ನು ಪರಿಹರಿಸಲು ಮತ್ತು ಪ್ರಕ್ರಿಯೆಯ ಉದ್ದಕ್ಕೂ ಸುಗಮ ಪ್ರಯಾಣವನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ನಾವು ಇಲ್ಲಿದ್ದೇವೆ.",
    "We eagerly anticipate the opportunity to assist you further.": "ನಿಮಗೆ ಮತ್ತಷ್ಟು ಸಹಾಯ ಮಾಡುವ ಅವಕಾಶವನ್ನು ನಾವು ಕುತೂಹಲದಿಂದ ನಿರೀಕ್ಷಿಸುತ್ತೇವೆ.",
    "Warm regards,": "ಶುಭಾಶಯಗಳೊಂದಿಗೆ,",
    "PrivateCourt Team": "ಖಾಸಗಿ ನ್ಯಾಯಾಲಯದ ತಂಡ",
    "Secure UDYAM Number Validation": "ಸುರಕ್ಷಿತ UDYAM ಸಂಖ್ಯೆ ಮೌಲ್ಯೀಕರಣ",
    "Enter UDYAM for": "UDYAM ಅನ್ನು ನಮೂದಿಸಿ",
    "having GST number": "GST ಸಂಖ್ಯೆಯನ್ನು ಹೊಂದಿದೆ",
    "Enter Your Udyam Number": "ನಿಮ್ಮ ಉದ್ಯಮ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
    "Error: Invalid UAM Number": "ದೋಷ: ಅಮಾನ್ಯ UAM ಸಂಖ್ಯೆ",
    "We regret to inform you that the UAM Number entered isinvalid or incorrect. Please ensure you have accuratelyentered detail and double-check for any typos.": "ನಮೂದಿಸಿದ UAM ಸಂಖ್ಯೆಯು ಅಮಾನ್ಯವಾಗಿದೆ ಅಥವಾ ತಪ್ಪಾಗಿದೆ ಎಂದು ನಿಮಗೆ ತಿಳಿಸಲು ನಾವು ವಿಷಾದಿಸುತ್ತೇವೆ. ದಯವಿಟ್ಟು ನೀವು ವಿವರಗಳನ್ನು ನಿಖರವಾಗಿ ನಮೂದಿಸಿರುವಿರಿ ಮತ್ತು ಯಾವುದೇ ಮುದ್ರಣದೋಷಗಳಿಗಾಗಿ ಎರಡು ಬಾರಿ ಪರಿಶೀಲಿಸಿ.",
    "Server Overload: Please Retry Later": "ಸರ್ವರ್ ಓವರ್ಲೋಡ್: ದಯವಿಟ್ಟು ನಂತರ ಮರುಪ್ರಯತ್ನಿಸಿ",
    "We apologize for the inconvenience and appreciate your patience. Currently, our server is experiencing a surge in requests, creating a temporary delay. Rest assured, we are diligently collecting your data to ensure accuracy and security.": "ಅನಾನುಕೂಲತೆಗಾಗಿ ನಾವು ಕ್ಷಮೆಯಾಚಿಸುತ್ತೇವೆ ಮತ್ತು ನಿಮ್ಮ ತಾಳ್ಮೆಯನ್ನು ಪ್ರಶಂಸಿಸುತ್ತೇವೆ. ಪ್ರಸ್ತುತ, ನಮ್ಮ ಸರ್ವರ್ ವಿನಂತಿಗಳಲ್ಲಿ ಉಲ್ಬಣವನ್ನು ಅನುಭವಿಸುತ್ತಿದೆ, ತಾತ್ಕಾಲಿಕ ವಿಳಂಬವನ್ನು ಸೃಷ್ಟಿಸುತ್ತದೆ. ಖಚಿತವಾಗಿರಿ, ನಿಖರತೆ ಮತ್ತು ಸುರಕ್ಷತೆಯನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ನಾವು ನಿಮ್ಮ ಡೇಟಾವನ್ನು ಶ್ರದ್ಧೆಯಿಂದ ಸಂಗ್ರಹಿಸುತ್ತಿದ್ದೇವೆ.",
    "Please bear with us during this busy period, and we kindly request you to wait for a brief moment before attempting again. Your understanding is crucial in maintaining the quality of service we strive to provide.": "ಈ ಬಿಡುವಿಲ್ಲದ ಅವಧಿಯಲ್ಲಿ ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಹಿಸಿಕೊಳ್ಳಿ ಮತ್ತು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸುವ ಮೊದಲು ಸ್ವಲ್ಪ ಸಮಯ ಕಾಯಲು ನಾವು ನಿಮ್ಮನ್ನು ವಿನಂತಿಸುತ್ತೇವೆ. ನಾವು ಒದಗಿಸಲು ಪ್ರಯತ್ನಿಸುತ್ತಿರುವ ಸೇವೆಯ ಗುಣಮಟ್ಟವನ್ನು ಕಾಪಾಡಿಕೊಳ್ಳುವಲ್ಲಿ ನಿಮ್ಮ ತಿಳುವಳಿಕೆಯು ನಿರ್ಣಾಯಕವಾಗಿದೆ.",
    "For urgent matters or assistance, feel free to call us at +91-9699900111.": "ತುರ್ತು ವಿಷಯಗಳು ಅಥವಾ ಸಹಾಯಕ್ಕಾಗಿ, +91-9699900111 ನಲ್ಲಿ ನಮಗೆ ಕರೆ ಮಾಡಲು ಮುಕ್ತವಾಗಿರಿ.",
    "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.": "ನಿಮ್ಮ ತಾಳ್ಮೆ ಮತ್ತು ಖಾಸಗಿ ನ್ಯಾಯಾಲಯವನ್ನು ವಹಿಸಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ನಿಮ್ಮ ಭದ್ರತೆ ನಮ್ಮ ಆದ್ಯತೆಯಾಗಿದೆ.",
    "If you continue to face issues, verify the": "ನೀವು ಸಮಸ್ಯೆಗಳನ್ನು ಎದುರಿಸುವುದನ್ನು ಮುಂದುವರಿಸಿದರೆ, ಪರಿಶೀಲಿಸಿ",
    "Applicant’s": "ಅಭ್ಯರ್ಥಿಗಳು",
    "Error: Invalid OTP": "ದೋಷ: ಅಮಾನ್ಯ OTP",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "ಅನಾನುಕೂಲತೆಗಾಗಿ ನಾವು ಕ್ಷಮೆಯಾಚಿಸುತ್ತೇವೆ. ನಮೂದಿಸಿದ OTP ತಪ್ಪಾಗಿದೆ. ದಯವಿಟ್ಟು OTP ಅನ್ನು ಎರಡು ಬಾರಿ ಪರಿಶೀಲಿಸಿ ಹಾಗೂ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
    "Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "ಹೆಚ್ಚಿನ ಸಹಾಯಕ್ಕಾಗಿ ಆಧಾರ್ ಕಾರ್ಡ್ ಅಥವಾ ನಮ್ಮ ಬೆಂಬಲ ತಂಡವನ್ನು +91-9699900111 ನಲ್ಲಿ ಸಂಪರ್ಕಿಸಿ. ನಿಮ್ಮ ಸಹಕಾರವನ್ನು ನಾವು ಪ್ರಶಂಸಿಸುತ್ತೇವೆ.",
    "Error: User already exists": "ದೋಷ: ಬಳಕೆದಾರರು ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದ್ದಾರೆ",
    "We apologize for the inconvenience. The Aadhaar number provided already exists in our records.Please provide a different Aadhaar number for verification.": "ಅನಾನುಕೂಲತೆಗಾಗಿ ನಾವು ಕ್ಷಮೆಯಾಚಿಸುತ್ತೇವೆ. ಒದಗಿಸಿದ ಆಧಾರ್ ಸಂಖ್ಯೆಯು ನಮ್ಮ ದಾಖಲೆಗಳಲ್ಲಿ ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ. ದಯವಿಟ್ಟು ಪರಿಶೀಲನೆಗಾಗಿ ಬೇರೆ ಆಧಾರ್ ಸಂಖ್ಯೆಯನ್ನು ಒದಗಿಸಿ.",
    "We apologize for the inconvenience. The name provided does not match the PAN records. Please ensure that the name entered matches the information on your PAN card and try again.": "ಅನಾನುಕೂಲತೆಗಾಗಿ ನಾವು ಕ್ಷಮೆಯಾಚಿಸುತ್ತೇವೆ. ಒದಗಿಸಿದ ಹೆಸರು PAN ದಾಖಲೆಗಳಿಗೆ ಹೊಂದಿಕೆಯಾಗುತ್ತಿಲ್ಲ. ನಮೂದಿಸಿದ ಹೆಸರು ನಿಮ್ಮ PAN ಕಾರ್ಡ್‌ನಲ್ಲಿರುವ ಮಾಹಿತಿಗೆ ಹೊಂದಿಕೆಯಾಗುತ್ತದೆ ಎಂಬುದನ್ನು ದಯವಿಟ್ಟು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ ಮತ್ತು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
    "Enter Bank Details for": "ಇದಕ್ಕಾಗಿ ಬ್ಯಾಂಕ್ ವಿವರಗಳನ್ನು ನಮೂದಿಸಿ",
    "Error: Already Exists": "ದೋಷ: ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ",
    "We regret to inform you that the ID you've provided already exists in our system. Please try another ID.": "ನೀವು ಒದಗಿಸಿದ ID ಈಗಾಗಲೇ ನಮ್ಮ ಸಿಸ್ಟಂನಲ್ಲಿ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ ಎಂದು ನಿಮಗೆ ತಿಳಿಸಲು ನಾವು ವಿಷಾದಿಸುತ್ತೇವೆ. ದಯವಿಟ್ಟು ಇನ್ನೊಂದು ಐಡಿಯನ್ನು ಪ್ರಯತ್ನಿಸಿ.",
    "If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.": "ನೀವು ಸಮಸ್ಯೆಗಳನ್ನು ಎದುರಿಸುವುದನ್ನು ಮುಂದುವರಿಸಿದರೆ, ದಯವಿಟ್ಟು ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಸ್ಟೇಟ್‌ಮೆಂಟ್ ಅನ್ನು ಪರಿಶೀಲಿಸಿ ಅಥವಾ ಒದಗಿಸಿದ ಮಾಹಿತಿಯ ನಿಖರತೆಯನ್ನು ಖಚಿತಪಡಿಸಲು ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಅನ್ನು ಸಂಪರ್ಕಿಸಿ.",
    "Error: Name Not Matched": "ದೋಷ: ಹೆಸರು ಹೊಂದಿಕೆಯಾಗುತ್ತಿಲ್ಲ",
    "Good Afternoon": "ಶುಭ ಅಪರಾಹ್ನ",
    "Account security starts with verification": "ಖಾತೆಯ ಭದ್ರತೆಯು ಪರಿಶೀಲನೆಯೊಂದಿಗೆ ಪ್ರಾರಂಭವಾಗುತ್ತದೆ",
    "OTP Validation for Account Access": "ಖಾತೆ ಪ್ರವೇಶಕ್ಕಾಗಿ OTP ಮೌಲ್ಯೀಕರಣ",
    "Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇನ್‌ಬಾಕ್ಸ್ ಅನ್ನು ಪರಿಶೀಲಿಸಿ ಮತ್ತು ಪರಿಶೀಲನೆ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು ಗೊತ್ತುಪಡಿಸಿದ ಕ್ಷೇತ್ರದಲ್ಲಿ OTP ಅನ್ನು ನಮೂದಿಸಿ.",
    "Request a Resend": "ಮರುಕಳುಹಿಸಲು ವಿನಂತಿಸಿ",
    "Error: Incorrect OTP": "ದೋಷ: ತಪ್ಪಾದ OTP",
    "We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.": "ಅನಾನುಕೂಲತೆಗಾಗಿ ನಾವು ಕ್ಷಮೆಯಾಚಿಸುತ್ತೇವೆ. ನಮೂದಿಸಿದ ಒನ್-ಟೈಮ್ ಪಾಸ್‌ವರ್ಡ್ (OTP) ತಪ್ಪಾಗಿದೆ. ನಿಮ್ಮ ಇಮೇಲ್‌ನಲ್ಲಿ ಸ್ವೀಕರಿಸಿದ OTP ಯನ್ನು ನೀವು ನಿಖರವಾಗಿ ನಮೂದಿಸಿದ್ದೀರಿ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ.",
    "If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team  at +91-9699900111.": "ಸಮಸ್ಯೆಯು ಮುಂದುವರಿದರೆ, ನೀವು ಮರುಕಳುಹಿಸುವ ಲಿಂಕ್ ಅನ್ನು ಕ್ಲಿಕ್ ಮಾಡುವ ಮೂಲಕ ಅಥವಾ ನಮ್ಮ ಬೆಂಬಲ ತಂಡವನ್ನು +91-9699900111 ನಲ್ಲಿ ಸಂಪರ್ಕಿಸುವ ಮೂಲಕ ಹೊಸ OTP ಯನ್ನು ವಿನಂತಿಸಬಹುದು.",
    "Double-check the email and try entering the OTP again.": "ಇಮೇಲ್ ಅನ್ನು ಎರಡು ಬಾರಿ ಪರಿಶೀಲಿಸಿ ಮತ್ತು OTP ಅನ್ನು ಮತ್ತೆ ನಮೂದಿಸಲು ಪ್ರಯತ್ನಿಸಿ.",
    "At PrivateCourt, we prioritize the security and integrity of our services. Our GST verification process is meticulously designed to ensure a reliable and secure experience for all our users.": "ಖಾಸಗಿ ನ್ಯಾಯಾಲಯದಲ್ಲಿ, ನಮ್ಮ ಸೇವೆಗಳ ಭದ್ರತೆ ಮತ್ತು ಸಮಗ್ರತೆಗೆ ನಾವು ಆದ್ಯತೆ ನೀಡುತ್ತೇವೆ. ನಮ್ಮ ಎಲ್ಲಾ ಬಳಕೆದಾರರಿಗೆ ವಿಶ್ವಾಸಾರ್ಹ ಮತ್ತು ಸುರಕ್ಷಿತ ಅನುಭವವನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ನಮ್ಮ GST ಪರಿಶೀಲನೆ ಪ್ರಕ್ರಿಯೆಯನ್ನು ನಿಖರವಾಗಿ ವಿನ್ಯಾಸಗೊಳಿಸಲಾಗಿದೆ.",
    "Enter Your GSTIN": "ನಿಮ್ಮ GSTIN ಅನ್ನು ನಮೂದಿಸಿ",
    "Please enter your correct GST Identification Number (GSTIN) in the format 00-XXXXX-0000-X-0-X-0.": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಸರಿಯಾದ GST ಗುರುತಿನ ಸಂಖ್ಯೆಯನ್ನು (GSTIN) 00-XXXXX-0000-X-0-X-0 ಸ್ವರೂಪದಲ್ಲಿ ನಮೂದಿಸಿ.",
    "Upon entering your GSTIN, PrivateCourt will diligently collect essential information associated with it. This includes your full name, date of birth, residential address, and contact details. For business owners, we extend our data collection to encompass all pertinent business details tied to your GSTIN, such as MCA specifics and records of GST return filings.": "ನಿಮ್ಮ GSTIN ಅನ್ನು ನಮೂದಿಸಿದ ನಂತರ, ಖಾಸಗಿ ನ್ಯಾಯಾಲಯವು ಅದಕ್ಕೆ ಸಂಬಂಧಿಸಿದ ಅಗತ್ಯ ಮಾಹಿತಿಯನ್ನು ಶ್ರದ್ಧೆಯಿಂದ ಸಂಗ್ರಹಿಸುತ್ತದೆ. ಇದು ನಿಮ್ಮ ಪೂರ್ಣ ಹೆಸರು, ಹುಟ್ಟಿದ ದಿನಾಂಕ, ನಿವಾಸದ ವಿಳಾಸ ಮತ್ತು ಸಂಪರ್ಕ ವಿವರಗಳನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ. ವ್ಯಾಪಾರ ಮಾಲೀಕರಿಗಾಗಿ, MCA ನಿರ್ದಿಷ್ಟತೆಗಳು ಮತ್ತು GST ರಿಟರ್ನ್ ಫೈಲಿಂಗ್‌ಗಳ ದಾಖಲೆಗಳಂತಹ ನಿಮ್ಮ GSTIN ಗೆ ಸಂಬಂಧಿಸಿದ ಎಲ್ಲಾ ಸಂಬಂಧಿತ ವ್ಯವಹಾರ ವಿವರಗಳನ್ನು ಒಳಗೊಳ್ಳಲು ನಾವು ನಮ್ಮ ಡೇಟಾ ಸಂಗ್ರಹಣೆಯನ್ನು ವಿಸ್ತರಿಸುತ್ತೇವೆ.",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your GSTIN.": "ಹಣಕಾಸಿನ ಒಳನೋಟಕ್ಕಾಗಿ ನಮ್ಮ ವ್ಯವಸ್ಥೆಯು ನಿಮ್ಮ CIBIL ಸ್ಕೋರ್ ಅನ್ನು ನಿರ್ಣಯಿಸುತ್ತದೆ ಮತ್ತು ನಿಮ್ಮ GSTIN ಗೆ ಸಂಬಂಧಿಸಿದ ಯಾವುದೇ ನಡೆಯುತ್ತಿರುವ ನ್ಯಾಯಾಲಯದ ಪ್ರಕರಣಗಳಿಗೆ ಅಡ್ಡ-ಪರಿಶೀಲಿಸುತ್ತದೆ.",
    "Error: Invalid GST Format": "ದೋಷ: ಅಮಾನ್ಯ GST ಫಾರ್ಮ್ಯಾಟ್",
    "Please enter your personal PAN": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ವೈಯಕ್ತಿಕ PAN ನಮೂದಿಸಿ",
    "Already Exists": "ಈಗಾಗಲೇ ಇದೆ",
    "Invalid": "ಅಮಾನ್ಯ",
    "Secure PAN Validation Process": "ಸುರಕ್ಷಿತ PAN ಮೌಲ್ಯೀಕರಣ ಪ್ರಕ್ರಿಯೆ",
    "This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.": "ಈ ಪರಿಶೀಲನಾ ಪ್ರಕ್ರಿಯೆಯನ್ನು ನಮ್ಮ ಸೇವೆಗಳ ಸಮಗ್ರತೆಯನ್ನು ಎತ್ತಿಹಿಡಿಯಲು ವಿನ್ಯಾಸಗೊಳಿಸಲಾಗಿದೆ, ವಿಶ್ವಾಸಾರ್ಹ ಮತ್ತು ಸುರಕ್ಷಿತ ಅನುಭವವನ್ನು ಖಾತ್ರಿಪಡಿಸುತ್ತದೆ.",
    "Please enter your correct PAN number in the format XXXXX-0000-X.": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಸರಿಯಾದ PAN ಸಂಖ್ಯೆಯನ್ನು XXXXX-0000-X ಫಾರ್ಮ್ಯಾಟ್‌ನಲ್ಲಿ ನಮೂದಿಸಿ.",
    "PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.": "ನಿಮ್ಮ ಪೂರ್ಣ ಹೆಸರು, ಹುಟ್ಟಿದ ದಿನಾಂಕ, ವಸತಿ ವಿಳಾಸ ಮತ್ತು ಸಂಪರ್ಕ ವಿವರಗಳಂತಹ ನಿಮ್ಮ ಪ್ಯಾನ್‌ಗೆ ಸಂಬಂಧಿಸಿದ ಅಗತ್ಯ ಮಾಹಿತಿಯನ್ನು ಖಾಸಗಿ ನ್ಯಾಯಾಲಯವು ಸೂಕ್ಷ್ಮವಾಗಿ ಸಂಗ್ರಹಿಸುತ್ತದೆ. ವ್ಯಾಪಾರ ಮಾಲೀಕರಿಗಾಗಿ, GST ವಿವರಗಳು, MCA ನಿರ್ದಿಷ್ಟತೆಗಳು ಮತ್ತು GST ರಿಟರ್ನ್ ಫೈಲಿಂಗ್‌ಗಳ ದಾಖಲೆಗಳಂತಹ ನಿಮ್ಮ PAN ಗೆ ಸಂಬಂಧಿಸಿದ ಎಲ್ಲಾ ವ್ಯವಹಾರ ವಿವರಗಳನ್ನು ಒಳಗೊಳ್ಳಲು ನಾವು ನಮ್ಮ ಡೇಟಾ ಸಂಗ್ರಹಣೆಯನ್ನು ವಿಸ್ತರಿಸುತ್ತೇವೆ.",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.": "ಹಣಕಾಸಿನ ಒಳನೋಟಕ್ಕಾಗಿ ನಮ್ಮ ಸಿಸ್ಟಂ ನಿಮ್ಮ CIBIL ಸ್ಕೋರ್ ಅನ್ನು ಮೌಲ್ಯಮಾಪನ ಮಾಡುತ್ತದೆ ಮತ್ತು ನಿಮ್ಮ PAN ಗೆ ಸಂಬಂಧಿಸಿದ ಯಾವುದೇ ನಡೆಯುತ್ತಿರುವ ನ್ಯಾಯಾಲಯದ ಪ್ರಕರಣಗಳಿಗೆ ಅಡ್ಡ-ಪರಿಶೀಲಿಸುತ್ತದೆ.",
    "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.": "ಒದಗಿಸಿದ ಪ್ಯಾನ್ ವಿವರಗಳು ಕಂಪನಿಗೆ ಸೇರಿದೆ ಎಂದು ತೋರುತ್ತಿದೆ. ಪರಿಶೀಲನೆ ಉದ್ದೇಶಗಳಿಗಾಗಿ ನಮಗೆ ನಿಮ್ಮ ವೈಯಕ್ತಿಕ PAN ವಿವರಗಳು ಬೇಕಾಗುತ್ತವೆ.",



    "Data Compilation in Progress: Please Allow Processing Time": "ಡೇಟಾ ಸಂಕಲನ ಪ್ರಗತಿಯಲ್ಲಿದೆ: ದಯವಿಟ್ಟು ಪ್ರಕ್ರಿಯೆಯ ಸಮಯವನ್ನು ಅನುಮತಿಸಿ",
    "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.": "ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ಒದಗಿಸಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ನಮ್ಮ ಸಿಸ್ಟಂ ಪ್ರಸ್ತುತ ನಿಮ್ಮ ಸಮಗ್ರ ಪ್ರೊಫೈಲ್ ಅನ್ನು ಸ್ಥಾಪಿಸಲು PAN, ಆಧಾರ್, GST, ಇಮೇಲ್ ಮತ್ತು ಹೆಚ್ಚಿನ ವಿವರಗಳನ್ನು ಒಳಗೊಂಡಂತೆ ನೀವು ನಮೂದಿಸಿದ ಡೇಟಾವನ್ನು ವಿಶ್ಲೇಷಿಸುವ ಮತ್ತು ಕಂಪೈಲ್ ಮಾಡುವ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿದೆ.",
    "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.": "ಈ ಕಾರ್ಯಾಚರಣೆಯು ಕೆಲವು ಕ್ಷಣಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಬಹುದು. ಈ ಸಮಯದಲ್ಲಿ, ನಿಮ್ಮ ಪರದೆಯು ಕನಿಷ್ಠ ಚಟುವಟಿಕೆಯನ್ನು ತೋರಿಸಬಹುದು. ಇದು ನಮ್ಮ ಡೇಟಾ ಸಂಕಲನ ಪ್ರಕ್ರಿಯೆಯ ವಾಡಿಕೆಯ ಭಾಗವಾಗಿದೆ ಎಂದು ದಯವಿಟ್ಟು ಖಚಿತವಾಗಿರಿ.",
    "Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.": "ನಿಖರತೆ ಮತ್ತು ಸುರಕ್ಷತೆಯನ್ನು ಎತ್ತಿಹಿಡಿಯಲು ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಅನ್ನು ನಿಖರವಾಗಿ ರಚಿಸಲಾಗಿದೆ. ನಿಮ್ಮ ಅನುಭವವನ್ನು ಹೆಚ್ಚಿಸಲು ನಾವು ಕೆಲಸ ಮಾಡುವಾಗ ನಿಮ್ಮ ತಾಳ್ಮೆ ಮತ್ತು ತಿಳುವಳಿಕೆಯನ್ನು ನಾವು ಪ್ರಾಮಾಣಿಕವಾಗಿ ಪ್ರಶಂಸಿಸುತ್ತೇವೆ.",
    "For urgent matters or assistance, feel free to call us at +91-9699900111.": "ತುರ್ತು ವಿಷಯಗಳು ಅಥವಾ ಸಹಾಯಕ್ಕಾಗಿ, +91-9699900111 ನಲ್ಲಿ ನಮಗೆ ಕರೆ ಮಾಡಲು ಮುಕ್ತವಾಗಿರಿ.",
    "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.": "ನಿಮ್ಮ ತಾಳ್ಮೆ ಮತ್ತು ಖಾಸಗಿ ನ್ಯಾಯಾಲಯವನ್ನು ವಹಿಸಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ನಿಮ್ಮ ಭದ್ರತೆ ನಮ್ಮ ಆದ್ಯತೆಯಾಗಿದೆ.",
    "Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಆಧಾರ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ. ಈ ಪ್ರಕ್ರಿಯೆಯು ನಿಮ್ಮ ಸುರಕ್ಷತೆಗಾಗಿ ನಿಖರವಾದ ಅಡ್ಡ-ಪರಿಶೀಲನೆಯನ್ನು ಖಚಿತಪಡಿಸುತ್ತದೆ. ನಿಮ್ಮ ಸಹಕಾರಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು.",
    "We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the": "ಅನಾನುಕೂಲತೆಗಾಗಿ ನಾವು ಕ್ಷಮೆಯಾಚಿಸುತ್ತೇವೆ. ನಮೂದಿಸಿದ ಆಧಾರ್ ಸಂಖ್ಯೆ ಅಮಾನ್ಯವಾಗಿದೆ. ದಯವಿಟ್ಟು ಎರಡು ಬಾರಿ ಪರಿಶೀಲಿಸಿ ಮತ್ತು ನೀವು ನಮೂದಿಸಿರುವಿರಿ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ",
    "Applicant’s correct 12-digit Aadhaar number.": "ಅರ್ಜಿದಾರರ ಸರಿಯಾದ 12-ಅಂಕಿಯ ಆಧಾರ್ ಸಂಖ್ಯೆ.",
    "Error: Invalid OTP": "ದೋಷ: ಅಮಾನ್ಯ OTP",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "ಅನಾನುಕೂಲತೆಗಾಗಿ ನಾವು ಕ್ಷಮೆಯಾಚಿಸುತ್ತೇವೆ. ನಮೂದಿಸಿದ OTP ತಪ್ಪಾಗಿದೆ. ದಯವಿಟ್ಟು OTP ಅನ್ನು ಎರಡು ಬಾರಿ ಪರಿಶೀಲಿಸಿ ಹಾಗೂ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
    "Welcome": "ಸ್ವಾಗತ",
    "Ensuring the accuracy of your bank account information is crucial for a seamless verification process.Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.": "ತಡೆರಹಿತ ಪರಿಶೀಲನೆ ಪ್ರಕ್ರಿಯೆಗೆ ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಖಾತೆಯ ಮಾಹಿತಿಯ ನಿಖರತೆಯನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳುವುದು ನಿರ್ಣಾಯಕವಾಗಿದೆ. ದಯವಿಟ್ಟು ನಿಮ್ಮ ಮಾನ್ಯ ಖಾತೆ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ ಮತ್ತು ನಿಮ್ಮ ಖಾತೆಗೆ ಸಂಬಂಧಿಸಿದ ಸರಿಯಾದ IFSC ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ.",
    "This verification step is designed to enhance the security of our services and provide you with a reliable experience.": "ಈ ಪರಿಶೀಲನಾ ಹಂತವನ್ನು ನಮ್ಮ ಸೇವೆಗಳ ಸುರಕ್ಷತೆಯನ್ನು ಹೆಚ್ಚಿಸಲು ಮತ್ತು ನಿಮಗೆ ವಿಶ್ವಾಸಾರ್ಹ ಅನುಭವವನ್ನು ಒದಗಿಸಲು ವಿನ್ಯಾಸಗೊಳಿಸಲಾಗಿದೆ.",
    "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.": "ಅನಾನುಕೂಲತೆಗಾಗಿ ನಾವು ಕ್ಷಮೆಯಾಚಿಸುತ್ತೇವೆ. ಒದಗಿಸಿದ ಹೆಸರು GST ದಾಖಲೆಗಳಿಗೆ ಹೊಂದಿಕೆಯಾಗುವುದಿಲ್ಲ. ನಮೂದಿಸಿದ ಹೆಸರು ನಿಮ್ಮ GST ಕಾರ್ಡ್‌ನಲ್ಲಿರುವ ಮಾಹಿತಿಗೆ ಹೊಂದಿಕೆಯಾಗುತ್ತಿದೆಯೇ ಎಂಬುದನ್ನು ದಯವಿಟ್ಟು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ ಹಾಗೂ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
    "Business Verification Process": "ವ್ಯಾಪಾರ ಪರಿಶೀಲನೆ ಪ್ರಕ್ರಿಯೆ",
    "Ensuring Secure Business Validation- This verification process is meticulously designed to uphold the integrity of our services, providing a reliable and secure experience.": "ಸುರಕ್ಷಿತ ವ್ಯಾಪಾರ ಮೌಲ್ಯೀಕರಣವನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳುವುದು- ಈ ಪರಿಶೀಲನಾ ಪ್ರಕ್ರಿಯೆಯನ್ನು ನಿಖರವಾಗಿ ನಮ್ಮ ಸೇವೆಗಳ ಸಮಗ್ರತೆಯನ್ನು ಎತ್ತಿಹಿಡಿಯಲು ವಿನ್ಯಾಸಗೊಳಿಸಲಾಗಿದೆ, ಇದು ವಿಶ್ವಾಸಾರ್ಹ ಮತ್ತು ಸುರಕ್ಷಿತ ಅನುಭವವನ್ನು ಒದಗಿಸುತ್ತದೆ.",
    "Please provide details for each of your businesses for accurate validation.": "ನಿಖರವಾದ ಮೌಲ್ಯೀಕರಣಕ್ಕಾಗಿ ದಯವಿಟ್ಟು ನಿಮ್ಮ ಪ್ರತಿಯೊಂದು ವ್ಯಾಪಾರದ ವಿವರಗಳನ್ನು ಒದಗಿಸಿ.",
    "PrivateCourt will collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.": "ಖಾಸಗಿ ನ್ಯಾಯಾಲಯವು ನಿಮ್ಮ ಪೂರ್ಣ ಹೆಸರು, ಹುಟ್ಟಿದ ದಿನಾಂಕ, ವಸತಿ ವಿಳಾಸ ಮತ್ತು ಸಂಪರ್ಕ ವಿವರಗಳಂತಹ ನಿಮ್ಮ ಪ್ಯಾನ್‌ಗೆ ಸಂಬಂಧಿಸಿದ ಅಗತ್ಯ ಮಾಹಿತಿಯನ್ನು ಸಂಗ್ರಹಿಸುತ್ತದೆ. ವ್ಯಾಪಾರ ಮಾಲೀಕರಿಗೆ, ನಿಮ್ಮ ಪ್ಯಾನ್‌ಗೆ ಸಂಬಂಧಿಸಿದ ಎಲ್ಲಾ ವ್ಯವಹಾರ ವಿವರಗಳನ್ನು ಒಳಗೊಳ್ಳಲು ನಾವು ನಮ್ಮ ಡೇಟಾ ಸಂಗ್ರಹಣೆಯನ್ನು ವಿಸ್ತರಿಸುತ್ತೇವೆ, ಉದಾಹರಣೆಗೆ GST ವಿವರಗಳು. , MCA ನಿಶ್ಚಿತಗಳು ಮತ್ತು GST ರಿಟರ್ನ್ ಫೈಲಿಂಗ್‌ಗಳ ದಾಖಲೆಗಳು.",
    "Our system evaluates your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.": "ಹಣಕಾಸಿನ ಒಳನೋಟಕ್ಕಾಗಿ ನಮ್ಮ ಸಿಸ್ಟಂ ನಿಮ್ಮ CIBIL ಸ್ಕೋರ್ ಅನ್ನು ಮೌಲ್ಯಮಾಪನ ಮಾಡುತ್ತದೆ ಮತ್ತು ನಿಮ್ಮ PAN ಗೆ ಸಂಬಂಧಿಸಿದ ಯಾವುದೇ ನಡೆಯುತ್ತಿರುವ ನ್ಯಾಯಾಲಯದ ಪ್ರಕರಣಗಳಿಗೆ ಅಡ್ಡ-ಪರಿಶೀಲಿಸುತ್ತದೆ.",
    "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system, a": "ನಿಮ್ಮ ಖಾತೆಯ ಸುರಕ್ಷತೆಯನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು, ದಯವಿಟ್ಟು ನಿಮ್ಮ ಮಾನ್ಯವಾದ ಇಮೇಲ್ ಐಡಿಯನ್ನು ನಿರ್ದಿಷ್ಟಪಡಿಸಿದ ಸ್ವರೂಪದಲ್ಲಿ ನಮೂದಿಸಿ- username@domain.com. ನಿಮ್ಮ ಇಮೇಲ್ ವಿಳಾಸವು ನಮ್ಮ ಸಿಸ್ಟಂನಲ್ಲಿ ಅಸ್ತಿತ್ವದಲ್ಲಿಲ್ಲದಿದ್ದರೆ,",
    "account will be created for you.": "ಖಾತೆಯನ್ನು ನಿಮಗಾಗಿ ರಚಿಸಲಾಗುವುದು.",
    "GST Verification Process": "GST ಪರಿಶೀಲನೆ ಪ್ರಕ್ರಿಯೆ",
    "We regret to inform you that the GST number entered does not match the required format (00-XXXXX-0000-X-0-X-0).Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "ನಮೂದಿಸಿದ GST ಸಂಖ್ಯೆಯು ಅಗತ್ಯವಿರುವ ಸ್ವರೂಪಕ್ಕೆ (00-XXXXX-0000-X-0-X-0) ಹೊಂದಿಕೆಯಾಗುತ್ತಿಲ್ಲ ಎಂದು ನಿಮಗೆ ತಿಳಿಸಲು ನಾವು ವಿಷಾದಿಸುತ್ತೇವೆ. ದಯವಿಟ್ಟು ನೀವು ಸರಿಯಾದ GST ವಿವರಗಳನ್ನು ನಮೂದಿಸಿರುವಿರಿ ಮತ್ತು ನಿಖರವಾದ ಪರಿಶೀಲನೆಗಾಗಿ ನಿರ್ದಿಷ್ಟಪಡಿಸಿದ ಸ್ವರೂಪವನ್ನು ಅನುಸರಿಸಿ.",
    "Verify GST": "GST ಪರಿಶೀಲಿಸಿ",
    "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "ಅಭಿನಂದನೆಗಳು! ನೀವು ನೋಂದಣಿ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ್ದೀರಿ. ಅಗತ್ಯ ಮಾಹಿತಿಯನ್ನು ಒದಗಿಸುವ ನಿಮ್ಮ ಸಮರ್ಪಣೆಯು ನಿಮಗೆ ಪರಿಣಾಮಕಾರಿಯಾಗಿ ಸೇವೆ ಸಲ್ಲಿಸಲು ನಮ್ಮನ್ನು ಒಂದು ಹೆಜ್ಜೆ ಹತ್ತಿರಕ್ಕೆ ತಂದಿದೆ.",
    "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.": "ಅನಾನುಕೂಲತೆಗಾಗಿ ನಾವು ಕ್ಷಮೆಯಾಚಿಸುತ್ತೇವೆ. ಒದಗಿಸಿದ ಹೆಸರು GST ದಾಖಲೆಗಳಿಗೆ ಹೊಂದಿಕೆಯಾಗುವುದಿಲ್ಲ. ನಮೂದಿಸಿದ ಹೆಸರು ನಿಮ್ಮ GST ಕಾರ್ಡ್‌ನಲ್ಲಿರುವ ಮಾಹಿತಿಗೆ ಹೊಂದಿಕೆಯಾಗುತ್ತಿದೆಯೇ ಎಂಬುದನ್ನು ದಯವಿಟ್ಟು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ ಹಾಗೂ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
    "Skip": "ಬಿಟ್ಟುಬಿಡಿ",
    "Server Overload: Please Retry Later": "ಸರ್ವರ್ ಓವರ್ಲೋಡ್: ದಯವಿಟ್ಟು ನಂತರ ಮರುಪ್ರಯತ್ನಿಸಿ",
    "Back To Home": "ಮರಳಿ ಮನೆಗೆ",
    
    //Dispute Webpage
    "Please input the Applicant’s Aadhaar number along with essential demographic details—name, date of birth, and gender. This process ensures accurate cross-checking for security. Thank you for your cooperation.":"ದಯವಿಟ್ಟು ಅರ್ಜಿದಾರರ ಆಧಾರ್ ಸಂಖ್ಯೆಯನ್ನು ಅಗತ್ಯ ಜನಸಂಖ್ಯಾ ವಿವರಗಳಾದ ಹೆಸರು, ಹುಟ್ಟಿದ ದಿನಾಂಕ ಮತ್ತು ಲಿಂಗದೊಂದಿಗೆ ನಮೂದಿಸಿ. ಈ ಪ್ರಕ್ರಿಯೆಯು ಭದ್ರತೆಗಾಗಿ ನಿಖರವಾದ ಅಡ್ಡ-ಪರಿಶೀಲನೆಯನ್ನು ಖಚಿತಪಡಿಸುತ್ತದೆ. ನಿಮ್ಮ ಸಹಕಾರಕ್ಕೆ ಧನ್ಯವಾದಗಳು.",
    "Verify Aadhaar":"",
    "less than Rs. 10000/- per month": "ಒಂದು ತಿಂಗಳಲ್ಲಿ Rs. 10000/- ಕ್ಕಿಂತ ಕಡಿಮೆ",
    "less than Rs. 20,000/- per month": "ಒಂದು ತಿಂಗಳಲ್ಲಿ Rs. 20,000/- ಕ್ಕಿಂತ ಕಡಿಮೆ",
    "less than Rs. 40,000/- per month": "ಒಂದು ತಿಂಗಳಲ್ಲಿ Rs. 40,000/- ಕ್ಕಿಂತ ಕಡಿಮೆ",
    "less than Rs. 60,000/- per month": "ಒಂದು ತಿಂಗಳಲ್ಲಿ Rs. 60,000/- ಕ್ಕಿಂತ ಕಡಿಮೆ",
    "less than Rs. 1,00,000/- per month": "ಒಂದು ತಿಂಗಳಲ್ಲಿ Rs. 1,00,000/- ಕ್ಕಿಂತ ಕಡಿಮೆ",
    "more than Rs. 1,00,000/- per month": "ಒಂದು ತಿಂಗಳಲ್ಲಿ Rs. 1,00,000/- ಕ್ಕಿಂತ ಹೆಚ್ಚು",
    "Payment Date":"ಪಾವತಿ ದಿನಾಂಕ",
    "Aadhaar Verification":"ಆಧಾರ್ ಪರಿಶೀಲನೆ",
    "To proceed further, we have initiated the Aadhaar verification process. Please provide the OTP (One-Time Password) that you have received to complete this step. Ensuring the security and authenticity of our users is paramount to us, and this verification step helps us maintain the integrity of our services.":"ಮುಂದೆ ಸಾಗಲು, ನಾವು ಆಧಾರ್ ಪರಿಶೀಲನಾ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಪ್ರಾರಂಭಿಸಿದ್ದೇವೆ. ಈ ಹಂತವನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು ದಯವಿಟ್ಟು ನೀವು ಸ್ವೀಕರಿಸಿದ ಒಟಿಪಿ (ಒನ್-ಟೈಮ್ ಪಾಸ್ ವರ್ಡ್) ಒದಗಿಸಿ. ನಮ್ಮ ಬಳಕೆದಾರರ ಸುರಕ್ಷತೆ ಮತ್ತು ಸತ್ಯಾಸತ್ಯತೆಯನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳುವುದು ನಮಗೆ ಅತ್ಯುನ್ನತವಾಗಿದೆ, ಮತ್ತು ಈ ಪರಿಶೀಲನಾ ಹಂತವು ನಮ್ಮ ಸೇವೆಗಳ ಸಮಗ್ರತೆಯನ್ನು ಕಾಪಾಡಿಕೊಳ್ಳಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
    "Thank You Aadhaar Verification Successfully":"ಆಧಾರ್ ಪರಿಶೀಲನೆ ಯಶಸ್ವಿಯಾಗಿರುವುದಕ್ಕೆ ಧನ್ಯವಾದಗಳು",
    "Thank you for providing the OTP for Aadhaar verification. With this step completed, we are now ready to proceed with your case.":"ಆಧಾರ್ ಪರಿಶೀಲನೆಗಾಗಿ ಒಟಿಪಿ ಒದಗಿಸಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ಈ ಹಂತ ಪೂರ್ಣಗೊಂಡ ನಂತರ, ನಾವು ಈಗ ನಿಮ್ಮ ಪ್ರಕರಣವನ್ನು ಮುಂದುವರಿಸಲು ಸಿದ್ಧರಿದ್ದೇವೆ.",
    "Our team will continue to work diligently to bring about a resolution that meets your needs and expectations. Should you have any questions or concerns along the way, please don't hesitate to reach out to us.":"ನಿಮ್ಮ ಅಗತ್ಯಗಳು ಮತ್ತು ನಿರೀಕ್ಷೆಗಳನ್ನು ಪೂರೈಸುವ ಪರಿಹಾರವನ್ನು ತರಲು ನಮ್ಮ ತಂಡವು ಶ್ರದ್ಧೆಯಿಂದ ಕೆಲಸ ಮಾಡುವುದನ್ನು ಮುಂದುವರಿಸುತ್ತದೆ. ದಾರಿಯುದ್ದಕ್ಕೂ ನಿಮಗೆ ಯಾವುದೇ ಪ್ರಶ್ನೆಗಳು ಅಥವಾ ಕಾಳಜಿಗಳಿದ್ದರೆ, ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ತಲುಪಲು ಹಿಂಜರಿಯಬೇಡಿ.",
    "Once again, thank you for entrusting PrivateCourt with your dispute resolution needs. We look forward to serving you.":"ಮತ್ತೊಮ್ಮೆ, ನಿಮ್ಮ ವಿವಾದ ಪರಿಹಾರ ಅಗತ್ಯಗಳನ್ನು ಖಾಸಗಿ ನ್ಯಾಯಾಲಯಕ್ಕೆ ವಹಿಸಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ನಿಮಗೆ ಸೇವೆ ಸಲ್ಲಿಸಲು ನಾವು ಎದುರು ನೋಡುತ್ತಿದ್ದೇವೆ.",
    // "Warm regards,":"",
    // "PrivateCourt Team":"",
    'Oops!':"ಓಹ್!",
    "The page you are searching, can't be found.":"ನೀವು ಹುಡುಕುತ್ತಿರುವ ಪುಟವು ಸಿಗಲಿಲ್ಲ.",
    'Language Selection:':'ಭಾಷಾ ಆಯ್ಕೆ:',
    'Empowering Effective Communication':'ಪರಿಣಾಮಕಾರಿ ಸಂವಹನವನ್ನು ಸಶಕ್ತಗೊಳಿಸುವುದು',
    "Good day! I'm Murthy, your dedicated assistant for today's proceedings. Lets start by selecting the language you're most comfortable with.":'ಶುಭ ದಿನ! ನಾನು ಮೂರ್ತಿ, ಇಂದಿನ ಕಾರ್ಯವೈಖರಿಗೆ ನಿಮ್ಮ ಸಮರ್ಪಿತ ಸಹಾಯಕ. ನೀವು ಹೆಚ್ಚು ಆರಾಮದಾಯಕವಾದ ಭಾಷೆಯನ್ನು ಆಯ್ಕೆ ಮಾಡುವ ಮೂಲಕ ಪ್ರಾರಂಭಿಸೋಣ.',
    "Opting for your preferred language ensures clear and effective communication. Whether it's your native tongue or another language of your choice, selecting it will streamline our interaction and enhance your experience.":"ನಿಮ್ಮ ಆದ್ಯತೆಯ ಭಾಷೆಯ ಆಯ್ಕೆಯು ಸ್ಪಷ್ಟ ಮತ್ತು ಪರಿಣಾಮಕಾರಿ ಸಂವಹನವನ್ನು ಖಚಿತಪಡಿಸುತ್ತದೆ. ಅದು ನಿಮ್ಮ ಮಾತೃಭಾಷೆಯಾಗಿರಲಿ ಅಥವಾ ನಿಮ್ಮ ಆಯ್ಕೆಯ ಇನ್ನೊಂದು ಭಾಷೆಯಾಗಿರಲಿ, ಅದನ್ನು ಆಯ್ಕೆ ಮಾಡುವುದರಿಂದ ನಮ್ಮ ಸಂವಹನವನ್ನು ಸುಗಮಗೊಳಿಸುತ್ತದೆ ಮತ್ತು ನಿಮ್ಮ ಅನುಭವವನ್ನು ಹೆಚ್ಚಿಸುತ್ತದೆ.",
    "Select Your Preferred Language":"ನಿಮ್ಮ ಆದ್ಯತೆಯ ಭಾಷೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    "Welcome to PrivateCourt, where Helping you is our priority.":"ಖಾಸಗಿ ನ್ಯಾಯಾಲಯಕ್ಕೆ ಸುಸ್ವಾಗತ, ಅಲ್ಲಿ ನಿಮಗೆ ಸಹಾಯ ಮಾಡುವುದು ನಮ್ಮ ಆದ್ಯತೆಯಾಗಿದೆ.",
    "Now, let me introduce you to PrivateCourt – your reliable partner in resolving lending disputes effectively and efficiently.":'ಈಗ, ನಾನು ನಿಮ್ಮನ್ನು ಖಾಸಗಿ ನ್ಯಾಯಾಲಯಕ್ಕೆ ಪರಿಚಯಿಸುತ್ತೇನೆ - ಸಾಲ ವಿವಾದಗಳನ್ನು ಪರಿಣಾಮಕಾರಿಯಾಗಿ ಮತ್ತು ಪರಿಣಾಮಕಾರಿಯಾಗಿ ಪರಿಹರಿಸುವಲ್ಲಿ ನಿಮ್ಮ ವಿಶ್ವಾಸಾರ್ಹ ಪಾಲುದಾರ.',
    "PrivateCourt":"ಖಾಸಗಿ ನ್ಯಾಯಾಲಯ", 
    "Your trusted partner in resolving lending disputes with care and expertise.":"ಕಾಳಜಿ ಮತ್ತು ಪರಿಣತಿಯೊಂದಿಗೆ ಸಾಲ ವಿವಾದಗಳನ್ನು ಪರಿಹರಿಸುವಲ್ಲಿ ನಿಮ್ಮ ವಿಶ್ವಾಸಾರ್ಹ ಪಾಲುದಾರ.",
    "Welcome to PrivateCourt, where your case details are in good hands. With the lending company initiating arbitration or conciliation, it's essential for us to understand your situation fully. Please share all the details you can.":"பிரைவேட் கோர்ட்டுக்கு வரவேற்கிறோம், உங்கள் வழக்கு விவரங்கள் நல்ல கைகளில் உள்ளன. கடன் வழங்கும் நிறுவனம் மத்தியஸ்தம் அல்லது சமரசத்தைத் தொடங்குவதால், உங்கள் நிலைமையை நாங்கள் முழுமையாகப் புரிந்துகொள்வது அவசியம். உங்களால் முடிந்த அனைத்து விவரங்களையும் பகிர்ந்து கொள்ளவும்.",
    "The more information we have, the better we can help. Your input is crucial in finding the right solution for you.":"ನಾವು ಹೆಚ್ಚು ಮಾಹಿತಿಯನ್ನು ಹೊಂದಿದ್ದೇವೆ, ನಾವು ಉತ್ತಮವಾಗಿ ಸಹಾಯ ಮಾಡಬಹುದು. ನಿಮಗಾಗಿ ಸರಿಯಾದ ಪರಿಹಾರವನ್ನು ಹುಡುಕುವಲ್ಲಿ ನಿಮ್ಮ ಇನ್ಪುಟ್ ನಿರ್ಣಾಯಕವಾಗಿದೆ.",
    "Trust us to carefully examine your case and work together to find the best way forward.":"ನಿಮ್ಮ ಪ್ರಕರಣವನ್ನು ಎಚ್ಚರಿಕೆಯಿಂದ ಪರೀಕ್ಷಿಸಲು ಮತ್ತು ಮುಂದೆ ಉತ್ತಮ ಮಾರ್ಗವನ್ನು ಕಂಡುಹಿಡಿಯಲು ಒಟ್ಟಾಗಿ ಕೆಲಸ ಮಾಡಲು ನಮ್ಮನ್ನು ನಂಬಿರಿ.",
    "Reviewing Your Dispute Information":'ನಿಮ್ಮ ವಿವಾದದ ಮಾಹಿತಿಯನ್ನು ಪರಿಶೀಲಿಸಲಾಗುತ್ತಿದೆ',
    "Let's take a closer look at the details surrounding your dispute. The information we're discussing today has been shared with us by Company, regarding a personal loan.":"ನಿಮ್ಮ ವಿವಾದದ ಸುತ್ತಲಿನ ವಿವರಗಳನ್ನು ಹತ್ತಿರದಿಂದ ನೋಡೋಣ. ನಾವು ಇಂದು ಚರ್ಚಿಸುತ್ತಿರುವ ಮಾಹಿತಿಯನ್ನು Company ಮೂಲಕ ವೈಯಕ್ತಿಕ ಸಾಲಕ್ಕೆ ಸಂಬಂಧಿಸಿದಂತೆ ನಮ್ಮೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳಲಾಗಿದೆ.",
    "By understanding these specifics, we can better assess the situation and formulate a resolution plan that aligns with your circumstances and goals.":"ಈ ನಿಶ್ಚಿತಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವ ಮೂಲಕ, ನಾವು ಪರಿಸ್ಥಿತಿಯನ್ನು ಉತ್ತಮವಾಗಿ ನಿರ್ಣಯಿಸಬಹುದು ಮತ್ತು ನಿಮ್ಮ ಸಂದರ್ಭಗಳು ಮತ್ತು ಗುರಿಗಳಿಗೆ ಹೊಂದಿಕೆಯಾಗುವ ರೆಸಲ್ಯೂಶನ್ ಯೋಜನೆಯನ್ನು ರೂಪಿಸಬಹುದು.",
    "Dispute Amount Information":"ವಿವಾದದ ಮೊತ್ತದ ಮಾಹಿತಿ",
    "According to their records, you initially borrowed an amount of":"ಪ್ರಸ್ತುತ, ನಿಮ್ಮ ಬಾಕಿಯು ರೂ. ಪಠ್ಯಕ್ಕಾಗಿ ಬ್ಯಾಕೆಂಡ್ ಡೆವಲಪರ್‌ನಿಂದ ಬನ್ನಿ.",
    "Presently, your outstanding due stands at Rs.":"ಪ್ರಸ್ತುತ, ನಿಮ್ಮ ಬಾಕಿಯು ರೂ. ಪಠ್ಯಕ್ಕಾಗಿ ಬ್ಯಾಕೆಂಡ್ ಡೆವಲಪರ್‌ನಿಂದ ಬನ್ನಿ.",
    "These details provide us with valuable insight into the history and current status of your loan.":"ಈ ವಿವರಗಳು ನಿಮ್ಮ ಸಾಲದ ಇತಿಹಾಸ ಮತ್ತು ಪ್ರಸ್ತುತ ಸ್ಥಿತಿಯ ಬಗ್ಗೆ ನಮಗೆ ಮೌಲ್ಯಯುತವಾದ ಒಳನೋಟವನ್ನು ಒದಗಿಸುತ್ತದೆ.",
    "Spotting Financial Issues: Understanding Unpaid Obligations":"ಹಣಕಾಸಿನ ಸಮಸ್ಯೆಗಳನ್ನು ಗುರುತಿಸುವುದು: ಪಾವತಿಸದ ಕಟ್ಟುಪಾಡುಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು",
    "Now, let's gently explore the challenges you've faced due to financial constraints. Discussing this may be sensitive, but your openness will greatly assist us in finding the best path forward. Please take a moment to select any of the following options that resonate with your experience:":"ಈಗ, ಹಣಕಾಸಿನ ನಿರ್ಬಂಧಗಳಿಂದಾಗಿ ನೀವು ಎದುರಿಸಿದ ಸವಾಲುಗಳನ್ನು ನಿಧಾನವಾಗಿ ಅನ್ವೇಷಿಸೋಣ. ಇದನ್ನು ಚರ್ಚಿಸುವುದು ಸಂವೇದನಾಶೀಲವಾಗಿರಬಹುದು, ಆದರೆ ನಿಮ್ಮ ಮುಕ್ತತೆ ಉತ್ತಮ ಮಾರ್ಗವನ್ನು ಕಂಡುಕೊಳ್ಳುವಲ್ಲಿ ನಮಗೆ ಹೆಚ್ಚು ಸಹಾಯ ಮಾಡುತ್ತದೆ. ನಿಮ್ಮ ಅನುಭವದೊಂದಿಗೆ ಅನುರಣಿಸುವ ಕೆಳಗಿನ ಯಾವುದೇ ಆಯ್ಕೆಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ಸ್ವಲ್ಪ ಸಮಯ ತೆಗೆದುಕೊಳ್ಳಿ:",
    "Financial Loss":"ಆರ್ಥಿಕ ನಷ್ಟ",
    "Business Loss":"ವ್ಯಾಪಾರ ನಷ್ಟ",
    "Partner Dispute":"ಭಾಗಸದಾರರ ವಿವಾದ",
    "Funds held with other vendors or clients":"ಇತರ ಮಾರಾಟಗಾರರು ಅಥವಾ ಗ್ರಾಹಕರ ಬಳಿ ನಿಂತಿರುವ ನಿಧಿಗಳು",
    "Monetary loss through other means":"ಇತರೆ ಮಾರ್ಗಗಳಿಂದ ಹಣಕಾಸಿನ ನಷ್ಟ",
    "Job Loss":"ಉದ್ಯೋಗ ನಷ್ಟ",
    "Less than 2 months":"2 ತಿಂಗಳಿಗಿಂತ ಕಡಿಮೆ",
    "Less than 6 months":"6 ತಿಂಗಳಿಗಿಂತ ಕಡಿಮೆ",
    "Less than 1 year":"1 ವರ್ಷಕ್ಕಿಂತ ಕಡಿಮೆ",
    "More than 1 year":"1 ವರ್ಷಕ್ಕಿಂತ ಹೆಚ್ಚು",
    "Health Issue":"ಆರೋಗ್ಯ ಸಮಸ್ಯೆ",
    "Death of the earning member":"ಆದಾಯಗಳಿಸೋ ಸದಸ್ಯರ ಸಾವು",
    "Missing payment details":"ಪಾವತಿ ವಿವರಗಳು ಕಾಣೆಯಾಗಿದೆ",
    "Dispute due to bad behaviour":"ಕೆಟ್ಟ ನಡವಳಿಕೆಯ ಕಾರಣದಿಂದ ವಿವಾದ",
    "No clear loan information":"ಸ್ಪಷ್ಟವಾದ ಸಾಲದ ಮಾಹಿತಿ ಇಲ್ಲ",
    "Death Case":"ಮರಣದ ಪ್ರಕರಣ",
    "We kindly request you to upload the death certificate at":"ನಾವು ದಯವಿಟ್ಟು ನೀವು ಸಾವಿನ ಪ್ರಮಾಣಪತ್ರವನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಲು ವಿನಂತಿಸುತ್ತೇವೆ",
    "Other reason/s":"ಇತರೆ ಕಾರಣಗಳು",
    "Your prompt response will greatly assist us in assessing the situation and finding the most suitable resolution. Rest assured, we are committed to working with you to resolve any outstanding issues and ensure a fair and satisfactory outcome.":"ನಿಮ್ಮ ತ್ವರಿತ ಪ್ರತಿಕ್ರಿಯೆ ಪರಿಸ್ಥಿತಿಯನ್ನು ಮೌಲ್ಯಮಾಪನ ಮಾಡುವಲ್ಲಿ ಮತ್ತು ಅತ್ಯುತ್ತಮ ಪರಿಹಾರ ಕಂಡುಹಿಡಿಯುವಲ್ಲಿ ನಮಗೆ ಬಹಳ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ನಿಮಗೆ ಖಚಿತಪಡಿಸಬಹುದು, ನಾವು ಯಾವುದೇ ಬಾಕಿ ಇರುವ ಸಮಸ್ಯೆಗಳನ್ನು ಪರಿಹರಿಸಲು ಮತ್ತು ನ್ಯಾಯಸಮ್ಮತ ಮತ್ತು ತೃಪ್ತಿಕರ ಫಲಿತಾಂಶವನ್ನು ಖಚಿತಪಡಿಸಲು ನಿಮಗೆ ಸಹಕರಿಸಲು ಬದ್ಧರಾಗಿದ್ದೇವೆ.",
    "Exploring Settlement Possibilities":"ತೀರ್ಮಾನ ಸಾಧ್ಯತೆಗಳನ್ನು ಅನ್ವೇಷಣೆ ಮಾಡುವುದು",
    "As our discussion progresses, I appreciate your openness in explaining the reason for the default. It's crucial to address your outstanding dues promptly to prevent any legal complexities. We recommend exploring different avenues, such as seeking assistance from family or investigating alternative financing options, to fulfill your obligations without delay.":"ನಮ್ಮ ಚರ್ಚೆ ಮುಂದುವರಿದಂತೆ, ವೈಫಲ್ಯದ ಕಾರಣವನ್ನು ವಿವರಿಸಲು ನಿಮ್ಮ ತೆರೆದ ಮನಸ್ಸಿಗೆ ನಾನು ಮೆಚ್ಚುಗೆ ವ್ಯಕ್ತಪಡಿಸುತ್ತೇನೆ. ಯಾವುದೇ ಕಾನೂನು ತಾಂತ್ರಿಕತೆಗಳನ್ನು ತಡೆಯಲು ನಿಮ್ಮ ಬಾಕಿ ಬಿಲ್‌ಗಳನ್ನು ತ್ವರಿತವಾಗಿ ಪರಿಹರಿಸುವುದು ಅತ್ಯವಶ್ಯಕವಾಗಿದೆ. ಕುಟುಂಬದ ಸಹಾಯವನ್ನು ಪಡೆಯುವುದು ಅಥವಾ ಪರ್ಯಾಯ ಹಣಕಾಸು ಆಯ್ಕೆಗಳನ್ನು ಪರಿಶೀಲಿಸುವಂತಹ ವಿಭಿನ್ನ ಮಾರ್ಗಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ನಾವು ಶಿಫಾರಸು ಮಾಡುತ್ತೇವೆ, ನಿಮ್ಮ ಬಾಧ್ಯತೆಯನ್ನು ವಿಳಂಬವಿಲ್ಲದೆ ಪೂರೈಸಲು.",
    "Timely settlement not only mitigates legal risks but also strengthens trust and credibility in your financial interactions. Your cooperation in this matter is truly valued.":"ಕಾಲಮಿತಿೊಳಗಿನ ತೀರ್ಮಾನವು ಕೇವಲ ಕಾನೂನು ಅಪಾಯಗಳನ್ನು ತಗ್ಗಿಸುವುದಷ್ಟೇ ಅಲ್ಲ, ನಿಮ್ಮ ಹಣಕಾಸು ವ್ಯವಹಾರಗಳಲ್ಲಿ ವಿಶ್ವಾಸ ಮತ್ತು ವಿಶ್ವಾಸಾರ್ಹತೆಯನ್ನು ಕೂಡ ಬಲಪಡಿಸುತ್ತದೆ. ಈ ವಿಷಯದಲ್ಲಿ ನಿಮ್ಮ ಸಹಕಾರವನ್ನು ನಿಜವಾಗಿಯೂ ಮೆಚ್ಚಲಾಗುತ್ತದೆ.",
    "Settlement Options":"ತೀರ್ಮಾನ ಆಯ್ಕೆಗಳು",
    "In our efforts to facilitate a swift resolution, we seek your cooperation.":"ವೇಗವಾದ ಪರಿಹಾರವನ್ನು ಸುಲಭಗೊಳಿಸಲು ನಮ್ಮ ಪ್ರಯತ್ನಗಳಲ್ಲಿ, ನಾವು ನಿಮ್ಮ ಸಹಕಾರವನ್ನು ಕೋರುತ್ತೇವೆ.",
    "Are you open to discussing the settlement of your outstanding dues?":"ನಿಮ್ಮ ಬಾಕಿ ಬಿಲ್‌ಗಳ ತೀರ್ಮಾನವನ್ನು ಚರ್ಚಿಸಲು ನೀವು ತೆರೆದಿದ್ದಾರೆ?",
    "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly, could you kindly provide details on your current family income?":"ನಿಮ್ಮ ಬಾಕಿ ಬಿಲ್‌ಗಳನ್ನು ಪರಿಹರಿಸಲು ನಿಮ್ಮ ತೆರೆದ ಮನಸ್ಸಿಗೆ ನಾವು ಮೆಚ್ಚುಗೆ ವ್ಯಕ್ತಪಡಿಸುತ್ತೇವೆ. ನಿಮ್ಮ ಹಣಕಾಸು ಪರಿಸ್ಥಿತಿಯನ್ನು ಉತ್ತಮವಾಗಿ ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಮತ್ತು ಅದರಂತೆ ನಮ್ಮ ದೃಷ್ಠಿಕೋಣವನ್ನು ಹೊಂದಿಸಲು, ದಯವಿಟ್ಟು ನಿಮ್ಮ ಪ್ರಸ್ತುತ ಕುಟುಂಬ ಆದಾಯದ ವಿವರಗಳನ್ನು ಒದಗಿಸಬಹುದೆ?",
    "In our efforts to address your outstanding dues, we'd like to know if a one-time payment settlement is an option for you.":"ನಿಮ್ಮ ಬಾಕಿ ಬಿಲ್‌ಗಳನ್ನು ಪರಿಹರಿಸಲು ನಮ್ಮ ಪ್ರಯತ್ನಗಳಲ್ಲಿ, ಒಮ್ಮೆ ಪಾವತಿ ತೀರ್ಮಾನವು ನಿಮ್ಮಿಗೆ ಆಯ್ಕೆಯಾಗಿದೆ ಎಂದು ತಿಳಿದುಕೊಳ್ಳಲು ನಾವು ಇಚ್ಛಿಸುತ್ತೇವೆ.",
    "Next":"ಮುಂದೆ",
    "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly,": "ನಿಮ್ಮ ಹೊರಗೆ ನಿಂತ ಹೊರಗೊಮ್ಮಲಿನ ನ್ಯಾಯೋಚಿತ ಸಂದರ್ಭದಲ್ಲಿ ನಿಮ್ಮ ಆರ್ಥಿಕ ಸ್ಥಿತಿಯನ್ನು ಹೆಚ್ಚಳಿಕೆಗೊಳಿಸಲು ಮತ್ತು ನಮ್ಮ ಮಾರ್ಗವನ್ನು ಅನುಕೂಲಿಸಲು,",
    "could you kindly provide details on your current family income?": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಪ್ರಸ್ತುತ ಕುಟುಂಬ ಆದಾಯವನ್ನು ವಿವರಿಸಿಕೊಡಲು ಸಾಧ್ಯವೇ?",
    "In our efforts to address your outstanding dues, we'd like to know if a": "ನಿಮ್ಮ ಅತೀತ ಉತ್ತಮ ಪಾವತಿಗಳನ್ನು ಸರಿಪಡಿಸಲು ನಮ್ಮ ಪ್ರಯತ್ನಗಳಲ್ಲಿ, ನಮಗೆ ಗೊತ್ತಾಗಲು ಇದೊಂದು ",
    "one-time payment settlement is an option for you.": "ಒಮ್ಮೆಯಲ್ಲಿ ಪಾವತಿ ಸಂಬಂಧಿಸುವ ಆಯ್ಕೆ ನಿಮಗೆ ಇದೆಯೆಂದು ನಾವು ಬಯಸುತ್ತೇವೆ."

}

export default kannadaTranslation;