import { useEffect, useState } from "react";
const AgeModal = () => {
  const [yearsDifference, setYearsDifference] = useState({});
  const [accounts,setAccounts] = useState([]);
  const calculateDateDifference = (dateString) => {
    const regex = /(\d{2}-\d{2}-\d{4})/;
    const match = dateString.match(regex);
  
    if (match) {
      const dateParts = match[0].split('-');
      const date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
  
      const now = new Date();
      let years = now.getFullYear() - date.getFullYear();
      let months = now.getMonth() - date.getMonth();
      let days = now.getDate() - date.getDate();
  
      if (days < 0) {
        months -= 1;
        days += new Date(now.getFullYear(), now.getMonth(), 0).getDate();
      }
  
      if (months < 0) {
        years -= 1;
        months += 12;
      }
  
      return { years, months, days };
    }
  
    return { years: 0, months: 0, days: 0 };
  };
  useEffect(() => {
    fetch('/CibilScore.json')
    .then(response => response.json())
    .then(data => {
      const filterAccounts1 = data.credit_report.CCRResponse.CIRReportDataLst[0].CIRReportData.RetailAccountDetails.map((item)=>({
        AccountNumber:item.AccountNumber,
        BankName:item.Institution,
        AccountType:item.AccountType,
        Active:item.Open,
        PendingAmount:item.Balance,
        LastPaymentDate:item.LastPaymentDate,
        history:item.History48Months
    }))
    setAccounts(filterAccounts1);
      const filterAccounts = data.credit_report.CCRResponse.CIRReportDataLst[0].CIRReportData.RetailAccountsSummary;
      const extractyears = calculateDateDifference(filterAccounts.OldestAccount);
      extractyears.noOfActiveAccounts = filterAccounts.NoOfActiveAccounts;
      setYearsDifference(extractyears);
    });
}, []);
    return (
        <div
      className="modal fade"
      id="ageModel"
      tabIndex="-1"
      aria-labelledby="ageModel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Age Details</h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <div class="modal-body">
            <div class="card card-new">
              <div class="d-flex payment-header">
                <div class="payment-header-left w-50">
                  <h2 class="mb-0">Medium Impact</h2>
                  <p>
                    Last updated on <strong>13 Jun 2024</strong>
                  </p>
                </div>
                <div class="payment-header-right w-50 text-end  align-items-center">
                  <span class="badge text-bg-success mt-3">{yearsDifference.years > 10 ? "Excellence" : "Fair"}</span>
                </div>
              </div>
              <div class="d-flex mt-2">
                <div class="">
                  <h2 class="mb-0">{yearsDifference.noOfActiveAccounts}</h2>
                  <p class="mb-0">Active Accounts</p>
                </div>
                <div class="ps-5">
                  <h2 class="mb-0">{yearsDifference.years} y {yearsDifference.months} m </h2>
                  <p class="mb-0">Age of Accounts</p>
                </div>
                
              </div>
            </div>
            <div class="card card-new text-bg-light">
              <div class="d-flex payment-header">
              <div style={{marginRight:"8px",marginTop:"4px"}}>
                  <i className="fas fa-thumbs-up text-info" style={{ fontSize: '30px' }}></i>
              </div>
                <div class="payment-header-left w-60">
                  <h2 class="p-accounts mb-0 text-info"> {yearsDifference.years > 10 ? "High Account Age" : "Average Account Age"}</h2>
                  <p>Good Job ! You have a account for last <strong>{yearsDifference.years} years {yearsDifference.months} months</strong>
                  </p>
                </div>
              </div>
            </div>
            <h4>Your Accounts</h4>
          
              {accounts.map((item,index)=> {
                const isClosed = item.Active !== "Yes";
              return (
              <div className={`d-flex border-bottom pb-3 pt-3 ${isClosed ? 'blur' : ''}`}>
              <div class="flex-shrink-0">
                <img src="https://placehold.co/80x80/EEE/31343C" alt="..." />
              </div>
              <div class="flex-grow-1 ms-3">
                <h4 class="mb-0">{item.BankName}</h4>
                <p class="mb-0 text-muted">{item.AccountType}: {item.AccountNumber}</p>
                <p class="mb-0 text-muted">
                  Status: <strong class="text-primary">{item.Active == "Yes" ? "Active" : "closed"}</strong>
                </p>
              </div>
            </div>
              );
            })} 
          </div>
        </div>
      </div>
    </div>
    )
}

export default AgeModal;