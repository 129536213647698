import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import VerifyButton from "./verifyButton";
import http from "./../../../services/httpService";
import { changeStage } from "../../../features/verificationStage/verificationSlice";
import useLanguage from "../../../local/saveLanguage";
import { changeEmail } from "../../../features/email/emailSlice";

const MidEmail = (props) => {
  const [email, setEmail] = useState("");
  const [emailEr, setEmailEr] = useState(false);
  const translate = useLanguage.getLang();
  const dispatch = useDispatch();
  // const theme = useSelector((state) => state.theme);

  const handleEmailChange = (e) => {
    const input = e.target.value;
    setEmailEr(false)
    const lowercaseInput = input.replace(/[A-Z]/g, (char) =>
      char.toLowerCase()
    );
    const newInput = lowercaseInput.replace(/[\/%#&!${},<>|*~`:;'",]/g, "");
    setEmail(newInput);
    // setEmailV(false);
  };

  const handleSubmit = (e) => {
    // console.log(email);
    e.preventDefault();
    http
      .post2("/loginUser", {
        email,
      })
      .then((response) => {
        console.log("Response:", response);
        console.log('dispatch1');
        // props.onVerify("emailOtp");
        dispatch(changeStage("emailOtp"));
        dispatch(changeEmail(email));
        console.log('dispatch2');
        // setCheck(true);
        // setEmailV(false);
      })
      .catch((error) => {
        // alert("email is not verified");
        setEmailEr(true);
        console.error("Error:", error);
      });
  };

  return (
    <div className="login-mid">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-5 login-mid-left">
            <h2 data-translate="tran-1">{translate?.["Email Security Check"] ?? "Email Security Check"}</h2>
          </div>
          <div className="col-xl-5 col-lg-7 offset-xl-1">
            <div className="card pb-normal bank-verification">
              <div className="vf-content">
                <h1 data-translate="tran-2">
                  {translate?.["Verify Your Email for Account Access"] ?? "Verify Your Email for Account Access"}

                </h1>
                <p>
                  <span data-translate="tran-3">
                    {translate?.["To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system, a"] ?? "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system, a"}
                  </span>{" "}
                  <strong data-translate="tran-4">{translate?.["NEW USER"] ?? "NEW USER"}</strong>{" "}
                  <span data-translate="tran-5">
                    {translate?.["account will be created for you."] ?? "account will be created for you."}

                  </span>
                </p>
                <p data-translate="tran-6">
                  {translate?.["Once submitted, you will receive a Verification OTP (One-Time Password) sent to your provided email address."] ?? "Once submitted, you will receive a Verification OTP (One-Time Password) sent to your provided email address."}
                </p>
                <p data-translate="tran-7">
                  {translate?.["For any queries or assistance, feel free to call us at +91-9699900111."] ?? "For any queries or assistance, feel free to call us at +91-9699900111."}
                </p>
                <p>
                  <strong data-translate="tran-8">
                    {translate?.["Thank you for entrusting PrivateCourt. Your security is our priority."] ?? "Thank you for entrusting PrivateCourt. Your security is our priority."}
                  </strong>
                </p>
              </div>
              <input
                className="form-control"
                type="text"
                value={email}
                onChange={handleEmailChange}
                placeholder="akshita@gmail.com"
              />
              {
                emailEr && <div className="alert alert-danger vfe-content" role="alert">
                  <div className="text-center">
                    <i className="bi bi-exclamation-triangle-fill text-danger"></i>
                  </div>
                  <h4 className="alert-heading" data-translate="tran-10">
                    {translate?.["Invalid Email Address"] ?? "Invalid Email Address"}
                  </h4>
                  <p data-translate="tran-11">
                    {translate?.["We regret to inform you that the email address entered is invalid. Please ensure that you have entered a correct and properly formatted email address."] ?? "We regret to inform you that the email address entered is invalid. Please ensure that you have entered a correct and properly formatted email address."}
                  </p>
                  <p data-translate="tran-12">
                    {translate?.["If the issue persists, double-check for any typos or errors in the email format (e.g., username@example.com)."] ?? "If the issue persists, double-check for any typos or errors in the email format (e.g., username@example.com)."}
                  </p>
                  <p data-translate="tran-13">
                    {translate?.["For further queries or assistance, feel free to call us at +91-9699900111."] ?? "For further queries or assistance, feel free to call us at +91-9699900111."}
                  </p>
                  <p>
                    <strong data-translate="tran-14">
                      {translate?.["Thank you for entrusting PrivateCourt. Your security is our priority."] ?? "Thank you for entrusting PrivateCourt. Your security is our priority."}
                    </strong>
                  </p>
                </div>
              }

              <div className="ms-auto">
                <VerifyButton btnName="Verify" onSubmit={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MidEmail;
