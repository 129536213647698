import { Country, State, City } from "country-state-city";
import { useEffect, useState } from "react";

export default function Location(props) {
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedState, setSelectedState] = useState({});
  const [selectedCity, setSelectedCity] = useState({});

  useEffect(() => {
    props.handleSelectChange(selectedCountry, selectedState, selectedCity);
  }, [selectedCountry, selectedCity, selectedState]);
  return (
    <>
      <div className="col-lg-4 ">
      <select className="form-select mb-3" aria-label="Default select example"
        value={selectedCountry ? selectedCountry.name : ""}
        onChange={(e) => {
          const selectedCountryValue = e.target.value;
          const country = Country.getAllCountries().find(
            (country) => country.name === selectedCountryValue
          );
          setSelectedCountry(country);
        }}
      >
        <option value="">Select a Country</option>
        {Country.getAllCountries().map((country) => (
          <option key={country.isoCode} value={country.name}>
            {country.name}
          </option>
        ))}
      </select>
      </div>

          <div className="col-lg-4">
                  <select className="form-select mb-3" aria-label="Default select example"
        value={selectedState ? selectedState.name : ""}
        onChange={(e) => {
          const selectedStateValue = e.target.value;
          const state = State.getStatesOfCountry(
            selectedCountry?.isoCode
          ).find((state) => state.name === selectedStateValue);
          setSelectedState(state);
        }}
      >
        <option value="">Select a State</option>
        {selectedCountry &&
          State.getStatesOfCountry(selectedCountry.isoCode).map((state) => (
            <option key={state.isoCode} value={state.name}>
              {state.name}
            </option>
          ))}
      </select>
          </div>
            <div className="col-lg-4">
     <select className="form-select mb-3" aria-label="Default select example"
        value={selectedCity ? selectedCity.name : ""}
        onChange={(e) => {
          const selectedCityValue = e.target.value;
          const city = City.getCitiesOfState(
            selectedState?.countryCode,
            selectedState?.isoCode
          ).find((city) => city.name === selectedCityValue);
          setSelectedCity(city);
        }}
      >
        <option value="">Select a City</option>
        {selectedState &&
          City.getCitiesOfState(
            selectedState.countryCode,
            selectedState.isoCode
          ).map((city) => (
            <option key={city.id} value={city.name}>
              {city.name}
            </option>
          ))}
      </select>
            </div>

 
    </>
  );
}
 {/* <div className="container">
        <h2 className="text-center mt-4">New Lead Form</h2>
        <div
          style={{ width: "100%", height: "1px", background: "#274591" }}
        ></div>
        <form onSubmit={handlerforSubmit}>
        <div className="row justify-content-center mt-4 ">
          <div className="col-md-4 mb-3">
            <label className="form-label">Name</label>
            <input 
            className="form-control" 
            name="name"
            value={name}
            onChange={handleInputChange} />
          </div>
          <div className="col-md-4 mb-3">
            <label className="form-label">Email</label>
            <input 
            className="form-control"
            name="email"
            value={email}
            onChange={handleInputChange} />
          </div>
        </div>
        <div className="row justify-content-center mt-4">
          <div className="col-md-4 mb-3">
            <label className="form-label">Phone</label>
            <input 
            className="form-control"
            name="phone"
            value={phone}
            onChange={handleInputChange} />
          </div>
          <div className="col-md-4 mb-3">
            <label className="form-label">Source</label>
            <input 
            className="form-control"
            name="source"
            value={source}
            onChange={handleInputChange} />
          </div>
        </div>
        <div className="row mt-4 justify-content-center">
          <div className="col-md-8 mb-3">
            <label className="form-label">Country</label>
            <div className="d-flex">
              <select
                className="form-control mb-3 mx-3"
                value={selectedCountry ? selectedCountry.name : ""}
                onChange={(e) => {
                  const selectedCountryValue = e.target.value;
                  const country = Country.getAllCountries().find(
                    (country) => country.name === selectedCountryValue
                  );
                  setSelectedCountry(country);
                }}
              >
                <option value="">Select a Country</option>
                {Country.getAllCountries().map((country) => (
                  <option key={country.isoCode} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>

              <select
                className="form-control mb-3"
                value={selectedState ? selectedState.name : ""}
                onChange={(e) => {
                  const selectedStateValue = e.target.value;
                  const state = State.getStatesOfCountry(
                    selectedCountry?.isoCode
                  ).find((state) => state.name === selectedStateValue);
                  setSelectedState(state);
                }}
              >
                <option value="">Select a State</option>
                {selectedCountry &&
                  State.getStatesOfCountry(selectedCountry.isoCode).map(
                    (state) => (
                      <option key={state.isoCode} value={state.name}>
                        {state.name}
                      </option>
                    )
                  )}
              </select>

              <select
                className="form-control mb-3 mx-3"
                value={selectedCity ? selectedCity.name : ""}
                onChange={(e) => {
                  const selectedCityValue = e.target.value;
                  const city = City.getCitiesOfState(
                    selectedState?.countryCode,
                    selectedState?.isoCode
                  ).find((city) => city.name === selectedCityValue);
                  setSelectedCity(city);
                }}
              >
                <option value="">Select a City</option>
                {selectedState &&
                  City.getCitiesOfState(
                    selectedState.countryCode,
                    selectedState.isoCode
                  ).map((city) => (
                    <option key={city.id} value={city.name}>
                      {city.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row mt-4 justify-content-center">
          <div className="col-md-4 mb-3">
            <label className="form-label">Organization Name</label>
            <input 
            className="form-control"
            value={organization}
            name="organization"
            onChange={handleInputChange} />
          </div>
          <div className="col-md-4 mb-3">
            <label className="form-label">User Type</label>
            <select className="form-control"
             value={user}
             name="user"
             onChange={handleInputChange}>
              <option>Select Type</option>
              <option>NBFC</option>
              <option>MSME</option>
              <option>SME</option>
            </select>
          </div>
        </div>
        <div>
          <h4 className="text-center">Additional Information</h4>
          <div className="row mt-4 justify-content-center">
            <div className="col-md-8 mb-3">
              <div class="form-group">
                <label for="exampleFormControlTextarea1">
                  Details
                </label>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  name="detail"
                  value={detail}
                  onChange={handleInputChange}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-3 mt-3 d-flex justify-content-center">
          <button type="submit" className="btn btn-primary">
            Create New Lead
          </button>
        </div>
        </form>
      </div> */}
