import React, { useState } from 'react';

const SingleSelectCheckbox = ({ label, checked, onChange }) => {
  return (
    <div className="form-check financial-loss-c mb-0">
      <input
        className="form-check-input"
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <label className="form-check-label fw-bold">{label}</label>
    </div>
  );
};

export default SingleSelectCheckbox;