const TeluguTranslation = {
    "Verify Aadhaar":"ఆధార్ వెరిఫై చేయండి",
    "less than Rs. 10000/- per month": "ఒక నెల లో Rs. 10000/- కంటే తక్కువగా",
    "less than Rs. 20,000/- per month": "ఒక నెల లో Rs. 20,000/- కంటే తక్కువగా",
    "less than Rs. 40,000/- per month": "ఒక నెల లో Rs. 40,000/- కంటే తక్కువగా",
    "less than Rs. 60,000/- per month": "ఒక నెల లో Rs. 60,000/- కంటే తక్కువగా",
    "less than Rs. 1,00,000/- per month": "ఒక నెల లో Rs. 1,00,000/- కంటే తక్కువగా",
    "more than Rs. 1,00,000/- per month": "ఒక నెల లో Rs. 1,00,000/- కంటే ఎక్కువ",
    "Language Selection:": "భాష ఎంపిక:",
    "Empowering Effective Communication": "ఎఫెక్టివ్ కమ్యూనికేషన్ సాధికారత",
    "Good day! I'm Murthy, your dedicated assistant for today's proceedings. Let's start by selecting the language you're most comfortable with.":"మంచి రోజు! నేను మూర్తిని, ఈరోజు ప్రొసీడింగ్స్‌కి మీ డెడికేటెడ్ అసిస్టెంట్. మీకు అత్యంత సౌకర్యవంతంగా ఉండే భాషను ఎంచుకోవడం ద్వారా ప్రారంభిద్దాం.",
    "Select Your Preferred Language": "మీరు ఇష్టపడే భాషను ఎంచుకోండి",
    "Opting for your preferred language ensures clear and effective communication. Whether it's your native tongue or another language of your choice, selecting it will streamline our interaction and enhance your experience.":"మీకు నచ్చిన భాషను ఎంచుకోవడం వలన స్పష్టమైన మరియు ప్రభావవంతమైన సంభాషణ జరుగుతుంది. అది మీ మాతృభాష అయినా లేదా మీకు నచ్చిన మరో భాష అయినా, దాన్ని ఎంచుకోవడం ద్వారా మా పరస్పర చర్యను క్రమబద్ధం చేస్తుంది మరియు మీ అనుభవాన్ని మెరుగుపరుస్తుంది.",
    "Next": "తరువాత",
    "Welcome to PrivateCourt, where Helping you is our priority.": "ప్రైవేట్‌కోర్టుకు స్వాగతం, ఇక్కడ మీకు సహాయం చేయడం మా ప్రాధాన్యత.",
    "Now, let me introduce you to PrivateCourt - your reliable partner in resolving lending disputes effectively and efficiently.":"ఇప్పుడు, నేను మిమ్మల్ని ప్రైవేట్‌కోర్ట్‌కి పరిచయం చేస్తున్నాను - రుణ వివాదాలను సమర్థవంతంగా మరియు సమర్ధవంతంగా పరిష్కరించడంలో మీ నమ్మకమైన భాగస్వామి.",
    "PrivateCourt: Your trusted partner in resolving lending disputes with care and expertise.":"ప్రైవేట్‌కోర్టు: రుణ వివాదాలను జాగ్రత్తగా మరియు నైపుణ్యంతో పరిష్కరించడంలో మీ విశ్వసనీయ భాగస్వామి.",
    "Welcome to PrivateCourt, where your case details are in good hands. With the lending company initiating arbitration or conciliation, it's essential for us to understand your situation fully. Please share all the details you can.":"మీ కేసు వివరాలు మంచి చేతుల్లో ఉన్న ప్రైవేట్‌కోర్టుకు స్వాగతం. రుణం ఇచ్చే కంపెనీ మధ్యవర్తిత్వం లేదా రాజీని ప్రారంభించడంతో, మీ పరిస్థితిని పూర్తిగా అర్థం చేసుకోవడం మాకు చాలా అవసరం. దయచేసి మీరు చేయగలిగిన అన్ని వివరాలను పంచుకోండి.",
    "The more information we have, the better we can help. Your input is crucial in finding the right solution for you.":"మన దగ్గర ఎంత ఎక్కువ సమాచారం ఉంటే అంత బాగా సహాయం చేయగలం. మీకు సరైన పరిష్కారాన్ని కనుగొనడంలో మీ ఇన్‌పుట్ కీలకం.",
    "Trust us to carefully examine your case and work together to find the best way forward.":"మీ కేసును జాగ్రత్తగా పరిశీలించడానికి మరియు ముందుకు సాగడానికి ఉత్తమమైన మార్గాన్ని కనుగొనడానికి కలిసి పని చేయడానికి మమ్మల్ని నమ్మండి.",
    "Reviewing Your Dispute Information": "మీ వివాద సమాచారాన్ని సమీక్షిస్తోంది",
    "Let's take a closer look at the details surrounding your dispute.": "మీ వివాదానికి సంబంధించిన వివరాలను నిశితంగా పరిశీలిద్దాం.",
    "By understanding these specifics, we can better assess the situation and formulate a resolution plan that aligns with your circumstances and goals.":"ఈ ప్రత్యేకతలను అర్థం చేసుకోవడం ద్వారా, మేము పరిస్థితిని బాగా అంచనా వేయవచ్చు మరియు మీ పరిస్థితులు మరియు లక్ష్యాలకు అనుగుణంగా పరిష్కార ప్రణాళికను రూపొందించవచ్చు.",
    "Dispute Amount Information": "వివాద మొత్తం సమాచారం",
    "According to their records, you initially borrowed an amount of": "వారి రికార్డుల ప్రకారం, మీరు మొదట్లో కొంత మొత్తాన్ని అప్పుగా తీసుకున్నారు",
    "Presently, your outstanding due stands at Rs.": "ప్రస్తుతం, మీ బకాయి రూ.",
    "These details provide us with valuable insight into the history and current status of your loan.":"ఈ వివరాలు మీ లోన్ చరిత్ర మరియు ప్రస్తుత స్థితిపై మాకు విలువైన అంతర్దృష్టిని అందిస్తాయి.",
    "Spotting Financial Issues:": "ఆర్థిక సమస్యలను గుర్తించడం:",
    "Understanding Unpaid Obligations": "చెల్లించని బాధ్యతలను అర్థం చేసుకోవడం",
    "Now, let's gently explore the challenges you've faced due to financial constraints. Discussing this may be sensitive, but your openness will greatly assist us in finding the best path forward. Please take a moment to select any of the following options that resonate with your experience:":"ఇప్పుడు, ఆర్థిక పరిమితుల కారణంగా మీరు ఎదుర్కొన్న సవాళ్లను సున్నితంగా అన్వేషిద్దాం. దీని గురించి చర్చించడం చాలా సున్నితంగా ఉండవచ్చు, కానీ మీ నిష్కాపట్యత ఉత్తమమైన మార్గాన్ని కనుగొనడంలో మాకు బాగా సహాయపడుతుంది. దయచేసి మీ అనుభవంతో ప్రతిధ్వనించే క్రింది ఎంపికలలో దేనినైనా ఎంచుకోవడానికి కొంత సమయం కేటాయించండి:",
    "Financial Loss": "ఆర్థిక నష్టం",
    "Business Loss": "వ్యాపారంలో నష్టం",
    "Job Loss": "ఉద్యోగ నష్టం",
    "Health Issue": "ఆరోగ్య సమస్య",
    "Death of the earning member": "సంపాదించే సభ్యుని మరణం",
    "Missing payment details": "చెల్లింపు వివరాలు లేవు",
    "Dispute due to bad behaviour": "చెడు ప్రవర్తన కారణంగా వివాదం",
    "No clear loan information": "స్పష్టమైన రుణ సమాచారం లేదు",
    "Death Case": "డెత్ కేసు",
    "We kindly request you to upload the death certificate at":"Legal@privatecourt.inలో మరణ ధృవీకరణ పత్రాన్ని అప్‌లోడ్ చేయవలసిందిగా మేము మిమ్మల్ని కోరుతున్నాము",
    "Other reason/s": "ఇతర కారణం/లు",
    "Your prompt response will greatly assist us in assessing the situation and finding the most suitable resolution. Rest assured, we are committed to working with you to resolve any outstanding issues and ensure a fair and satisfactory outcome.":"మీ సత్వర ప్రతిస్పందన పరిస్థితిని అంచనా వేయడంలో మరియు అత్యంత అనుకూలమైన పరిష్కారాన్ని కనుగొనడంలో మాకు బాగా సహాయపడుతుంది. హామీ ఇవ్వండి, ఏవైనా అత్యుత్తమ సమస్యలను పరిష్కరించడానికి మరియు న్యాయమైన మరియు సంతృప్తికరమైన ఫలితాన్ని నిర్ధారించడానికి మీతో కలిసి పని చేయడానికి మేము కట్టుబడి ఉన్నాము.",
    "Partner Dispute": "భాగస్వామి వివాదం",
    "Funds held with other vendors or clients": "ఇతర విక్రేతలు లేదా క్లయింట్‌లతో ఉన్న నిధులు",
    "Monetary loss through other means": "ఇతర మార్గాల ద్వారా ద్రవ్య నష్టం",
    "Less than 2 months": "రెండు నెలల కంటే తక్కువ",
    "Less than 6 months": "ఆరు నెలల కన్నా తక్కువ",
    "Less than 1 year": "ఒక సంవత్సరం కంటే తక్కువ",
    "More than 1 year": "ఒక సంవత్సరం కంటే ఎక్కువ",
    "Exploring Settlement Possibilities": "సెటిల్మెంట్ అవకాశాలను అన్వేషించడం",
    "As our discussion progresses, I appreciate your openness in explaining the reason for the default. It's crucial to address your outstanding dues promptly to prevent any legal complexities. We recommend exploring different avenues, such as seeking assistance from family or investigating alternative financing options, to fulfill your obligations without delay.":"మా చర్చ సాగుతున్నప్పుడు, డిఫాల్ట్‌కు కారణాన్ని వివరించడంలో మీ బహిరంగతను నేను అభినందిస్తున్నాను. చట్టపరమైన సంక్లిష్టతలను నివారించడానికి మీ బకాయిలను వెంటనే పరిష్కరించడం చాలా ముఖ్యం. మీ బాధ్యతలను ఆలస్యం చేయకుండా నెరవేర్చడానికి, కుటుంబం నుండి సహాయం కోరడం లేదా ప్రత్యామ్నాయ ఫైనాన్సింగ్ ఎంపికలను పరిశోధించడం వంటి విభిన్న మార్గాలను అన్వేషించాలని మేము సిఫార్సు చేస్తున్నాము.",
    "Timely settlement not only mitigates legal risks but also strengthens trust and credibility in your financial interactions. Your cooperation in this matter is truly valued.":"సకాలంలో పరిష్కారం చట్టపరమైన నష్టాలను తగ్గించడమే కాకుండా మీ ఆర్థిక పరస్పర చర్యలపై నమ్మకం మరియు విశ్వసనీయతను బలపరుస్తుంది. ఈ విషయంలో మీ సహకారం నిజంగా విలువైనది.",
    "Settlement Options": "సెటిల్మెంట్ ఎంపికలు",
    "In our efforts to facilitate a swift resolution, we seek your cooperation.":"సత్వర పరిష్కారాన్ని సులభతరం చేసే మా ప్రయత్నాలలో, మేము మీ సహకారాన్ని కోరుతున్నాము.",
    "Are you open to discussing the settlement of your outstanding dues?": "మీ బకాయిల పరిష్కారం గురించి చర్చించడానికి మీరు సిద్ధంగా ఉన్నారా?",
    "Please Select": "దయచేసి ఎంచుకోండి",
    "Yes": "అవును",
    "No": "నం",
    "I am unable to pay by any means": "నేను ఏ విధంగానూ చెల్లించలేకపోతున్నాను",
    "We Appreciate Your Honesty.": "మేము మీ నిజాయితీని అభినందిస్తున్నాము.",
    "Aadhaar Verify": "ఆధార్ వెరిఫై",
    "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly, could you kindly provide details on your current family income?":"మీ బకాయిలను పరిష్కరించడంలో మీ బహిరంగతను మేము అభినందిస్తున్నాము. మీ ఆర్థిక పరిస్థితిని బాగా అర్థం చేసుకోవడానికి మరియు తదనుగుణంగా మా విధానాన్ని రూపొందించడానికి, మీరు దయచేసి మీ ప్రస్తుత కుటుంబ ఆదాయంపై వివరాలను అందించగలరా?",
    "No Income": "ఆదాయం లేదు",
    "less than Rs. 10000/- per month": "కంటే తక్కువ రూ. 10000/- నెలకు",
    "less than Rs. 20000/- per month": "కంటే తక్కువ రూ. 20000/- నెలకు",
    "less than Rs. 40000/- per month": "కంటే తక్కువ రూ. 40000/- నెలకు",
    "more than Rs. 100000/- per month": "కంటే ఎక్కువ రూ. 100000/- నెలకు",
    "In our efforts to address your outstanding dues, we'd like to know if a one-time payment settlement is an option for you.":"మీ బకాయిలను పరిష్కరించేందుకు మా ప్రయత్నాలలో, వన్-టైమ్ పేమెంట్ సెటిల్‌మెంట్ మీకు ఒక ఎంపికగా ఉందో లేదో తెలుసుకోవాలనుకుంటున్నాము.",
    "We appreciate your feedback. If a one-time payment is not viable, could you consider arranging payment in three equal instalments?":"మేము మీ అభిప్రాయాన్ని అభినందిస్తున్నాము. ఒక పర్యాయ చెల్లింపు ఆచరణీయం కానట్లయితే, మీరు మూడు సమాన వాయిదాలలో చెల్లింపును ఏర్పాటు చేయడాన్ని పరిగణించవచ్చా?",
    "This approach could help ease any immediate financial strain while still addressing the outstanding dues.":"ఈ విధానం బకాయిలను పరిష్కరించేటప్పుడు ఏదైనా తక్షణ ఆర్థిక ఒత్తిడిని తగ్గించడంలో సహాయపడుతుంది.",
    "Aadhaar Verification": "ఆధార్ ధృవీకరణ",
    "To proceed further, we have initiated the Aadhaar verification process. Please provide the OTP (One-Time Password) that you have received to complete this step. Ensuring the security and authenticity of our users is paramount to us, and this verification step helps us maintain the integrity of our services.":"మరింత కొనసాగడానికి, మేము ఆధార్ ధృవీకరణ ప్రక్రియను ప్రారంభించాము. దయచేసి ఈ దశను పూర్తి చేయడానికి మీరు అందుకున్న OTP (వన్-టైమ్ పాస్‌వర్డ్)ని అందించండి. మా వినియోగదారుల భద్రత మరియు ప్రామాణికతను నిర్ధారించడం మాకు చాలా ముఖ్యమైనది మరియు ఈ ధృవీకరణ దశ మా సేవల సమగ్రతను కాపాడుకోవడంలో మాకు సహాయపడుతుంది.",
    "Enter the Applicant’s Aadhaar Number": "దరఖాస్తుదారు ఆధార్ నంబర్‌ను నమోదు చేయండి",
    "Please input the Applicant’s Aadhaar number along with essential demographic details—name, date of birth, and gender. This process ensures accurate cross-checking for security. Thank you for your cooperation.":"దయచేసి అవసరమైన జనాభా వివరాలతో పాటుగా దరఖాస్తుదారు యొక్క ఆధార్ నంబర్‌ను ఇన్‌పుట్ చేయండి-పేరు, పుట్టిన తేదీ మరియు లింగం. ఈ ప్రక్రియ భద్రత కోసం ఖచ్చితమైన క్రాస్ చెకింగ్‌ను నిర్ధారిస్తుంది. మీ సహకారానికి ధన్యవాదాలు.",
    "For any queries or assistance, feel free to call us at +91-9699900111.": "ఏవైనా ప్రశ్నలు లేదా సహాయం కోసం, మాకు +91-9699900111కు కాల్ చేయడానికి సంకోచించకండి.",
    "Thank you for entrusting PrivateCourt. Your security is our priority.": "ప్రైవేట్‌కోర్టును అప్పగించినందుకు ధన్యవాదాలు. మీ భద్రత మా ప్రాధాన్యత.",
    "Enter the Aadhaar OTP Here": "ఇక్కడ ఆధార్ OTPని నమోదు చేయండి",
    "Verify OTP": "OTPని ధృవీకరించండి",
    "Thank You Aadhaar Verification Successfully": "ధన్యవాదాలు ఆధార్ ధృవీకరణ విజయవంతమైంది",
    "Thank you for providing the OTP for Aadhaar verification. With this step completed, we are now ready to proceed with your case.":"ఆధార్ ధృవీకరణ కోసం OTPని అందించినందుకు ధన్యవాదాలు. ఈ దశ పూర్తయినందున, మేము ఇప్పుడు మీ కేసును కొనసాగించడానికి సిద్ధంగా ఉన్నాము.",
    "Our team will continue to work diligently to bring about a resolution that meets your needs and expectations. Should you have any questions or concerns along the way, please don't hesitate to reach out to us.":"మీ అవసరాలు మరియు అంచనాలకు అనుగుణంగా తీర్మానాన్ని తీసుకురావడానికి మా బృందం శ్రద్ధగా పని చేస్తూనే ఉంటుంది. మార్గంలో మీకు ఏవైనా ప్రశ్నలు లేదా ఆందోళనలు ఉంటే, దయచేసి మమ్మల్ని సంప్రదించడానికి సంకోచించకండి.",
    "Once again, thank you for entrusting PrivateCourt with your dispute resolution needs. We look forward to serving you.":"మరోసారి, మీ వివాద పరిష్కార అవసరాలను ప్రైవేట్‌కోర్టుకు అప్పగించినందుకు ధన్యవాదాలు. మేము మీకు సేవ చేయడానికి ఎదురుచూస్తున్నాము.",
    "Warm regards,": "శుభాకాంక్షలు,",
    "PrivateCourt Team": "ప్రైవేట్ కోర్టు బృందం",


    //Dispute WebPage
   "Payment Date":"చెల్లింపు తేదీ",
  "Aadhaar Verification":"ఆధార్ వెరిఫికేషన్",
  "To proceed further, we have initiated the Aadhaar verification process. Please provide the OTP (One-Time Password) that you have received to complete this step. Ensuring the security and authenticity of our users is paramount to us, and this verification step helps us maintain the integrity of our services.":"మరింత ముందుకు వెళ్లడానికి, మేము ఆధార్ ధృవీకరణ ప్రక్రియను ప్రారంభించాము. ఈ దశను పూర్తి చేయడం కొరకు దయచేసి మీరు అందుకున్న వోటిపి (వన్-టైమ్ పాస్ వర్డ్) ఇవ్వండి. మా వినియోగదారుల భద్రత మరియు ప్రామాణికతను ధృవీకరించడం మాకు చాలా ముఖ్యమైనది, మరియు ఈ ధృవీకరణ దశ మా సేవల సమగ్రతను నిర్వహించడానికి మాకు సహాయపడుతుంది.",
  "Thank You Aadhaar Verification Successfully":"ఆధార్ వెరిఫికేషన్ విజయవంతంగా ముగిసిందని ధన్యవాదాలు",
  "Thank you for providing the OTP for Aadhaar verification. With this step completed, we are now ready to proceed with your case.":"ఆధార్ వెరిఫికేషన్ కోసం ఓటీపీ ఇచ్చినందుకు ధన్యవాదాలు. ఈ దశ పూర్తయిన తరువాత, మేము ఇప్పుడు మీ కేసుతో ముందుకు సాగడానికి సిద్ధంగా ఉన్నాము.",
  "Our team will continue to work diligently to bring about a resolution that meets your needs and expectations. Should you have any questions or concerns along the way, please don't hesitate to reach out to us.":"మీ అవసరాలు మరియు ఆకాంక్షలను తీర్చే ఒక తీర్మానాన్ని తీసుకురావడానికి మా బృందం శ్రద్ధతో పనిచేస్తూనే ఉంటుంది. మీకు ఏవైనా ప్రశ్నలు లేదా ఆందోళనలు ఉంటే, దయచేసి మమ్మల్ని సంప్రదించడానికి సంకోచించకండి.",
  "Once again, thank you for entrusting PrivateCourt with your dispute resolution needs. We look forward to serving you.":"మరోసారి, మీ వివాద పరిష్కార అవసరాలను ప్రైవేట్ కోర్ట్ కు అప్పగించినందుకు ధన్యవాదాలు. మీకు సేవ చేయడానికి మేము ఎదురు చూస్తున్నాము.",
  "Warm regards,":"",
  "PrivateCourt Team":"",
  'Oops!':"అయ్యో!",
  "The page you are searching, can't be found.":"మీరు వెతుకుతున్న పేజీ కనుగొనబడలేదు.",
  "Language Selection:": "భాష ఎంపిక:",
  "Empowering Effective Communication": "ఎఫెక్టివ్ కమ్యూనికేషన్ సాధికారత:",
  "Good day! I'm Murthy, your dedicated assistant for today's proceedings. Lets start by selecting the language you're most comfortable with.":"మంచి రోజు! నేను మూర్తిని, ఈరోజు ప్రొసీడింగ్స్‌కి మీ డెడికేటెడ్ అసిస్టెంట్. మీకు అత్యంత సౌకర్యవంతంగా ఉండే భాషను ఎంచుకోవడం ద్వారా ప్రారంభిద్దాం.",
  "Select Your Preferred Language": "మీరు ఇష్టపడే భాషను ఎంచుకోండి",
  "Opting for your preferred language ensures clear and effective communication. Whether it's your native tongue or another language of your choice, selecting it will streamline our interaction and enhance your experience.":"మీకు నచ్చిన భాషను ఎంచుకోవడం వలన స్పష్టమైన మరియు ప్రభావవంతమైన సంభాషణ జరుగుతుంది. అది మీ మాతృభాష అయినా లేదా మీకు నచ్చిన మరో భాష అయినా, దాన్ని ఎంచుకోవడం ద్వారా మా పరస్పర చర్యను క్రమబద్ధం చేస్తుంది మరియు మీ అనుభవాన్ని మెరుగుపరుస్తుంది.",
  "Next": "తరువాత",
  "Welcome to PrivateCourt, where Helping you is our priority.": "ప్రైవేట్‌కోర్టుకు స్వాగతం, ఇక్కడ మీకు సహాయం చేయడం మా ప్రాధాన్యత.",
  "Now, let me introduce you to PrivateCourt – your reliable partner in resolving lending disputes effectively and efficiently.":"ఇప్పుడు, నేను మిమ్మల్ని ప్రైవేట్‌కోర్ట్‌కి పరిచయం చేస్తున్నాను - రుణ వివాదాలను సమర్థవంతంగా మరియు సమర్ధవంతంగా పరిష్కరించడంలో మీ నమ్మకమైన భాగస్వామి.",
  "PrivateCourt":"ప్రైవేట్‌కోర్టు",
  "Your trusted partner in resolving lending disputes with care and expertise.":"రుణ వివాదాలను జాగ్రత్తగా మరియు నైపుణ్యంతో పరిష్కరించడంలో మీ విశ్వసనీయ భాగస్వామి.",
  "Welcome to PrivateCourt, where your case details are in good hands. With the lending company initiating arbitration or conciliation, it's essential for us to understand your situation fully. Please share all the details you can.":"మీ కేసు వివరాలు మంచి చేతుల్లో ఉన్న ప్రైవేట్‌కోర్టుకు స్వాగతం. రుణం ఇచ్చే కంపెనీ మధ్యవర్తిత్వం లేదా రాజీని ప్రారంభించడంతో, మీ పరిస్థితిని పూర్తిగా అర్థం చేసుకోవడం మాకు చాలా అవసరం. దయచేసి మీరు చేయగలిగిన అన్ని వివరాలను పంచుకోండి.",
  "The more information we have, the better we can help. Your input is crucial in finding the right solution for you.":"మన దగ్గర ఎంత ఎక్కువ సమాచారం ఉంటే అంత బాగా సహాయం చేయగలం. మీకు సరైన పరిష్కారాన్ని కనుగొనడంలో మీ ఇన్‌పుట్ కీలకం.",
  "Trust us to carefully examine your case and work together to find the best way forward.":"మీ కేసును జాగ్రత్తగా పరిశీలించడానికి మరియు ముందుకు సాగడానికి ఉత్తమమైన మార్గాన్ని కనుగొనడానికి కలిసి పని చేయడానికి మమ్మల్ని నమ్మండి.",
  "Reviewing Your Dispute Information": "మీ వివాద సమాచారాన్ని సమీక్షిస్తోంది",
  "Let's take a closer look at the details surrounding your dispute. The information we're discussing today has been shared with us by Company, regarding a personal loan.": "మీ వివాదానికి సంబంధించిన వివరాలను నిశితంగా పరిశీలిద్దాం. ఈ రోజు మనం చర్చిస్తున్న సమాచారం వ్యక్తిగత రుణానికి సంబంధించి Company ద్వారా మాతో పంచుకోబడింది.",
  "By understanding these specifics, we can better assess the situation and formulate a resolution plan that aligns with your circumstances and goals.":"ఈ ప్రత్యేకతలను అర్థం చేసుకోవడం ద్వారా, మేము పరిస్థితిని బాగా అంచనా వేయవచ్చు మరియు మీ పరిస్థితులు మరియు లక్ష్యాలకు అనుగుణంగా పరిష్కార ప్రణాళికను రూపొందించవచ్చు.",
  "Dispute Amount Information": "వివాద మొత్తం సమాచారం",
  "According to their records, you initially borrowed an amount of": "వారి రికార్డుల ప్రకారం, మీరు మొదట్లో కొంత మొత్తాన్ని అప్పుగా తీసుకున్నారు",
  "Presently, your outstanding due stands at Rs.": "ప్రస్తుతం, మీ బకాయి రూ.",
  "These details provide us with valuable insight into the history and current status of your loan.":"ఈ వివరాలు మీ లోన్ చరిత్ర మరియు ప్రస్తుత స్థితిపై మాకు విలువైన అంతర్దృష్టిని అందిస్తాయి.",
  "Spotting Financial Issues: Understanding Unpaid Obligations": "ఆర్థిక సమస్యలను గుర్తించడం: చెల్లించని బాధ్యతలను అర్థం చేసుకోవడం",
  "Now, let's gently explore the challenges you've faced due to financial constraints. Discussing this may be sensitive, but your openness will greatly assist us in finding the best path forward. Please take a moment to select any of the following options that resonate with your experience:":"ఇప్పుడు, ఆర్థిక పరిమితుల కారణంగా మీరు ఎదుర్కొన్న సవాళ్లను సున్నితంగా అన్వేషిద్దాం. దీని గురించి చర్చించడం చాలా సున్నితంగా ఉండవచ్చు, కానీ మీ నిష్కాపట్యత ఉత్తమమైన మార్గాన్ని కనుగొనడంలో మాకు బాగా సహాయపడుతుంది. దయచేసి మీ అనుభవంతో ప్రతిధ్వనించే క్రింది ఎంపికలలో దేనినైనా ఎంచుకోవడానికి కొంత సమయం కేటాయించండి:",
  "Financial Loss": "ఆర్థిక నష్టం",
  "Business Loss": "వ్యాపారంలో నష్టం",
  "Job Loss": "ఉద్యోగ నష్టం",
  "Health Issue": "ఆరోగ్య సమస్య",
  "Death of the earning member": "సంపాదించే సభ్యుని మరణం",
  "Missing payment details": "చెల్లింపు వివరాలు లేవు",
  "Dispute due to bad behaviour": "చెడు ప్రవర్తన కారణంగా వివాదం",
  "No clear loan information": "స్పష్టమైన రుణ సమాచారం లేదు",
  "Death Case": "డెత్ కేసు",
  "We kindly request you to upload the death certificate at Legal@privatecourt.in":"Legal@privatecourt.inలో మరణ ధృవీకరణ పత్రాన్ని అప్‌లోడ్ చేయవలసిందిగా మేము మిమ్మల్ని కోరుతున్నాము",
  "Other reason/s": "ఇతర కారణం/లు",
  "Your prompt response will greatly assist us in assessing the situation and finding the most suitable resolution. Rest assured, we are committed to working with you to resolve any outstanding issues and ensure a fair and satisfactory outcome.":"మీ సత్వర ప్రతిస్పందన పరిస్థితిని అంచనా వేయడంలో మరియు అత్యంత అనుకూలమైన పరిష్కారాన్ని కనుగొనడంలో మాకు బాగా సహాయపడుతుంది. హామీ ఇవ్వండి, ఏవైనా అత్యుత్తమ సమస్యలను పరిష్కరించడానికి మరియు న్యాయమైన మరియు సంతృప్తికరమైన ఫలితాన్ని నిర్ధారించడానికి మీతో కలిసి పని చేయడానికి మేము కట్టుబడి ఉన్నాము.",
  "Partner Dispute": "భాగస్వామి వివాదం",
  "Funds held with other vendors or clients": "ఇతర విక్రేతలు లేదా క్లయింట్‌లతో ఉన్న నిధులు",
  "Monetary loss through other means": "ఇతర మార్గాల ద్వారా ద్రవ్య నష్టం",
  "Less than 2 months": "రెండు నెలల కంటే తక్కువ",
  "Less than 6 months": "ఆరు నెలల కన్నా తక్కువ",
  "Less than 1 year": "ఒక సంవత్సరం కంటే తక్కువ",
  "More than 1 year": "ఒక సంవత్సరం కంటే ఎక్కువ",
  "Exploring Settlement Possibilities": "సెటిల్మెంట్ అవకాశాలను అన్వేషించడం",
  "As our discussion progresses, I appreciate your openness in explaining the reason for the default. It's crucial to address your outstanding dues promptly to prevent any legal complexities. We recommend exploring different avenues, such as seeking assistance from family or investigating alternative financing options, to fulfill your obligations without delay.":"మా చర్చ సాగుతున్నప్పుడు, డిఫాల్ట్‌కు కారణాన్ని వివరించడంలో మీ బహిరంగతను నేను అభినందిస్తున్నాను. చట్టపరమైన సంక్లిష్టతలను నివారించడానికి మీ బకాయిలను వెంటనే పరిష్కరించడం చాలా ముఖ్యం. మీ బాధ్యతలను ఆలస్యం చేయకుండా నెరవేర్చడానికి, కుటుంబం నుండి సహాయం కోరడం లేదా ప్రత్యామ్నాయ ఫైనాన్సింగ్ ఎంపికలను పరిశోధించడం వంటి విభిన్న మార్గాలను అన్వేషించాలని మేము సిఫార్సు చేస్తున్నాము.",
  "Timely settlement not only mitigates legal risks but also strengthens trust and credibility in your financial interactions. Your cooperation in this matter is truly valued.":"సకాలంలో పరిష్కారం చట్టపరమైన నష్టాలను తగ్గించడమే కాకుండా మీ ఆర్థిక పరస్పర చర్యలపై నమ్మకం మరియు విశ్వసనీయతను బలపరుస్తుంది. ఈ విషయంలో మీ సహకారం నిజంగా విలువైనది.",
  "Settlement Options": "సెటిల్మెంట్ ఎంపికలు",
  "In our efforts to facilitate a swift resolution, we seek your cooperation.":"సత్వర పరిష్కారాన్ని సులభతరం చేసే మా ప్రయత్నాలలో, మేము మీ సహకారాన్ని కోరుతున్నాము.",
  "Are you open to discussing the settlement of your outstanding dues?": "మీ బకాయిల పరిష్కారం గురించి చర్చించడానికి మీరు సిద్ధంగా ఉన్నారా?",
  "Please Select": "దయచేసి ఎంచుకోండి",
  "Yes": "అవును",
  "No": "నం",
  "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly,": "మీ సమస్యను పరిష్కరించడానికి మీ మొత్తం కుటుంబ ఆదాయాన్ని అర్థం చేసుకోవడానికి,",
  "could you kindly provide details on your current family income?": "దయచేసి మీ ప్రస్తుత కుటుంబ ఆదాయంపై వివరాలను అందించండి?",
  "In our efforts to address your outstanding dues, we'd like to know if a": "మీ బకాయిలో ఉన్న డ్యూస్ ని చేర్చడానికి మా ప్రయత్నాల్లో, మేము మీకు ఒక ",
  "one-time payment settlement is an option for you.": "ఒక సమయం చెల్లింపు సమాధానం అంచనా ఉందా?",
  "I am unable to pay by any means": "నేను ఏ విధంగానూ చెల్లించలేకపోతున్నాను",
  "We Appreciate Your Honesty.": "మేము మీ నిజాయితీని అభినందిస్తున్నాము.",
  "Aadhaar Verify": "ఆధార్ వెరిఫై",
  "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly, could you kindly provide details on your current family income?":"మీ బకాయిలను పరిష్కరించడంలో మీ బహిరంగతను మేము అభినందిస్తున్నాము. మీ ఆర్థిక పరిస్థితిని బాగా అర్థం చేసుకోవడానికి మరియు తదనుగుణంగా మా విధానాన్ని రూపొందించడానికి, మీరు దయచేసి మీ ప్రస్తుత కుటుంబ ఆదాయంపై వివరాలను అందించగలరా?",
  "No Income": "ఆదాయం లేదు",
  "less than Rs. 10000/- per month": "కంటే తక్కువ రూ. 10000/- నెలకు",
  "less than Rs. 20000/- per month": "కంటే తక్కువ రూ. 20000/- నెలకు",
  "less than Rs. 40000/- per month": "కంటే తక్కువ రూ. 40000/- నెలకు",
  "more than Rs. 100000/- per month": "కంటే ఎక్కువ రూ. 100000/- నెలకు",
  "In our efforts to address your outstanding dues, we'd like to know if a one-time payment settlement is an option for you.":"మీ బకాయిలను పరిష్కరించేందుకు మా ప్రయత్నాలలో, వన్-టైమ్ పేమెంట్ సెటిల్‌మెంట్ మీకు ఒక ఎంపికగా ఉందో లేదో తెలుసుకోవాలనుకుంటున్నాము.",
  "We appreciate your feedback. If a one-time payment is not viable, could you consider arranging payment in three equal instalments?":"మేము మీ అభిప్రాయాన్ని అభినందిస్తున్నాము. ఒక పర్యాయ చెల్లింపు ఆచరణీయం కానట్లయితే, మీరు మూడు సమాన వాయిదాలలో చెల్లింపును ఏర్పాటు చేయడాన్ని పరిగణించవచ్చా?",
  "This approach could help ease any immediate financial strain while still addressing the outstanding dues.":"ఈ విధానం బకాయిలను పరిష్కరించేటప్పుడు ఏదైనా తక్షణ ఆర్థిక ఒత్తిడిని తగ్గించడంలో సహాయపడుతుంది.",
  "Aadhaar Verification": "ఆధార్ ధృవీకరణ",
  "To proceed further, we have initiated the Aadhaar verification process. Please provide the OTP (One-Time Password) that you have received to complete this step. Ensuring the security and authenticity of our users is paramount to us, and this verification step helps us maintain the integrity of our services.":"మరింత కొనసాగడానికి, మేము ఆధార్ ధృవీకరణ ప్రక్రియను ప్రారంభించాము. దయచేసి ఈ దశను పూర్తి చేయడానికి మీరు అందుకున్న OTP (వన్-టైమ్ పాస్‌వర్డ్)ని అందించండి. మా వినియోగదారుల భద్రత మరియు ప్రామాణికతను నిర్ధారించడం మాకు చాలా ముఖ్యమైనది మరియు ఈ ధృవీకరణ దశ మా సేవల సమగ్రతను కాపాడుకోవడంలో మాకు సహాయపడుతుంది.",
  "Enter the Applicant’s Aadhaar Number": "దరఖాస్తుదారు ఆధార్ నంబర్‌ను నమోదు చేయండి",
  "Please input the Applicant’s Aadhaar number along with essential demographic details—name, date of birth, and gender. This process ensures accurate cross-checking for security. Thank you for your cooperation.":"దయచేసి అవసరమైన జనాభా వివరాలతో పాటుగా దరఖాస్తుదారు యొక్క ఆధార్ నంబర్‌ను ఇన్‌పుట్ చేయండి-పేరు, పుట్టిన తేదీ మరియు లింగం. ఈ ప్రక్రియ భద్రత కోసం ఖచ్చితమైన క్రాస్ చెకింగ్‌ను నిర్ధారిస్తుంది. మీ సహకారానికి ధన్యవాదాలు.",
  "For any queries or assistance, feel free to call us at +91-9699900111.": "ఏవైనా ప్రశ్నలు లేదా సహాయం కోసం, మాకు +91-9699900111కు కాల్ చేయడానికి సంకోచించకండి.",
  "Thank you for entrusting PrivateCourt. Your security is our priority.": "ప్రైవేట్‌కోర్టును అప్పగించినందుకు ధన్యవాదాలు. మీ భద్రత మా ప్రాధాన్యత.",
  "Enter the Aadhaar OTP Here": "ఇక్కడ ఆధార్ OTPని నమోదు చేయండి",
  "Verify OTP": "OTPని ధృవీకరించండి",
  "Verify":"ధృవీకరించండి",
  "Thank You Aadhaar Verification Successfully": "ధన్యవాదాలు ఆధార్ ధృవీకరణ విజయవంతమైంది",
  "Thank you for providing the OTP for Aadhaar verification. With this step completed, we are now ready to proceed with your case.":"ఆధార్ ధృవీకరణ కోసం OTPని అందించినందుకు ధన్యవాదాలు. ఈ దశ పూర్తయినందున, మేము ఇప్పుడు మీ కేసును కొనసాగించడానికి సిద్ధంగా ఉన్నాము.",
  "Our team will continue to work diligently to bring about a resolution that meets your needs and expectations. Should you have any questions or concerns along the way, please don't hesitate to reach out to us.":"మీ అవసరాలు మరియు అంచనాలకు అనుగుణంగా తీర్మానాన్ని తీసుకురావడానికి మా బృందం శ్రద్ధగా పని చేస్తూనే ఉంటుంది. మార్గంలో మీకు ఏవైనా ప్రశ్నలు లేదా ఆందోళనలు ఉంటే, దయచేసి మమ్మల్ని సంప్రదించడానికి సంకోచించకండి.",
  "Once again, thank you for entrusting PrivateCourt with your dispute resolution needs. We look forward to serving you.":"మరోసారి, మీ వివాద పరిష్కార అవసరాలను ప్రైవేట్‌కోర్టుకు అప్పగించినందుకు ధన్యవాదాలు. మేము మీకు సేవ చేయడానికి ఎదురుచూస్తున్నాము.",
  "Warm regards,": "శుభాకాంక్షలు,",
  "PrivateCourt Team": "ప్రైవేట్ కోర్టు బృందం",
  };


// const TeluguTranslation=
// {
//     'Email Security Check':'ఇమెయిల్ భద్రతా తనిఖీ',
//     'Verify Your Email for Account Access': 'ఖాతా యాక్సెస్ కోసం మీ ఇమెయిల్‌ను ధృవీకరించండి',
//     "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system": "మీ ఖాతా భద్రతను నిర్ధారించడానికి, దయచేసి మీ చెల్లుబాటు అయ్యే ఇమెయిల్ IDని పేర్కొన్న ఫార్మాట్‌లో నమోదు చేయండి- username@domain.com. మీ ఇమెయిల్ చిరునామా మా సిస్టమ్‌లో లేకుంటే,",
//     'NEW USER': 'కొత్త వినియోగదారు',
//     'account will be created for you': 'మీ కోసం ఖాతా సృష్టించబడుతుంది.',
//     'Once submitted, you will receive a Verification OTP (One-Time Password) sent to your provided email address.': 'సమర్పించిన తర్వాత, మీరు అందించిన ఇమెయిల్ చిరునామాకు పంపబడిన ధృవీకరణ OTP (వన్-టైమ్ పాస్‌వర్డ్)ని మీరు అందుకుంటారు.',
//     'For any queries or assistance, feel free to call us at +91-9699900111.': 'ఏవైనా ప్రశ్నలు లేదా సహాయం కోసం, మాకు +91-9699900111కు కాల్ చేయడానికి సంకోచించకండి.',
//     'Thank you for entrusting PrivateCourt. Your security is our priority.': 'ప్రైవేట్‌కోర్టును అప్పగించినందుకు ధన్యవాదాలు. మీ భద్రత మా ప్రాధాన్యత.',
//     'Verify': 'ధృవీకరించండి',
//     'Invalid Email Address': 'చెల్లని ఇమెయిల్ చిరునామా',
//     'We regret to inform you that the email address entered is invalid. Please ensure that you have entered a correct and properly formatted email address.': 'నమోదు చేసిన ఇమెయిల్ చిరునామా చెల్లదని మీకు తెలియజేయడానికి మేము చింతిస్తున్నాము. దయచేసి మీరు సరైన మరియు సరిగ్గా ఫార్మాట్ చేయబడిన ఇమెయిల్ చిరునామాను నమోదు చేశారని నిర్ధారించుకోండి.',
//     'If the issue persists, double-check for any typos or errors in the email format (e.g., username@example.com).': 'సమస్య కొనసాగితే, ఇమెయిల్ ఫార్మాట్‌లో (ఉదా., username@example.com) ఏవైనా అక్షరదోషాలు లేదా లోపాల కోసం ఒకటికి రెండుసార్లు తనిఖీ చేయండి.',
//     'For further queries or assistance, feel free to call us at +91-9699900111.': 'తదుపరి ప్రశ్నలు లేదా సహాయం కోసం, మాకు +91-9699900111 వద్ద కాల్ చేయడానికి సంకోచించకండి.',  
//     'Thank you for entrusting PrivateCourt. Your security is our priority.': 'ప్రైవేట్‌కోర్టును అప్పగించినందుకు ధన్యవాదాలు. మీ భద్రత మా ప్రాధాన్యత.',
//     'Secure UDYAM Number Validation':'సురక్షిత ఉద్యమం నంబర్ ధ్రువీకరణ',
//     'Enter Your Udyam Number':'మీ ఉద్యమం నంబర్‌ని నమోదు చేయండి',
//     'Please enter your correct UDYAM Number.':'దయచేసి మీ సరైన ఉద్యమం నంబర్‌ని నమోదు చేయండి.',
//     'Your UDYAM Number allows us to collect crucial details associated with your business. This includes your business address, current status, service details, and organization type.':'మీ ఉద్యమం నంబర్ మీ వ్యాపారంతో అనుబంధించబడిన కీలకమైన వివరాలను సేకరించడానికి మమ్మల్ని అనుమతిస్తుంది. ఇందులో మీ వ్యాపార చిరునామా, ప్రస్తుత స్థితి, సేవా వివరాలు మరియు సంస్థ రకం ఉంటాయి.',
//     'Ensuring the accuracy of your UDYAM Number is vital for a seamless verification process.':'అతుకులు లేని ధృవీకరణ ప్రక్రియ కోసం మీ ಉದ್ಯಮ నంబర్ యొక్క ఖచ్చితత్వాన్ని నిర్ధారించడం చాలా ముఖ్యం.',
//     'UDYAM':'ఉద్యమం',
//     'Error: Invalid UDYAM Number':'లోపం: ఉద్యమం నంబర్ చెల్లదు',
//     'We regret to inform you that the UDYAM number entered is invalid or incorrect. Please ensure you have entered the correct UDYAM details and double-check for any typos.':'నమోదు చేసిన UDYAM నంబర్ చెల్లదని లేదా తప్పు అని మీకు తెలియజేయడానికి మేము చింతిస్తున్నాము. దయచేసి మీరు సరైన UDYAM వివరాలను నమోదు చేసినట్లు నిర్ధారించుకోండి మరియు ఏవైనా అక్షరదోషాలు ఉన్నాయో లేదో ఒకటికి రెండుసార్లు తనిఖీ చేయండి.',
//     'If you continue to face issues, kindly verify your UDYAM certificate and re-enter the details.':'మీరు సమస్యలను ఎదుర్కొంటూనే ఉంటే, దయచేసి మీ UDYAM ప్రమాణపత్రాన్ని ధృవీకరించండి మరియు వివరాలను మళ్లీ నమోదు చేయండి.',
//     'Secure PAN Validation Process':'సురక్షిత పాన్ ధ్రువీకరణ ప్రక్రియ',
//     'This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.':'ఈ ధృవీకరణ ప్రక్రియ విశ్వసనీయమైన మరియు సురక్షితమైన అనుభవాన్ని అందించడం కోసం మా సేవల సమగ్రతను కాపాడేందుకు రూపొందించబడింది.',
//     'Enter Your PAN Number':'మీ పాన్ నంబర్‌ను నమోదు చేయండి',
//     'Please enter your correct PAN number in the format XXXXX-0000-X.':'దయచేసి మీ సరైన PAN నంబర్‌ని XXXXX-0000-X ఫార్మాట్‌లో నమోదు చేయండి.',
//     'PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.':'ప్రైవేట్‌కోర్టు మీ పూర్తి పేరు, పుట్టిన తేదీ, నివాస చిరునామా మరియు సంప్రదింపు వివరాలు వంటి మీ పాన్‌తో అనుబంధించబడిన ముఖ్యమైన సమాచారాన్ని నిశితంగా సేకరిస్తుంది. వ్యాపార యజమానుల కోసం, GST వివరాలు, MCA ప్రత్యేకతలు మరియు GST రిటర్న్ ఫైలింగ్‌ల రికార్డులు వంటి మీ PANతో ముడిపడి ఉన్న అన్ని సంబంధిత వ్యాపార వివరాలను పొందుపరచడానికి మేము మా డేటా సేకరణను విస్తరింపజేస్తాము.',
//     'Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.':'ఆర్థిక అంతర్దృష్టి కోసం మా సిస్టమ్ మీ CIBIL స్కోర్‌ను అంచనా వేస్తుంది మరియు మీ PANతో అనుబంధించబడిన ఏవైనా కోర్టు కేసుల కోసం క్రాస్ వెరిఫై చేస్తుంది.',
//     'Error: Invalid PAN Format':'లోపం: చెల్లని PAN ఫార్మాట్',
//     'We regret to inform you that the PAN number entered does not match the required format (XXXXX-0000-X). Please ensure you have entered the correct PAN details and follow the specified format for accurate verification.':'నమోదు చేసిన PAN నంబర్ అవసరమైన ఫార్మాట్ (XXXXX-0000-X)తో సరిపోలడం లేదని మీకు తెలియజేయడానికి చింతిస్తున్నాము. దయచేసి మీరు సరైన PAN వివరాలను నమోదు చేశారని నిర్ధారించుకోండి మరియు ఖచ్చితమైన ధృవీకరణ కోసం పేర్కొన్న ఆకృతిని అనుసరించండి.',
//     'If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111.':'మీరు సమస్యలను ఎదుర్కొంటూనే ఉంటే, దయచేసి మీ పాన్ కార్డ్‌ని ఒకటికి రెండుసార్లు తనిఖీ చేసి, వివరాలను మళ్లీ నమోదు చేయండి. తదుపరి ప్రశ్నలు లేదా సహాయం కోసం, మాకు +91-9699900111 వద్ద కాల్ చేయడానికి సంకోచించకండి.',
//     'Welcome XXXX':'స్వాగతం XXXX',
//     'Account security starts with verification':'ఖాతా భద్రత ధృవీకరణతో ప్రారంభమవుతుంది',
//     'OTP Validation for Account Access':'ఖాతా యాక్సెస్ కోసం OTP ధ్రువీకరణ',
//     'Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.':'ధృవీకరణ ప్రక్రియను పూర్తి చేయడానికి దయచేసి మీ ఇన్‌బాక్స్‌ని తనిఖీ చేయండి మరియు నిర్దేశించిన ఫీల్డ్‌లో OTPని నమోదు చేయండి.',
//     "Didn't receive OTP?":'OTP అందలేదా? ',
//     'Request a Resend':'మళ్లీ పంపమని అభ్యర్థించండి',
//     'Verify OTP':'OTPని ధృవీకరించండి',
//     'Error: Incorrect OTP':'లోపం: సరికాని OTP',
//     'We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.':'అసౌకర్యానికి మేము చింతిస్తున్నాము. నమోదు చేసిన వన్-టైమ్ పాస్‌వర్డ్ (OTP) తప్పు. దయచేసి మీరు మీ ఇమెయిల్‌లో అందుకున్న OTPని ఖచ్చితంగా నమోదు చేశారని నిర్ధారించుకోండి.',
//     'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.':'సమస్య కొనసాగితే, మీరు మళ్లీ పంపే లింక్‌పై క్లిక్ చేయడం ద్వారా లేదా మా మద్దతు బృందాన్ని +91-9699900111లో సంప్రదించడం ద్వారా కొత్త OTPని అభ్యర్థించవచ్చు.',
//     'Double-check the email and try entering the OTP again.':'ఇమెయిల్‌ను ఒకటికి రెండుసార్లు తనిఖీ చేసి, మళ్లీ OTPని నమోదు చేయడానికి ప్రయత్నించండి.',
//     'Choose Profile':'ప్రొఫైల్‌ని ఎంచుకోండి',
//     'Setting Your Default Profile':'మీ డిఫాల్ట్ ప్రొఫైల్‌ను సెట్ చేస్తోంది',
//     'Please choose the default profile for a seamless experience.':'అతుకులు లేని అనుభవం కోసం దయచేసి డిఫాల్ట్ ప్రొఫైల్‌ను ఎంచుకోండి',
//     "If you manage multiple companies, select the profile you'd like to set as the default. This ensures that every time you log in, you'll have quick access to the information relevant to your preferred company.":'మీరు బహుళ కంపెనీలను నిర్వహిస్తుంటే, మీరు డిఫాల్ట్‌గా సెట్ చేయాలనుకుంటున్న ప్రొఫైల్‌ను ఎంచుకోండి. మీరు లాగిన్ చేసిన ప్రతిసారీ, మీరు ఇష్టపడే కంపెనీకి సంబంధించిన సమాచారాన్ని త్వరితగతిన యాక్సెస్ చేయగలరని ఇది నిర్ధారిస్తుంది.',
//     'Making the right selection now simplifies your future logins and provides a tailored experience based on your primary business entity.':'ఇప్పుడు సరైన ఎంపిక చేయడం వలన మీ భవిష్యత్ లాగిన్‌లను సులభతరం చేస్తుంది మరియు మీ ప్రాథమిక వ్యాపార సంస్థ ఆధారంగా అనుకూలమైన అనుభవాన్ని అందిస్తుంది.',
//     'Go to Dashboard':'డాష్బోర్డ్ కు వెళ్ళండి',
//     'Data Compilation in Progress':'డేటా కంపైలేషన్ ప్రోగ్రెస్‌లో ఉంది:',
//     'Please Allow Processing Time':'దయచేసి ప్రాసెసింగ్ సమయాన్ని అనుమతించండి',
//     "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.":'మీ సమాచారాన్ని అందించినందుకు ధన్యవాదాలు. మా సిస్టమ్ ప్రస్తుతం మీ సమగ్ర ప్రొఫైల్‌ను ఏర్పాటు చేయడానికి PAN, ఆధార్, GST, ఇమెయిల్ మరియు మరిన్ని వివరాలతో సహా మీరు నమోదు చేసిన డేటాను విశ్లేషించి, కంపైల్ చేసే ప్రక్రియలో ఉంది.',
//     "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.":'ఈ ఆపరేషన్‌కు కొన్ని క్షణాలు పట్టవచ్చు. ఈ సమయంలో, మీ స్క్రీన్ కనీస కార్యాచరణను చూపవచ్చు. దయచేసి ఇది మా డేటా కంపైలేషన్ ప్రాసెస్‌లో ఒక సాధారణ భాగమని హామీ ఇవ్వండి.',
//     'Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.':'మీ ప్రొఫైల్ ఖచ్చితత్వం మరియు భద్రతను కలిగి ఉండేలా సూక్ష్మంగా రూపొందించబడింది. మీ అనుభవాన్ని మెరుగుపరచడానికి మేము పని చేస్తున్నప్పుడు మీ సహనాన్ని మరియు అవగాహనను మేము హృదయపూర్వకంగా అభినందిస్తున్నాము.',
//     'Start Your Bank Account Verification':'మీ బ్యాంక్ ఖాతా ధృవీకరణను ప్రారంభించండి',
//     'Bank Verification':'బ్యాంక్ ధృవీకరణ',
//     'Ensuring the accuracy of your bank account information is crucial for a seamless verification process. Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.':'అతుకులు లేని ధృవీకరణ ప్రక్రియ కోసం మీ బ్యాంక్ ఖాతా సమాచారం యొక్క ఖచ్చితత్వాన్ని నిర్ధారించడం చాలా ముఖ్యం. దయచేసి మీ ఖాతా నంబర్‌ను నమోదు చేయండి మరియు మీ ఖాతాతో అనుబంధించబడిన సరైన IFSC కోడ్‌ను ఇన్‌పుట్ చేయండి.',
//     'This verification step is designed to enhance the security of our services and provide you with a reliable experience.':'ఈ ధృవీకరణ దశ మా సేవల భద్రతను మెరుగుపరచడానికి మరియు మీకు నమ్మకమైన అనుభవాన్ని అందించడానికి రూపొందించబడింది.',
//     'Account Number':'',
//     'IFSC code':'',
//     'Error: Invalid Bank Account or IFSC Code':'లోపం: చెల్లని బ్యాంక్ ఖాతా లేదా IFSC కోడ్',
//     'We regret to inform you that the Bank Account or IFSC Code entered is invalid or incorrect. Please ensure you have accurately entered both details and double-check for any typos.':'నమోదు చేసిన బ్యాంక్ ఖాతా లేదా IFSC కోడ్ చెల్లదని లేదా తప్పు అని మీకు తెలియజేయడానికి చింతిస్తున్నాము. దయచేసి మీరు రెండు వివరాలను ఖచ్చితంగా నమోదు చేసినట్లు నిర్ధారించుకోండి మరియు ఏవైనా అక్షరదోషాల కోసం ఒకటికి రెండుసార్లు తనిఖీ చేయండి.',
//     'If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.':'మీరు సమస్యలను ఎదుర్కొంటూనే ఉంటే, దయచేసి మీ బ్యాంక్ స్టేట్‌మెంట్‌ను ధృవీకరించండి లేదా అందించిన సమాచారం యొక్క ఖచ్చితత్వాన్ని నిర్ధారించడానికి మీ బ్యాంక్‌ని సంప్రదించండి.',
//     'Aadhaar Details Verification':'ఆధార్ వివరాల ధృవీకరణ',
//     'Enter Your Aadhaar Number':'మీ ఆధార్ నంబర్‌ను నమోదు చేయండి',
//     'Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.':'దయచేసి మీ ఆధార్ నంబర్‌ను ఇన్‌పుట్ చేయండి. ఈ ప్రక్రియ మీ భద్రత కోసం ఖచ్చితమైన క్రాస్-చెకింగ్‌ను నిర్ధారిస్తుంది. మీ సహకారానికి ధన్యవాదాలు.',
//     'Enter Your Aadhaar OTP Here':'మీ ఆధార్ OTPని ఇక్కడ నమోదు చేయండి',
//     'Error: Incorrect Aadhaar OTP':'లోపం: ఆధార్ OTP తప్పు',
//     'We regret to inform you that the One-Time Password (OTP) entered for Aadhaar verification is incorrect. Please ensure that you have accurately entered the OTP received on your registered mobile number.':'ఆధార్ ధృవీకరణ కోసం నమోదు చేసిన వన్-టైమ్ పాస్‌వర్డ్ (OTP) తప్పు అని మీకు తెలియజేయడానికి మేము చింతిస్తున్నాము. దయచేసి మీరు మీ రిజిస్టర్డ్ మొబైల్ నంబర్‌లో అందుకున్న OTPని ఖచ్చితంగా నమోదు చేశారని నిర్ధారించుకోండి.',
//     'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.':'సమస్య కొనసాగితే, మీరు మళ్లీ పంపే లింక్‌పై క్లిక్ చేయడం ద్వారా లేదా మా మద్దతు బృందాన్ని +91-9699900111లో సంప్రదించడం ద్వారా కొత్త OTPని అభ్యర్థించవచ్చు.',
//     'Double-check the email and try entering the OTP again.':'ఇమెయిల్‌ను ఒకటికి రెండుసార్లు తనిఖీ చేసి, మళ్లీ OTPని నమోదు చేయడానికి ప్రయత్నించండి.',
//     'Error: Invalid Aadhaar Number':'లోపం: చెల్లని ఆధార్ నంబర్',
//     'We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the correct 12-digit Aadhaar number.':'అసౌకర్యానికి మేము చింతిస్తున్నాము. నమోదు చేసిన ఆధార్ నంబర్ చెల్లదు. దయచేసి మీరు సరైన 12-అంకెల ఆధార్ నంబర్‌ను నమోదు చేసినట్లు ఒకటికి రెండుసార్లు తనిఖీ చేసి, నిర్ధారించుకోండి.',
//     'If you continue to face issues, verify your Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.':'మీరు సమస్యలను ఎదుర్కొంటూనే ఉంటే, మీ ఆధార్ కార్డ్‌ని ధృవీకరించండి లేదా తదుపరి సహాయం కోసం +91-9699900111లో మా మద్దతు బృందాన్ని సంప్రదించండి. మీ సహకారాన్ని మేము అభినందిస్తున్నాము.',
//     'Language Selection:':'భాష ఎంపిక:',
//     'Empowering Effective Communication':'ఎఫెక్టివ్ కమ్యూనికేషన్‌ను సాధికారపరచడం',
//     "Good day! I'm Murthy, your dedicated assistant for today's proceedings. Lets start by selecting the language you're most comfortable with.":'మంచి రోజు! నేను మూర్తిని, ఈరోజు ప్రొసీడింగ్స్‌కి మీ డెడికేటెడ్ అసిస్టెంట్. మీకు అత్యంత సౌకర్యవంతంగా ఉండే భాషను ఎంచుకోవడం ద్వారా ప్రారంభిద్దాం.',
//     "Opting for your preferred language ensures clear and effective communication. Whether it's your native tongue or another language of your choice, selecting it will streamline our interaction and enhance your experience.":"మీకు నచ్చిన భాషను ఎంచుకోవడం వలన స్పష్టమైన మరియు సమర్థవంతమైన కమ్యూనికేషన్‌ను నిర్ధారిస్తుంది. అది మీ మాతృభాష అయినా లేదా మీకు నచ్చిన మరో భాష అయినా, దానిని ఎంచుకోవడం ద్వారా మా పరస్పర చర్యను క్రమబద్ధం చేస్తుంది మరియు మీ అనుభవాన్ని మెరుగుపరుస్తుంది.",
//     "Select Your Preferred Language":"మీరు ఇష్టపడే భాషను ఎంచుకోండి",
//     "Welcome to PrivateCourt, where Helping you is our priority.":"ప్రైవేట్‌కోర్టుకు స్వాగతం, ఇక్కడ మీకు సహాయం చేయడం మా ప్రాధాన్యత.",
//     "Now, let me introduce you to PrivateCourt – your reliable partner in resolving lending disputes effectively and efficiently.":'ఇప్పుడు, రుణ వివాదాలను సమర్థవంతంగా మరియు సమర్ధవంతంగా పరిష్కరించడంలో మీ విశ్వసనీయ భాగస్వామి అయిన ప్రైవేట్‌కోర్ట్‌కు మిమ్మల్ని పరిచయం చేస్తున్నాను.',
//     "PrivateCourt: Your trusted partner in resolving lending disputes with care and expertise.":"ప్రైవేట్‌కోర్టు: రుణ వివాదాలను జాగ్రత్తగా మరియు నైపుణ్యంతో పరిష్కరించడంలో మీ విశ్వసనీయ భాగస్వామి.",
//     "Welcome to PrivateCourt, where your case details are in good hands. With the lending company initiating arbitration or conciliation, it's essential for us to understand your situation fully. Please share all the details you can.":"మీ కేసు వివరాలు మంచి చేతుల్లో ఉన్న ప్రైవేట్‌కోర్టుకు స్వాగతం. రుణం ఇచ్చే కంపెనీ మధ్యవర్తిత్వం లేదా రాజీని ప్రారంభించడంతో, మీ పరిస్థితిని పూర్తిగా అర్థం చేసుకోవడం మాకు చాలా అవసరం. దయచేసి మీరు చేయగలిగిన అన్ని వివరాలను పంచుకోండి.",
//     "The more information we have, the better we can help. Your input is crucial in finding the right solution for you.":"మన దగ్గర ఎంత ఎక్కువ సమాచారం ఉంటే అంత బాగా సహాయం చేయగలం. మీకు సరైన పరిష్కారాన్ని కనుగొనడంలో మీ ఇన్‌పుట్ కీలకం.",
//     "Trust us to carefully examine your case and work together to find the best way forward.":"మీ కేసును జాగ్రత్తగా పరిశీలించడానికి మరియు ముందుకు సాగడానికి ఉత్తమమైన మార్గాన్ని కనుగొనడానికి కలిసి పని చేయడానికి మమ్మల్ని నమ్మండి.",
//     "Reviewing Your Dispute Information":'మీ వివాద సమాచారాన్ని సమీక్షిస్తోంది',
//     "Let's take a closer look at the details surrounding your dispute. The information we're discussing today has been shared with us by LendingKart, regarding a personal loan.":"మీ వివాదానికి సంబంధించిన వివరాలను నిశితంగా పరిశీలిద్దాం. ఈ రోజు మనం చర్చిస్తున్న సమాచారం వ్యక్తిగత రుణానికి సంబంధించి LendingKart ద్వారా మాతో పంచుకోబడింది.",
//     "By understanding these specifics, we can better assess the situation and formulate a resolution plan that aligns with your circumstances and goals.":"ఈ ప్రత్యేకతలను అర్థం చేసుకోవడం ద్వారా, మేము పరిస్థితిని బాగా అంచనా వేయగలము మరియు మీ పరిస్థితులు మరియు లక్ష్యాలకు అనుగుణంగా పరిష్కార ప్రణాళికను రూపొందించగలము.",
//     "Dispute Amount Information":"వివాద మొత్తం సమాచారం",
//     "According to their records, you initially borrowed an amount of":"వారి రికార్డుల ప్రకారం, మీరు మొదట్లో కొంత మొత్తాన్ని రుణంగా తీసుకున్నారు ",
//     "Presently, your outstanding due stands at Rs.":"ప్రస్తుతం, మీ బకాయి రూ. ",
//     "These details provide us with valuable insight into the history and current status of your loan.":"ఈ వివరాలు మీ లోన్ చరిత్ర మరియు ప్రస్తుత స్థితిపై మాకు విలువైన అంతర్దృష్టిని అందిస్తాయి.",
//     "Spotting Financial Issues: Understanding Unpaid Obligations":"ఆర్థిక సమస్యలను గుర్తించడం: చెల్లించని బాధ్యతలను అర్థం చేసుకోవడం",
//     "Now, let's gently explore the challenges you've faced due to financial constraints. Discussing this may be sensitive, but your openness will greatly assist us in finding the best path forward. Please take a moment to select any of the following options that resonate with your experience:":"ఇప్పుడు, ఆర్థిక పరిమితుల కారణంగా మీరు ఎదుర్కొన్న సవాళ్లను సున్నితంగా అన్వేషిద్దాం. దీని గురించి చర్చించడం చాలా సున్నితంగా ఉండవచ్చు, కానీ మీ నిష్కాపట్యత ఉత్తమమైన మార్గాన్ని కనుగొనడంలో మాకు బాగా సహాయపడుతుంది. దయచేసి మీ అనుభవంతో ప్రతిధ్వనించే క్రింది ఎంపికలలో దేనినైనా ఎంచుకోవడానికి కొంత సమయం కేటాయించండి:",
//     "Financial Loss":"ఆర్థిక నష్టం",
//     "Business Loss":"వ్యాపారంలో నష్టం",
//     "Partner Dispute":"భాగస్వామి వివాదం",
//     "Funds held with other vendors or clients":"ఇతర విక్రేతలు లేదా క్లయింట్‌లతో ఉన్న నిధులు",
//     "Monetary loss through other means":"ఇతర మార్గాల ద్వారా ద్రవ్య నష్టం",
//     "Job Loss":"ఉద్యోగ నష్టం",
//     "Less than 2 months":"2 నెలల కంటే తక్కువ",
//     "Less than 6 months":"6 నెలల కన్నా తక్కువ",
//     "Less than 1 year":"1 సంవత్సరం కంటే తక్కువ",
//     "More than 1 year" :"1 సంవత్సరం కంటే ఎక్కువ",
//     "Health Issue":"ఆరోగ్య సమస్య",
//     "Death of the earning member":"సంపాదించే సభ్యుని మరణం",
//     "Missing payment details":"చెల్లింపు వివరాలు లేవు",
//     "Dispute due to bad behaviour":"చెడు ప్రవర్తన కారణంగా వివాదం",
//     "No clear loan information":"స్పష్టమైన రుణ సమాచారం లేదు",
//     "Death Case":"డెత్ కేసు",
//     "We kindly request you to upload the death certificate at":"లో మరణ ధృవీకరణ పత్రాన్ని అప్‌లోడ్ చేయవలసిందిగా మేము మిమ్మల్ని కోరుతున్నాము",
//     "Other reason/s":"ఇతర కారణం/లు",
//     "Your prompt response will greatly assist us in assessing the situation and finding the most suitable resolution. Rest assured, we are committed to working with you to resolve any outstanding issues and ensure a fair and satisfactory outcome.":"మీ సత్వర ప్రతిస్పందన పరిస్థితిని అంచనా వేయడంలో మరియు అత్యంత అనుకూలమైన పరిష్కారాన్ని కనుగొనడంలో మాకు బాగా సహాయపడుతుంది. హామీ ఇవ్వండి, ఏవైనా అత్యుత్తమ సమస్యలను పరిష్కరించడానికి మరియు న్యాయమైన మరియు సంతృప్తికరమైన ఫలితాన్ని నిర్ధారించడానికి మీతో కలిసి పని చేయడానికి మేము కట్టుబడి ఉన్నాము.",
//     "Exploring Settlement Possibilities":"సెటిల్మెంట్ అవకాశాలను అన్వేషించడం",
//     "As our discussion progresses, I appreciate your openness in explaining the reason for the default. It's crucial to address your outstanding dues promptly to prevent any legal complexities. We recommend exploring different avenues, such as seeking assistance from family or investigating alternative financing options, to fulfill your obligations without delay.":"మా చర్చ సాగుతున్నప్పుడు, డిఫాల్ట్‌కు కారణాన్ని వివరించడంలో మీ బహిరంగతను నేను అభినందిస్తున్నాను. చట్టపరమైన సంక్లిష్టతలను నివారించడానికి మీ బకాయిలను వెంటనే పరిష్కరించడం చాలా ముఖ్యం. మీ బాధ్యతలను ఆలస్యం చేయకుండా నెరవేర్చడానికి, కుటుంబం నుండి సహాయం కోరడం లేదా ప్రత్యామ్నాయ ఫైనాన్సింగ్ ఎంపికలను పరిశోధించడం వంటి విభిన్న మార్గాలను అన్వేషించాలని మేము సిఫార్సు చేస్తున్నాము.",
//     "Timely settlement not only mitigates legal risks but also strengthens trust and credibility in your financial interactions. Your cooperation in this matter is truly valued.":"సకాలంలో పరిష్కారం చట్టపరమైన నష్టాలను తగ్గించడమే కాకుండా మీ ఆర్థిక పరస్పర చర్యలపై నమ్మకం మరియు విశ్వసనీయతను బలపరుస్తుంది. ఈ విషయంలో మీ సహకారం నిజంగా విలువైనది.",
//     "Settlement Options":"సెటిల్మెంట్ ఎంపికలు",
//     "In our efforts to facilitate a swift resolution, we seek your cooperation.":"సత్వర పరిష్కారాన్ని సులభతరం చేసే మా ప్రయత్నాలలో, మేము మీ సహకారాన్ని కోరుతున్నాము.",
//     "Are you open to discussing the settlement of your outstanding dues?":"మీ బకాయిల పరిష్కారం గురించి చర్చించడానికి మీరు సిద్ధంగా ఉన్నారా?",
//     "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly, could you kindly provide details on your current family income?":"మీ బకాయిలను పరిష్కరించడంలో మీ బహిరంగతను మేము అభినందిస్తున్నాము. మీ ఆర్థిక పరిస్థితిని బాగా అర్థం చేసుకోవడానికి మరియు తదనుగుణంగా మా విధానాన్ని రూపొందించడానికి, మీరు దయచేసి మీ ప్రస్తుత కుటుంబ ఆదాయంపై వివరాలను అందించగలరా?",
//     "In our efforts to address your outstanding dues, we'd like to know if":"మీ బకాయిలను పరిష్కరించేందుకు మా ప్రయత్నాలలో, వన్-టైమ్ పేమెంట్", 
//     "a one-time payment settlement is an option for you.":"సెటిల్‌మెంట్ మీకు ఒక ఎంపికగా ఉందో లేదో తెలుసుకోవాలనుకుంటున్నాము.",
//     "We appreciate your feedback. If a one-time payment is not viable,":"మేము మీ అభిప్రాయాన్ని అభినందిస్తున్నాము. ఒక పర్యాయ చెల్లింపు ఆచరణీయం కానట్లయితే,",
//     "could you consider arranging payment in three equal instalments?":"మీరు మూడు సమాన వాయిదాలలో చెల్లింపును ఏర్పాటు చేయడాన్ని పరిగణించవచ్చా?",
//     "This approach could help ease any immediate financial strain while still addressing the outstanding dues.":"ఈ విధానం బకాయిలను పరిష్కరించేటప్పుడు ఏదైనా తక్షణ ఆర్థిక ఒత్తిడిని తగ్గించడంలో సహాయపడుతుంది.",
//     "Yes":"అవును",
//     "Next":"తరువాత",
//     "I am unable to pay by any means":"నేను ఏ విధంగానూ చెల్లించలేకపోతున్నాను",
//     "Amount with Yes":"అవునుతో మొత్తం",
//     "No":"నం",
//     "Please Select":"దయచేసి ఎంచుకోండి",
//     "We Appreciate Your Honesty.":"మేము మీ నిజాయితీని అభినందిస్తున్నాము.",
//     "No Income":"ఆదాయం లేదు",
//     "less than Rs. 10000/- per month":"కంటే తక్కువ రూ. 10000/- నెలకు",
//     "less than Rs. 20,000/- per month":"కంటే తక్కువ రూ. 20000/- నెలకు",
//     "less than Rs. 40,000/- per month":"కంటే తక్కువ రూ. 40000/- నెలకు",
//     "less than Rs. 60,000/- per month":"కంటే తక్కువ రూ. 60000/- నెలకు",
//     "more than Rs. 1,00,000/- per month":"కంటే ఎక్కువ రూ. నెలకు 1,00,000/-",

//     //newly added

//     "Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "దయచేసి మీరు సరైన GST వివరాలను నమోదు చేశారని నిర్ధారించుకోండి మరియు ఖచ్చితమైన ధృవీకరణ కోసం పేర్కొన్న ఆకృతిని అనుసరించండి.",
//     "If you continue to face issues, kindly double-check your PAN card and re-enter the details.": "మీరు సమస్యలను ఎదుర్కొంటూనే ఉంటే, దయచేసి మీ పాన్ కార్డ్‌ని ఒకటికి రెండుసార్లు తనిఖీ చేసి, వివరాలను మళ్లీ నమోదు చేయండి.",
//     "The GST provided already exists in our records. Please try using a different GST for verification.": "అందించిన GST ఇప్పటికే మా రికార్డులలో ఉంది. దయచేసి ధృవీకరణ కోసం వేరే GSTని ఉపయోగించి ప్రయత్నించండి.",
//     "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.": "అందించిన పాన్ వివరాలు కంపెనీకి చెందినవని తెలుస్తోంది. ధృవీకరణ ప్రయోజనాల కోసం మాకు మీ వ్యక్తిగత పాన్ వివరాలు అవసరం.",
//     "If you continue to face issues, kindly double-check your PAN card and re-enter the details.For further queries or assistance, feel free to call us at +91-9699900111.": "మీరు సమస్యలను ఎదుర్కొంటూనే ఉంటే, దయచేసి మీ PAN కార్డ్‌ని ఒకటికి రెండుసార్లు తనిఖీ చేసి, వివరాలను మళ్లీ నమోదు చేయండి. తదుపరి ప్రశ్నలు లేదా సహాయం కోసం, మాకు +91-9699900111కు కాల్ చేయడానికి సంకోచించకండి.",
//     "The PAN provided already exists in our records. Please try using a different PAN for verification.": "అందించిన PAN ఇప్పటికే మా రికార్డులలో ఉంది. దయచేసి ధృవీకరణ కోసం వేరొక PANని ఉపయోగించడానికి ప్రయత్నించండి.",
//     "New User Registration": "కొత్త వినియోగదారు నమోదు",
//     "To begin your journey with us, we kindly request all new users to register by following the simple steps outlined below:": "మాతో మీ ప్రయాణాన్ని ప్రారంభించడానికి, దిగువ వివరించిన సాధారణ దశలను అనుసరించడం ద్వారా కొత్త వినియోగదారులందరినీ నమోదు చేసుకోవాలని మేము దయతో అభ్యర్థిస్తున్నాము:",
//     "Registration": "నమోదు",
//     "To register and gain access to our comprehensive verification services, please proceed by making a one-time payment of": "మా సమగ్ర ధృవీకరణ సేవలను నమోదు చేసుకోవడానికి మరియు యాక్సెస్ పొందడానికి, దయచేసి ఒక్కసారి చెల్లింపు చేయడం ద్వారా కొనసాగండి",
//     "Rs. 500": "రూ. 500",
//     "GST": "GST",
//     "done": "పూర్తయింది",
//     "Please note that the registration fee is subject to 18% GST (Goods and Services Tax) as per government regulations.": "ప్రభుత్వ నిబంధనల ప్రకారం రిజిస్ట్రేషన్ రుసుము 18% GST (వస్తువులు మరియు సేవల పన్ను)కి లోబడి ఉంటుందని దయచేసి గమనించండి.",
//     "Total Amount Due:": "మొత్తము బాకీ:",
//     "Rs. 590": "రూ. 590",
//     "Total Registration Fee + 18% GST": "మొత్తం నమోదు రుసుము + 18% GST",
//     "Secure Payment Gateway": "సురక్షిత చెల్లింపు గేట్‌వే",
//     "Rest assured, your payment and personal information are handled with the utmost security through our encrypted payment gateway.": "నిశ్చయంగా, మీ చెల్లింపు మరియు వ్యక్తిగత సమాచారం మా గుప్తీకరించిన చెల్లింపు గేట్‌వే ద్వారా అత్యంత భద్రతతో నిర్వహించబడతాయి.",
//     "Registration Complete - Thank You!": "నమోదు పూర్తయింది - ధన్యవాదాలు!",
//     "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "అభినందనలు! మీరు నమోదు ప్రక్రియను పూర్తి చేసారు. అవసరమైన సమాచారాన్ని అందించడంలో మీ అంకితభావం మీకు సమర్థవంతంగా సేవ చేయడానికి మమ్మల్ని ఒక అడుగు దగ్గరకు తీసుకువచ్చింది.",
//     "With your registration finalized, our team is fully equipped to handle your requirements efficiently. We assure you that your case will be handled with the utmost care and attention to detail.": "మీ రిజిస్ట్రేషన్ ఖరారు కావడంతో, మీ అవసరాలను సమర్థవంతంగా నిర్వహించడానికి మా బృందం పూర్తిగా సన్నద్ధమైంది. మీ కేసు చాలా జాగ్రత్తగా మరియు వివరాలకు శ్రద్ధతో నిర్వహించబడుతుందని మేము మీకు హామీ ఇస్తున్నాము.",
//     "Should you ever require assistance or have any queries, please feel free to contact us at any time. We are here to address your concerns and ensure a smooth journey throughout the process.": "మీకు ఎప్పుడైనా సహాయం అవసరమైతే లేదా ఏవైనా ప్రశ్నలు ఉంటే, దయచేసి ఎప్పుడైనా మమ్మల్ని సంప్రదించడానికి సంకోచించకండి. మీ ఆందోళనలను పరిష్కరించడానికి మరియు ప్రక్రియ అంతటా ప్రశాంతమైన ప్రయాణాన్ని నిర్ధారించడానికి మేము ఇక్కడ ఉన్నాము.",
//     "We eagerly anticipate the opportunity to assist you further.": "మీకు మరింత సహాయం చేసే అవకాశాన్ని మేము ఆసక్తిగా ఎదురుచూస్తున్నాము.",
//     "Warm regards,": "శుభాకాంక్షలు,",
//     "PrivateCourt Team": "ప్రైవేట్ కోర్టు బృందం",
//     "Secure UDYAM Number Validation": "సురక్షిత UDYAM నంబర్ ధ్రువీకరణ",
//     "Enter UDYAM for": "దీని కోసం UDYAMని నమోదు చేయండి",
//     "having GST number": "GST సంఖ్యను కలిగి ఉంది",
//     "Enter Your Udyam Number": "మీ Udyam నంబర్‌ని నమోదు చేయండి",
//     "Error: Invalid UAM Number": "లోపం: UAM నంబర్ చెల్లదు",
//     "We regret to inform you that the UAM Number entered isinvalid or incorrect. Please ensure you have accuratelyentered detail and double-check for any typos.": "నమోదు చేసిన UAM నంబర్ చెల్లదని లేదా తప్పు అని మీకు తెలియజేయడానికి మేము చింతిస్తున్నాము. దయచేసి మీరు వివరాలను ఖచ్చితంగా నమోదు చేశారని నిర్ధారించుకోండి మరియు ఏవైనా అక్షరదోషాల కోసం ఒకటికి రెండుసార్లు తనిఖీ చేయండి.",
//     "Server Overload: Please Retry Later": "సర్వర్ ఓవర్‌లోడ్: దయచేసి తర్వాత మళ్లీ ప్రయత్నించండి",
//     "We apologize for the inconvenience and appreciate your patience. Currently, our server is experiencing a surge in requests, creating a temporary delay. Rest assured, we are diligently collecting your data to ensure accuracy and security.": "అసౌకర్యానికి మేము చింతిస్తున్నాము మరియు మీ సహనాన్ని అభినందిస్తున్నాము. ప్రస్తుతం, మా సర్వర్ అభ్యర్థనల పెరుగుదలను ఎదుర్కొంటోంది, తాత్కాలిక ఆలస్యాన్ని సృష్టిస్తోంది. నిశ్చయంగా, ఖచ్చితత్వం మరియు భద్రతను నిర్ధారించడానికి మేము మీ డేటాను శ్రద్ధగా సేకరిస్తున్నాము.",
//     "Please bear with us during this busy period, and we kindly request you to wait for a brief moment before attempting again. Your understanding is crucial in maintaining the quality of service we strive to provide.": "దయచేసి ఈ బిజీ కాలంలో మాతో సహించండి మరియు మళ్లీ ప్రయత్నించే ముందు కొద్దిసేపు వేచి ఉండమని మేము మిమ్మల్ని అభ్యర్థిస్తున్నాము. మేము అందించడానికి ప్రయత్నిస్తున్న సేవ యొక్క నాణ్యతను కొనసాగించడంలో మీ అవగాహన కీలకం.",
//     "For urgent matters or assistance, feel free to call us at +91-9699900111.": "అత్యవసర విషయాలు లేదా సహాయం కోసం, మాకు +91-9699900111కు కాల్ చేయడానికి సంకోచించకండి.",
//     "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.": "మీ సహనానికి మరియు ప్రైవేట్‌కోర్టుకు అప్పగించినందుకు ధన్యవాదాలు. మీ భద్రత మా ప్రాధాన్యత.",
//     "If you continue to face issues, verify the": "మీరు సమస్యలను ఎదుర్కొంటూనే ఉంటే, ధృవీకరించండి",
//     "Applicant’s": "దరఖాస్తుదారు యొక్క",
//     "Error: Invalid OTP": "లోపం: చెల్లని OTP",
//     "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "అసౌకర్యానికి మేము చింతిస్తున్నాము. నమోదు చేసిన OTP తప్పు. దయచేసి OTPని ఒకటికి రెండుసార్లు తనిఖీ చేసి, మళ్లీ ప్రయత్నించండి.",
//     "Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "తదుపరి సహాయం కోసం ఆధార్ కార్డ్ లేదా మా మద్దతు బృందాన్ని +91-9699900111లో సంప్రదించండి. మీ సహకారాన్ని మేము అభినందిస్తున్నాము.",
//     "Error: User already exists": "లోపం: వినియోగదారు ఇప్పటికే ఉన్నారు",
//     "We apologize for the inconvenience. The Aadhaar number provided already exists in our records.Please provide a different Aadhaar number for verification.": "అసౌకర్యానికి మేము చింతిస్తున్నాము. అందించిన ఆధార్ నంబర్ మా రికార్డుల్లో ఇప్పటికే ఉంది.దయచేసి ధృవీకరణ కోసం వేరే ఆధార్ నంబర్‌ను అందించండి.",
//     "We apologize for the inconvenience. The name provided does not match the PAN records. Please ensure that the name entered matches the information on your PAN card and try again.": "అసౌకర్యానికి మేము చింతిస్తున్నాము. అందించిన పేరు PAN రికార్డులతో సరిపోలడం లేదు. దయచేసి నమోదు చేసిన పేరు మీ పాన్ కార్డ్‌లోని సమాచారంతో సరిపోలుతుందని నిర్ధారించుకుని, మళ్లీ ప్రయత్నించండి.",
//     "Enter Bank Details for": "కోసం బ్యాంక్ వివరాలను నమోదు చేయండి",
//     "Error: Already Exists": "లోపం: ఇప్పటికే ఉంది",
//     "We regret to inform you that the ID you've provided already exists in our system. Please try another ID.": "మీరు అందించిన ID ఇప్పటికే మా సిస్టమ్‌లో ఉందని మీకు తెలియజేయడానికి చింతిస్తున్నాము. దయచేసి మరొక IDని ప్రయత్నించండి.",
//     "If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.": "మీరు సమస్యలను ఎదుర్కొంటూనే ఉంటే, దయచేసి మీ బ్యాంక్ స్టేట్‌మెంట్‌ను ధృవీకరించండి లేదా అందించిన సమాచారం యొక్క ఖచ్చితత్వాన్ని నిర్ధారించడానికి మీ బ్యాంక్‌ని సంప్రదించండి.",
//     "Error: Name Not Matched": "లోపం: పేరు సరిపోలలేదు",
//     "Good Afternoon": "శుభ మద్యాహ్నం",
//     "Account security starts with verification": "ఖాతా భద్రత ధృవీకరణతో ప్రారంభమవుతుంది",
//     "OTP Validation for Account Access": "ఖాతా యాక్సెస్ కోసం OTP ధ్రువీకరణ",
//     "Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.": "ధృవీకరణ ప్రక్రియను పూర్తి చేయడానికి దయచేసి మీ ఇన్‌బాక్స్‌ని తనిఖీ చేయండి మరియు నిర్దేశించిన ఫీల్డ్‌లో OTPని నమోదు చేయండి.",
//     "Request a Resend": "మళ్లీ పంపమని అభ్యర్థించండి",
//     "Error: Incorrect OTP": "లోపం: సరికాని OTP",
//     "We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.": "అసౌకర్యానికి మేము చింతిస్తున్నాము. నమోదు చేసిన వన్-టైమ్ పాస్‌వర్డ్ (OTP) తప్పు. దయచేసి మీరు మీ ఇమెయిల్‌లో అందుకున్న OTPని ఖచ్చితంగా నమోదు చేశారని నిర్ధారించుకోండి.",
//     "If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team  at +91-9699900111.": "సమస్య కొనసాగితే, మీరు మళ్లీ పంపే లింక్‌పై క్లిక్ చేయడం ద్వారా లేదా మా మద్దతు బృందాన్ని +91-9699900111లో సంప్రదించడం ద్వారా కొత్త OTPని అభ్యర్థించవచ్చు.",
//     "Double-check the email and try entering the OTP again.": "ఇమెయిల్‌ను ఒకటికి రెండుసార్లు తనిఖీ చేసి, మళ్లీ OTPని నమోదు చేయడానికి ప్రయత్నించండి.",
//     "At PrivateCourt, we prioritize the security and integrity of our services. Our GST verification process is meticulously designed to ensure a reliable and secure experience for all our users.": "ప్రైవేట్‌కోర్ట్‌లో, మేము మా సేవల భద్రత మరియు సమగ్రతకు ప్రాధాన్యతనిస్తాము. మా వినియోగదారులందరికీ విశ్వసనీయమైన మరియు సురక్షితమైన అనుభవాన్ని అందించడానికి మా GST ధృవీకరణ ప్రక్రియ ఖచ్చితంగా రూపొందించబడింది.",
//     "Enter Your GSTIN": "మీ GSTINని నమోదు చేయండి",
//     "Please enter your correct GST Identification Number (GSTIN) in the format 00-XXXXX-0000-X-0-X-0.": "దయచేసి మీ సరైన GST గుర్తింపు సంఖ్య (GSTIN)ని 00-XXXXX-0000-X-0-X-0 ఫార్మాట్‌లో నమోదు చేయండి.",
//     "Upon entering your GSTIN, PrivateCourt will diligently collect essential information associated with it. This includes your full name, date of birth, residential address, and contact details. For business owners, we extend our data collection to encompass all pertinent business details tied to your GSTIN, such a specifics and records of GST return filings.": "మీ GSTINని నమోదు చేసిన తర్వాత, ప్రైవేట్‌కోర్ట్ దానితో అనుబంధించబడిన ముఖ్యమైన సమాచారాన్ని శ్రద్ధగా సేకరిస్తుంది. ఇందులో మీ పూర్తి పేరు, పుట్టిన తేదీ, నివాస చిరునామా మరియు సంప్రదింపు వివరాలు ఉంటాయి. వ్యాపార యజమానుల కోసం, మేము మీ GSTINతో ముడిపడి ఉన్న అన్ని సంబంధిత వ్యాపార వివరాలను, అటువంటి ప్రత్యేకతలు మరియు GST రిటర్న్ ఫైలింగ్‌ల రికార్డులను పొందుపరచడానికి మా డేటా సేకరణను విస్తరింపజేస్తాము.",
//     "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your GSTIN.": "ఆర్థిక అంతర్దృష్టి కోసం మా సిస్టమ్ మీ CIBIL స్కోర్‌ను అంచనా వేస్తుంది మరియు మీ GSTINతో అనుబంధించబడిన ఏవైనా కోర్టు కేసులను క్రాస్ వెరిఫై చేస్తుంది.",
//     "Error: Invalid GST Format": "లోపం: GST ఫార్మాట్ చెల్లదు",
//     "Please enter your personal PAN": "దయచేసి మీ వ్యక్తిగత పాన్‌ను నమోదు చేయండి",
//     "Already Exists": "ఇప్పటికే ఉన్నది",
//     "Invalid": "చెల్లదు",
//     "Secure PAN Validation Process": "సురక్షిత పాన్ ధ్రువీకరణ ప్రక్రియ",
//     "This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.": "ఈ ధృవీకరణ ప్రక్రియ విశ్వసనీయమైన మరియు సురక్షితమైన అనుభవాన్ని అందించడం కోసం మా సేవల సమగ్రతను కాపాడేందుకు రూపొందించబడింది.",
//     "Enter Your PAN Number": "మీ పాన్ నంబర్‌ను నమోదు చేయండి",
//     "Please enter your correct PAN number in the format XXXXX-0000-X.": "దయచేసి మీ సరైన PAN నంబర్‌ని XXXXX-0000-X ఫార్మాట్‌లో నమోదు చేయండి.",
//     "PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details specifics, and records of GST return filings.": "మీ పూర్తి పేరు, పుట్టిన తేదీ, నివాస చిరునామా మరియు సంప్రదింపు వివరాలు వంటి మీ పాన్‌తో అనుబంధించబడిన ముఖ్యమైన సమాచారాన్ని ప్రైవేట్‌కోర్టు చాలా జాగ్రత్తగా సేకరిస్తుంది. వ్యాపార యజమానుల కోసం, GST వివరాల ప్రత్యేకతలు మరియు GST రిటర్న్ ఫైలింగ్‌ల రికార్డులు వంటి మీ PANతో ముడిపడి ఉన్న అన్ని సంబంధిత వ్యాపార వివరాలను పొందుపరచడానికి మేము మా డేటా సేకరణను విస్తరింపజేస్తాము.",
//     "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.": "ఆర్థిక అంతర్దృష్టి కోసం మా సిస్టమ్ మీ CIBIL స్కోర్‌ను అంచనా వేస్తుంది మరియు మీ PANతో అనుబంధించబడిన ఏవైనా కోర్టు కేసులను క్రాస్ వెరిఫై చేస్తుంది.",
//     "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.": "అందించిన పాన్ వివరాలు కంపెనీకి చెందినవని తెలుస్తోంది. ధృవీకరణ ప్రయోజనాల కోసం మాకు మీ వ్యక్తిగత పాన్ వివరాలు అవసరం.",



//     "Data Compilation in Progress: Please Allow Processing Time": "డేటా కంపైలేషన్ ప్రోగ్రెస్‌లో ఉంది: దయచేసి ప్రాసెసింగ్ సమయాన్ని అనుమతించండి",
//     "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.": "మీ సమాచారాన్ని అందించినందుకు ధన్యవాదాలు. మా సిస్టమ్ ప్రస్తుతం మీ సమగ్ర ప్రొఫైల్‌ను ఏర్పాటు చేయడానికి PAN, ఆధార్, GST, ఇమెయిల్ మరియు మరిన్ని వివరాలతో సహా మీరు నమోదు చేసిన డేటాను విశ్లేషించి, కంపైల్ చేసే ప్రక్రియలో ఉంది.",
//     "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.": "ఈ ఆపరేషన్‌కు కొన్ని క్షణాలు పట్టవచ్చు. ఈ సమయంలో, మీ స్క్రీన్ కనీస కార్యాచరణను చూపవచ్చు. దయచేసి ఇది మా డేటా కంపైలేషన్ ప్రాసెస్‌లో ఒక సాధారణ భాగమని హామీ ఇవ్వండి.",
//     "Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.": "మీ ప్రొఫైల్ ఖచ్చితత్వం మరియు భద్రతను కలిగి ఉండేలా సూక్ష్మంగా రూపొందించబడింది. మీ అనుభవాన్ని మెరుగుపరచడానికి మేము పని చేస్తున్నప్పుడు మీ సహనాన్ని మరియు అవగాహనను మేము హృదయపూర్వకంగా అభినందిస్తున్నాము.",
//     "For urgent matters or assistance, feel free to call us at +91-9699900111.": "అత్యవసర విషయాలు లేదా సహాయం కోసం, మాకు +91-9699900111కు కాల్ చేయడానికి సంకోచించకండి.",
//     "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.": "మీ సహనానికి మరియు ప్రైవేట్‌కోర్టుకు అప్పగించినందుకు ధన్యవాదాలు. మీ భద్రత మా ప్రాధాన్యత.",
//     "Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.": "దయచేసి మీ ఆధార్ నంబర్‌ను ఇన్‌పుట్ చేయండి. ఈ ప్రక్రియ మీ భద్రత కోసం ఖచ్చితమైన క్రాస్-చెకింగ్‌ను నిర్ధారిస్తుంది. మీ సహకారానికి ధన్యవాదాలు",
//     "We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the": "అసౌకర్యానికి మేము చింతిస్తున్నాము. నమోదు చేసిన ఆధార్ నంబర్ చెల్లదు. దయచేసి రెండుసార్లు తనిఖీ చేసి, మీరు ప్రవేశించారని నిర్ధారించుకోండి",
//     "Applicant’s correct 12-digit Aadhaar number.": "దరఖాస్తుదారు సరైన 12 అంకెల ఆధార్ నంబర్.",
//     "Error: Invalid OTP": "లోపం: చెల్లని OTP",
//     "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "అసౌకర్యానికి మేము చింతిస్తున్నాము. నమోదు చేసిన OTP తప్పు. దయచేసి OTPని ఒకటికి రెండుసార్లు తనిఖీ చేసి, మళ్లీ ప్రయత్నించండి.",
//     "Welcome": "స్వాగతం",
//     "Ensuring the accuracy of your bank account information is crucial for a seamless verification process.Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.": "అతుకులు లేని ధృవీకరణ ప్రక్రియ కోసం మీ బ్యాంక్ ఖాతా సమాచారం యొక్క ఖచ్చితత్వాన్ని నిర్ధారించడం చాలా ముఖ్యం. దయచేసి మీ చెల్లుబాటు అయ్యే ఖాతా నంబర్‌ను నమోదు చేయండి మరియు మీ ఖాతాతో అనుబంధించబడిన సరైన IFSC కోడ్‌ను ఇన్‌పుట్ చేయండి.",
//     "This verification step is designed to enhance the security of our services and provide you with a reliable experience.": "ఈ ధృవీకరణ దశ మా సేవల భద్రతను మెరుగుపరచడానికి మరియు మీకు నమ్మకమైన అనుభవాన్ని అందించడానికి రూపొందించబడింది.",
//     "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.": "అసౌకర్యానికి మేము చింతిస్తున్నాము. అందించిన పేరు GST రికార్డులతో సరిపోలడం లేదు. దయచేసి నమోదు చేసిన పేరు మీ GST కార్డ్‌లోని సమాచారంతో సరిపోలుతుందని నిర్ధారించుకుని, మళ్లీ ప్రయత్నించండి.",
//     "Business Verification Process": "వ్యాపార ధృవీకరణ ప్రక్రియ",
//     "Ensuring Secure Business Validation- This verification process is meticulously designed to uphold the integrity of our services, providing a reliable and secure experience.": "సురక్షిత వ్యాపార ధృవీకరణను నిర్ధారించడం- ఈ ధృవీకరణ ప్రక్రియ మా సేవల సమగ్రతను నిలబెట్టడానికి, విశ్వసనీయమైన మరియు సురక్షితమైన అనుభవాన్ని అందించడానికి ఖచ్చితంగా రూపొందించబడింది.",
//     "Please provide details for each of your businesses for accurate validation.": "దయచేసి ఖచ్చితమైన ధృవీకరణ కోసం మీ ప్రతి వ్యాపారానికి సంబంధించిన వివరాలను అందించండి.",
//     "PrivateCourt will collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.": "ప్రైవేట్‌కోర్టు మీ పూర్తి పేరు, పుట్టిన తేదీ, నివాస చిరునామా మరియు సంప్రదింపు వివరాలు వంటి మీ పాన్‌తో అనుబంధించబడిన ముఖ్యమైన సమాచారాన్ని సేకరిస్తుంది. వ్యాపార యజమానుల కోసం, GST వివరాలు, MCA ప్రత్యేకతలు మరియు GST రిటర్న్ ఫైలింగ్‌ల రికార్డులు వంటి మీ PANతో ముడిపడి ఉన్న అన్ని సంబంధిత వ్యాపార వివరాలను పొందుపరచడానికి మేము మా డేటా సేకరణను విస్తరింపజేస్తాము.",
//     "Our system evaluates your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.": "ఆర్థిక అంతర్దృష్టి కోసం మా సిస్టమ్ మీ CIBIL స్కోర్‌ను మూల్యాంకనం చేస్తుంది మరియు మీ PANతో అనుబంధించబడిన ఏవైనా కోర్టు కేసుల కోసం క్రాస్ వెరిఫై చేస్తుంది.",
//     "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system, a": "మీ ఖాతా భద్రతను నిర్ధారించడానికి, దయచేసి మీ చెల్లుబాటు అయ్యే ఇమెయిల్ IDని పేర్కొన్న ఫార్మాట్‌లో నమోదు చేయండి- username@domain.com. మీ ఇమెయిల్ చిరునామా మా సిస్టమ్‌లో లేకుంటే,",
//     "account will be created for you.": "మీ కోసం ఖాతా సృష్టించబడుతుంది.",
//     "GST Verification Process": "GST ధృవీకరణ ప్రక్రియ",
//     "We regret to inform you that the GST number entered does not match the required format (00-XXXXX-0000-X-0-X-0).Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "నమోదు చేసిన GST నంబర్ అవసరమైన ఫార్మాట్ (00-XXXXX-0000-X-0-X-0)తో సరిపోలడం లేదని మీకు తెలియజేయడానికి మేము చింతిస్తున్నాము. దయచేసి మీరు సరైన GST వివరాలను నమోదు చేసినట్లు నిర్ధారించుకోండి మరియు ఖచ్చితమైన ధృవీకరణ కోసం పేర్కొన్న ఆకృతిని అనుసరించండి.",
//     "Verify GST": "GSTని ధృవీకరించండి",
//     "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "అభినందనలు! మీరు నమోదు ప్రక్రియను పూర్తి చేసారు. అవసరమైన సమాచారాన్ని అందించడంలో మీ అంకితభావం మీకు సమర్థవంతంగా సేవ చేయడానికి మమ్మల్ని ఒక అడుగు దగ్గరకు తీసుకువచ్చింది.",
//     "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.": "అసౌకర్యానికి మేము చింతిస్తున్నాము. అందించిన పేరు GST రికార్డులతో సరిపోలడం లేదు. దయచేసి నమోదు చేసిన పేరు మీ GST కార్డ్‌లోని సమాచారంతో సరిపోలుతుందని నిర్ధారించుకుని, మళ్లీ ప్రయత్నించండి.",
//     "Skip": "దాటవేయి",
//     "Server Overload: Please Retry Later": "సర్వర్ ఓవర్‌లోడ్: దయచేసి తర్వాత మళ్లీ ప్రయత్నించండి",
//     "Back To Home": "తిరిగి ఇంటికి",
// }

export default TeluguTranslation