import nofound from '../../asset/icons/404.jpg'
import useLanguage from "../../local/saveLanguage";
const NotFound = () =>{
        const translate = useLanguage.getLang();
    return (
        <div class="container">
            <div class="row h-auto">
                <div className="col-lg-6 align-self-center">
                    <h1 className='md' data-translate="adc-main-heading">{translate['Oops!'] ?? "Oops!"}</h1>
                    <p data-translate="adc-title-12">{translate["The page you are searching, can't be found."] ?? "The page you are searching, can't be found."}</p>                    
                </div>
                <div className="col-lg-6 align-self-center">
                    <img className="img-fluid" src={nofound} alt="No found"/>
                </div>
            </div>
        </div>  
    )
}
export default NotFound;
        

