
const CompanyProfileModal = (props) => {
    const companyDetails = props?.user;
    const calculateDateDifference = (dateString) => {
        // Regular expression to match date format "dd/mm/yyyy"
        const regex = /(\d{2}\/\d{2}\/\d{4})/;
        const match = dateString.match(regex);
      
        if (match) {
          const dateParts = match[0].split('/');
          const date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
          const now = new Date();
      
          let years = now.getFullYear() - date.getFullYear();
          let months = now.getMonth() - date.getMonth();
          let days = now.getDate() - date.getDate();
      
          if (days < 0) {
            months -= 1;
            days += new Date(now.getFullYear(), now.getMonth(), 0).getDate();
          }
      
          if (months < 0) {
            years -= 1;
            months += 12;
          }
      
          return { years, months, days };
        }
      
        return { years: 0, months: 0, days: 0 };
      };
      const yearsMonth = calculateDateDifference(companyDetails?.IncorporationDate); 
  return (
    <div class="modal fade" id="companyprofile">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">company</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>

        <div class="modal-body">
          <h5>{companyDetails?.CompanyName}</h5>
          <div class="row">
            <div class="col-lg-12">
              <ol class="list-group">
                <li class="list-group-item text-sm">Founded in: <strong class="float-end">{companyDetails?.IncorporationDate}  ({yearsMonth.years} Years {yearsMonth.months} Months)</strong></li>
                <li class="list-group-item">SubCategory: <strong class="float-end">{companyDetails?.SUBCATEGORY}</strong></li> 
                <li class="list-group-item">ROC : <strong class="float-end">{companyDetails?.ROC}
                </strong></li>
                <li class="list-group-item">Authorized Capital: <strong class="float-end">{companyDetails?.AUTHORIZED_CAPITAL}
                </strong></li>
                <li class="list-group-item">Headquarters: <strong class="float-end">{companyDetails?.District}, {companyDetails?.Country}</strong></li>
                {/* <li class="list-group-item">Office Locations: <strong class="float-end">Mumbai,Delhi India</strong></li> */}
                <li class="list-group-item">Class: <strong class="float-end">{companyDetails?.Class}
                </strong></li>
                {/* <li class="list-group-item">Founders: <strong class="float-end">Vijay Shekhar Sharma, Akshay Khanna, Vijay Shekhar
                </strong></li> */}
                {/* <li class="list-group-item">Type of Company: <strong class="float-end">Corporate</strong></li> */}
                {/* <li class="list-group-item">Nature of Business: <strong class="float-end">Product
                  |
                  Service
                  |
                  B2C
                  |
                  B2B</strong></li> */}
                <li class="list-group-item">Website: <strong class="float-end">{companyDetails?.WebsiteURL ? companyDetails?.WebsiteUR : 'N/A' }</strong></li>
              </ol>
            </div>
          </div>
        </div>           
      </div>
    </div>
  </div>
  );
};

export default CompanyProfileModal;
