import React, { useState } from 'react';
import VerifyButton from './verifyButton';
import { useDispatch } from 'react-redux';
import { changeStage } from "../../../features/verificationStage/verificationSlice";
import useLanguage from "../../../local/saveLanguage";


const MidBank = () => {
  const [accountNumber, setAccountNumber] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const dispatch = useDispatch()
  const translate = useLanguage.getLang();
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'accountNumber') {
      setAccountNumber(value);
    } else if (name === 'ifscCode') {
      setIfscCode(value);
    }
  };

  const handleSubmit = (event) => {
    dispatch(changeStage("email"));
  };

  return (
    <div className="login-mid">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 login-mid-left">
            <h2><span data-translate="tran-1">{translate?.["Welcome"] ?? "Welcome"}</span> XXXX</h2>
            <p data-translate="tran-2">{translate?.["Start Your Bank Account Verification"] ?? "Start Your Bank Account Verification"}</p>
          </div>
          <div className="col-xl-5 col-lg-6 offset-xl-1">
            <div className="card pb-normal bank-verification">
              <div className="vf-content">
                <h1 className="mb-2" data-translate="tran-3">{translate?.["Bank Verification"] ?? "Bank Verification"}</h1>
                <p data-translate="tran-4">{translate?.["Ensuring the accuracy of your bank account information is crucial for a seamless verification process. Kindly enter your valid Account Number, and input the correct IFSC code associated with your account."] ?? "Ensuring the accuracy of your bank account information is crucial for a seamless verification process. Kindly enter your valid Account Number, and input the correct IFSC code associated with your account."}</p>
                <p data-translate="tran-5">{translate?.["This verification step is designed to enhance the security of our services and provide you with a reliable experience."] ?? "This verification step is designed to enhance the security of our services and provide you with a reliable experience."}</p>
                <p data-translate="tran-6">{translate?.["For any queries or assistance, feel free to call us at +91-9699900111."] ?? "For any queries or assistance, feel free to call us at +91-9699900111."}</p>
                <p><strong data-translate="tran-7">{translate?.["Thank you for entrusting PrivateCourt. Your security is our priority."] ?? "Thank you for entrusting PrivateCourt. Your security is our priority."}</strong></p>
              </div>
              <form onSubmit={handleSubmit}>
                <h5 className="mb-1 mt-3 blue" data-translate="tran-8">{translate?.["Account Number"] ?? "Account Number"}</h5>
                <input
                  className="mt-1 mb-1 form-control rounded"
                  type="text"
                  name="accountNumber"
                  value={accountNumber}
                  onChange={handleChange}
                //   onKeyPress={restrictInput}
                />
                <h5 className="mb-1 mt-3 blue" data-translate="tran-9">{translate?.["IFSC code"] ?? "IFSC code"}</h5>
                <input
                  className="mt-1 form-control rounded text-uppercase"
                  type="text"
                  name="ifscCode"
                  value={ifscCode}
                  onChange={handleChange}
                />
                <div className="ms-auto">
                  <VerifyButton btnName="Verify" onSubmit={handleSubmit} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MidBank;

