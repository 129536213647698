import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import httpService from "../../../services/httpService";
import http from '../../../services/httpService'
import useLanguage from "../../../local/saveLanguage";
import { changeStage } from "../../../features/disputeStage/disputeSlice";

const ThankYou = () => {
  const dispatch=useDispatch();
  const tokenID =  useSelector(state => state.disputeStage.accessToken);
  console.log(tokenID)
  const translate = useLanguage.getLang();
  console.log(tokenID)
  const InactiveURL = async () => {
    await http.post2("/deleteAccessToken",{accessToken:tokenID})
    // await axios.post("/deleteAccessToken",{accessToken:tokenID})
    .then((res)=>{
      console.log(res);
    })
    .catch((err)=>{
      dispatch(changeStage("overload"));
    })
  }
  useEffect(()=>{
    if(tokenID){
      InactiveURL();
    }
  },[tokenID]);
   return (
    <div class="login-mid">
          <div class="container">
            <div class="row">
              <div class="col-xl-6 col-lg-6 login-mid-left">
                <h2 data-translate="lml-heading">{translate["Aadhaar Verification"] ?? "Aadhaar Verification"}</h2>
                <p data-translate="lml-para">{translate["To proceed further, we have initiated the Aadhaar verification process. Please provide the OTP (One-Time Password) that you have received to complete this step. Ensuring the security and authenticity of our users is paramount to us, and this verification step helps us maintain the integrity of our services."] ?? "To proceed further, we have initiated the Aadhaar verification process. Please provide the OTP (One-Time Password) that you have received to complete this step. Ensuring the security and authenticity of our users is paramount to us, and this verification step helps us maintain the integrity of our services."}</p>
              </div>
              <div class="col-xl-6 col-lg-6">
                <div class="card pb-normal ad-box">
                  <div class="alert alert-success text-success vf-content" role="alert">
                    <div class="text-center">
                        <i class="bi bi-check-circle-fill fs-1"></i>
                    </div>
                    <h4 class="alert-heading blue" data-translate="ty-heading">{translate["Thank You Aadhaar Verification Successfully"] ?? "Thank You Aadhaar Verification Successfully"}</h4>
                    <p data-translate="ty-title-1">{translate["Thank you for providing the OTP for Aadhaar verification. With this step completed, we are now ready to proceed with your case."] ?? "Thank you for providing the OTP for Aadhaar verification. With this step completed, we are now ready to proceed with your case."}</p>
                    <p data-translate="ty-title-2">{translate["Our team will continue to work diligently to bring about a resolution that meets your needs and expectations. Should you have any questions or concerns along the way, please don't hesitate to reach out to us."] ?? "Our team will continue to work diligently to bring about a resolution that meets your needs and expectations. Should you have any questions or concerns along the way, please don't hesitate to reach out to us."}</p>
                    <p data-translate="ty-title-3">{translate["Once again, thank you for entrusting PrivateCourt with your dispute resolution needs. We look forward to serving you."] ?? "Once again, thank you for entrusting PrivateCourt with your dispute resolution needs. We look forward to serving you."}</p>
                    <p class="fw-bold">
                      <span data-translate="ty-title-4">Warm regards,</span> 
                      <br/><span data-translate="ty-title-5">PrivateCourt Team</span>
                    </p>
                  </div>                   
                </div>
              </div>
            </div>
          </div>
        </div>
   )
}
export default ThankYou;