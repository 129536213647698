// import React from 'react';
// import GaugeChart from 'react-gauge-chart';


// const Graph = ({ score }) => {
//   const percent = (score - 300) / (850 - 300); // Normalize score to [0, 1]

//   return (
//     <div style={{ textAlign: 'center' }} >
//       <GaugeChart 
//         id="credit-score-gauge"
//         nrOfLevels={4}
//         percent={percent}
//         arcPadding={0.02}
//         cornerRadius={3}
//         colors={['#FF0000', '#FF8000', '#FFFF00', '#00FF00']}
//         arcWidth={0.3}
//         textColor="#ffff"
//         needleColor="#464A4F"
//       />
//       <div style={{ fontSize: '24px', marginTop: '-20px' }}>
//         {score}
//       </div>
//       {/* <div style={{ marginTop: '20px', fontSize: '18px' }}>
//         <div>
//           <span style={{ color: '#00a000', fontWeight: 'bold' }}>✔</span> Basic Details Verified.
//         </div>
//         <div>
//           <span style={{ color: '#ff0000', fontWeight: 'bold' }}>✖</span> Need to Verify Address.
//         </div>
//       </div> */}
//     </div>
//   );
// };

// export default Graph;


import React, { useEffect, useRef, useState } from 'react';
import ReactSpeedometer from "react-d3-speedometer";

const Graph = ({ score }) => {
  const [dimensions, setDimensions] = useState({ width: 400, height: 300 });
  const containerRef = useRef(null);
  useEffect(() => {
    const updateDimensions = () => {
      console.log("called")
      if (containerRef.current) {
        const { offsetWidth } = containerRef.current;
        if (window.innerWidth < 500) {
          setDimensions({
            width: offsetWidth * 0.9,
            height: offsetWidth * 0.45,
          });
        } else {
          setDimensions({
            width: offsetWidth,
            height: offsetWidth * 0.58,
          });
        }
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);
  return (
    <div ref={containerRef} className="speedometer-container" style={{ textAlign: 'center', marginTop: '40px' }}>
      <ReactSpeedometer
        forceRender={true}
        value={score}
        minValue={300}
        maxValue={900}
        segments={4}
        segmentColors={['#FF0000', '#FFA500', '#00FF00', '#008000']}
        needleColor="#000000"
        startColor="#FF0000"
        endColor="#008000"
        textColor="#000000"
        ringWidth={60}
        width={window.innerWidth < 500 ? dimensions.width : 400}
        height={window.innerWidth < 500 ? dimensions.width : 250}
      />
      <div className="speedometer-labels" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <span style={{ color: '#FF0000',fontSize:'20px',fontWeight:'bold' }}>Poor</span>
        <span style={{ color: '#FFA500',fontSize:'20px',fontWeight:'bold' }}>Average</span>
        <span style={{ color: '#00FF00',fontSize:'20px',fontWeight:'bold' }}>Good</span>
        <span style={{ color: '#008000',fontSize:'20px',fontWeight:'bold' }}>Excellent</span>
      </div>
    </div>
  );
};

export default Graph;
