import React from "react";
import bgLogo from "./../../../images/logo-bg.png";
import bgLogoWhite from "./../../../images/logo-white-bg.png";
import { useSelector } from "react-redux";

const BgLogo = ({ children }) => {
     //Read data from the store with the useSelector hook
    // Get the dispatch function with the useDispatch hook, and dispatch actions as needed
    const theme = useSelector(state => state.theme.theme);
    return (
        <div className="bg-logo-bottom bg-cover">
            <img id="bg-img-logo" src={theme?bgLogo:bgLogoWhite} alt=""/>
        </div>
    );
};

export default BgLogo;