const BusinessLossCheckbox = ({ label, checked, onChange }) => {
    return (
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />
        <label
          className="form-check-label"
          htmlFor="business-loss-c"
          data-translate="business-loss-c"
        >
          {label}
        </label>
      </div>
    );
  };

  export default BusinessLossCheckbox;