import React,{useRef, useState} from 'react';
import VerifyButton from './verifyButton';
import { useDispatch } from 'react-redux';
import { changeStage } from "../../../features/verificationStage/verificationSlice";
import useLanguage from "../../../local/saveLanguage";
import http from "./../../../services/httpService";
import auth from "./../../../services/authService";


const MidAadhaar = () => {
    const translate = useLanguage.getLang();
    const [aadhaarEr, setAadhaarEr] = useState(false);
    const [user, setUser] = useState(auth.getUser().UserLogin);
    const dispatch = useDispatch();

    // const restrictInput = (e) => {
    //     const charCode = e.which ? e.which : e.keyCode;
    //     if (charCode < 48 || charCode > 57) {
    //         e.preventDefault();
    //     }
    // };

    const inputRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
      ];
    
      console.log(inputRefs);
    
      const handleKeyDown = (index, e) => {
        // console.log(inputRefs[0].current.value);
        setAadhaarEr(false);
        if (e.key === "Backspace" && index > 0 && e.target.value === "") {
          const prevIndex = index-1;
          inputRefs[prevIndex].current.focus();
        } else if (e.key >= "0" && e.key <= "9") {
          const nextIndex = index + 1;
          if (nextIndex < inputRefs.length) {
            inputRefs[index].current.value = e.key;
            inputRefs[nextIndex].current.focus();
          }
        } 
        else if (
          index === 0 &&
          e.key !== "Backspace" &&
          (e.key < "0" || e.key > "9")
        ) {
          // Prevent typing in the first input field if it's not a number or Backspace
          inputRefs[0] = e.key;
          e.preventDefault();
        }
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
        // setLoading("Loading...");
        let aadhaarno = inputRefs.map((ref) => ref.current.value).join("");
        try {
          const response = await http.post2("/aadhaarOtpget", {
            aadhaarno,
            mid: user.mid,
          });
          auth.login(response.data.token);
          dispatch(changeStage("bank"));
          // setUser(auth.getUser().UserLogin);
          // alert("Aadhaar verified");
          // navigate("/dashboard");
        //   if (response.data.token) {
        //     try {
        //       const response2 = await http.post2("/verifyUser", {
        //         mid: user.mid,
        //       });
        //       auth.login(response2.data.token);
        //       setUser(auth.getUser().UserLogin);
        //       setWrongAttempts(0);
        //       setLoading("");
        //     } catch (error) {
        //       setLoading("");
        //       setWrongAttempts(wrongAttempts + 1);
        //       console.log("Error", error);
        //     }
        //   } else {
        //     setWrongAttempts(wrongAttempts + 1);
        //   }

        } catch (error) {
        //   alert("Aadhar not verified");
        //   setAadharer(true);
        //   setLoading("");
        //   setWrongAttempts(wrongAttempts + 1);
          setAadhaarEr(true);
          console.error("Error:", error);
        }
      };

    // const handleSubmit = () => {
    //     dispatch(changeStage("bank"));
    // }

    return (
        <div className="login-mid">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-lg-6 login-mid-left">
                        <h2 data-translate="tran-1">{translate?.["Aadhaar Details Verification"] ?? "Aadhaar Details Verification"}</h2>
                    </div>
                    <div className="col-xl-6 col-lg-6 offset-xl-1">
                        <div className="card pb-normal ad-box">
                            <div className="vf-content">
                                <h1 data-translate="tran-2">{translate?.["Enter Your Aadhaar Number"] ?? "Enter Your Aadhaar Number"}</h1>
                                <p data-translate="tran-3">{translate?.["Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation."] ?? "Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation."}</p>
                                <p data-translate="tran-4">{translate?.["For any queries or assistance, feel free to call us at +91-9699900111."] ?? "For any queries or assistance, feel free to call us at +91-9699900111."}</p>
                                <p><strong data-translate="tran-5">{translate?.["Thank you for entrusting PrivateCourt. Your security is our priority."] ?? "Thank you for entrusting PrivateCourt. Your security is our priority."}</strong></p>
                            </div>
                            <div className="otp-boxs otp-pan d-flex flex-wrap justify-content-center udyam-number-box aadhar-number-box">
                                {[...Array(12)].map((_, index) => (
                                    <React.Fragment key={index}>
                                        <input
                                            className="text-center form-control rounded"
                                            type="text"
                                            onKeyUp={(e)=>handleKeyDown(index, e)}
                                            maxLength="1"
                                            ref={inputRefs[index]}
                                        />
                                        {(index === 3 || index === 7) && <span className="aadhar-divider">-</span>}
                                    </React.Fragment>
                                ))}
                                {/* <h6 className="field-mid-text">1234</h6> */}
                            </div>
                            <h5 className="mb-1 mt-3" data-translate="tran-6">Enter Your Aadhaar OTP Here</h5>
                            <div className="otp-boxs d-flex flex-row justify-content-center aadhar-number-box">
                                {[...Array(6)].map((_, index) => (
                                    <input
                                        key={index}
                                        className="text-center form-control rounded"
                                        type="text"
                                        // onKeyPress={restrictInput}
                                        maxLength="1"
                                    />
                                ))}
                            </div>
                            <div className="ms-auto">
                                <VerifyButton btnName="Verify Aadhaar" onSubmit={handleSubmit} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MidAadhaar;
