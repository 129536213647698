// import React, { useEffect } from "react";
// import ReactDOM  from "react-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Login from "./pages/loginPage/login";


// function App() {
//   const theme = useSelector(state => state.theme);

//   useEffect(()=>{
//     if (!theme) {
//       import("./style2.css");
//     }
//     else{
//       import("./style.css");
//     }
//   }, [theme]);

//   return (
//     <>
//     <Routes>
//       <Route path="/login" element={<Login />} />
//     </Routes>
//     </>
//   );
// }

// export default App;


import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/loginPage/login";
import DisputePage from "./pages/dashboardPage/DisputePage";
import PaymentComponent from "./components/payment";
import Dispute from "./components/Dispute";
import Emailsend from "./components/EmailSend";
import MainGraph from "./pages/UsersProfile/components/MainGraph";
import UserProfile from "./pages/UsersProfile/UserProfile";
// import './style.css'
// import './style2.css'


function App() {
  const theme = useSelector((state) => state.theme.theme);

  // useEffect(() => {
  //   const importThemeStyle = async () => {
  //     console.log(theme);
  //     if (!theme) {
  //       await import("./style2.css");
  //     } else {
  //       await import("./style.css");
  //     }
  //   };

  //   importThemeStyle();
  // }, [theme]);

  useEffect(() => {
    const importThemeStyle = async () => {
      const styleSheetId = "themeStyleSheet";
      const existingStyleSheet = document.getElementById(styleSheetId);
      if (existingStyleSheet) {
        existingStyleSheet.remove();
      }
      const link = document.createElement("link");
      link.id = styleSheetId;
      link.rel = "stylesheet";
      link.href = theme ? "./style.css" : "./style2.css";
      document.head.appendChild(link);
    };

    importThemeStyle();
  }, [theme]);

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/dispute/*" element={<DisputePage />} />
        <Route path="/payment" element={<PaymentComponent />}/>
        <Route path='/dispute1' element={<Dispute />}/>
        <Route path="/emailsend" element={<Emailsend />}/>
        <Route path="/graph" element={<UserProfile />}/>
      </Routes>
    </>
  );
}

export default App;
