const titleCase = (input) => {
    if (typeof input == "string") {
      return input
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    } else if (typeof input == "number") {
      return input;
    } else {
      throw new Error("Input must be either a string or a number");
    }
  };

export default titleCase;