import {createSlice} from "@reduxjs/toolkit";

const initialState={
    verificationStage:"email",
}

export const verificationSlice=createSlice({
    name:"verificationStage",
    initialState,
    reducers:{
        changeStage:(state, action)=>{
            state.verificationStage=action.payload;
        }
    }
});

export const {changeStage}=verificationSlice.actions;

export default verificationSlice.reducer;