import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeTheme } from "../../features/theme/themeSlice";
import { changeStage } from "../../features/verificationStage/verificationSlice";
import LoginWrapper from "./components/loginWrapper";
import BgLogo from "./components/bgImgLogo";
import LoginInnerWrapper from "./components/loginInnerWrapper";
import LoginHeader from "./components/loginHeader";
// import LoginMid from "./components/loginMid";
import MidEmailOTP from "./components/midEmailOtp";
import MidEmail from "./components/midEmail";
import LoginLang from "./components/loginLang";
import MidPan from "./components/midpan";
import MidAadhaar from "./components/midAadhaar";
import MidBank from "./components/midBank";
import DataCompilation from "../rootfiles/DataCompilation";

const Login = (props) => {
    const dispatch = useDispatch();
    const theme = useSelector(state => state.theme);
    const verificationStage=useSelector(state=>state.verificationStage.verificationStage);
    const lang=useSelector(state=>state.lang.lang);
    console.log(lang,"lang"); 
    const handleThemeChange = () => {
        dispatch(changeTheme());
    };
    
    useEffect(()=>{
        console.log(lang,'lang.. useEffect');
    },[lang])

    return (
        <LoginWrapper>
            <BgLogo/>
            <LoginInnerWrapper>
                <LoginHeader/>
                {verificationStage=="email" && <MidEmail/>}
                {verificationStage=="emailOtp" && <MidEmailOTP/>}
                {verificationStage=='pan' && <MidPan/>}
                {verificationStage=='aadhaar' && <MidAadhaar/>}
                {verificationStage=='bank' && <MidBank/>}
                <LoginLang/>
            </LoginInnerWrapper>

            {/* <h1>new Login page</h1>
            <button onClick={handleThemeChange}>
                Change Theme
            </button>
            <p>Current Theme: {theme}</p>
            <p>New Login Page</p> */}
        </LoginWrapper>
    );
};

export default Login;
