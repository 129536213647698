export const CompanyList = {
    "Vector Finance Pvt. Ltd.":"vector finance",
    "SURJIT HIRE PURCHASE PRIVATE LIMITED":"Surjit Hire Purchase",
    "STFT Finance Private Limited":"STFT FINANCE",
    "":"Smart Prism finance",
    "LENDINGKART FINANCE LIMITED":"LendingKart",
    "":"Klubworks Finance",
    "":"JS Loans",
    "ICICI HOME FINANCE COMPANY LIMITED":"ICICI HOME",
    "K. M. GLOBAL CREDIT PRIVATE LIMITED":"Credit Fair Finance",
    "Aye Finance Private Limited":"Aye Finance",
    "AVANTI FINANCE PRIVATE LIMITED":"AVANTI FINANCE",
    "":"Ambit Finance",
    "SAYYAM INVESTMENTS PVT LTD (PayRupik)":"SAYYAM INVESTMENTS PRIVATE LIMITED",
    "Trickle Flood Technologies Pvt Ltd":"Trickle Flood Technologies Pvt Ltd"
  }
export const languageFolders = {
    "English (UK)": "AudioEnglish",
    "Hindi": "AudioHindi",
    "Telugu": "AudioTelugu",
    "Tamil": "AudioTamil",
    "Bengali": "AudioBangla",
    "Gujarati":"AudioGujarati",
    "Kannada":"AudioKannada",
    "Malayalam":"AudioMalayalam",
    "Marathi":"AudioMarathi"
  };