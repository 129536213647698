import React from "react";
import loadingIcon from "../asesst/loading3.gif";

function loading() {
  return (
    <div
      className="main-wrapper d-flex justify-content-center align-items-center"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: 9999,
      }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg d-flex justify-content-center align-items-center">
            <img src={loadingIcon} alt="loading image" style={{ marginTop: '2vh' }} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default loading;