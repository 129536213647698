import { changeLang } from "../../features/language/langSlice";
import BgLogo from "../loginPage/components/bgImgLogo";
import LoginHeader from "../loginPage/components/loginHeader";
import LoginInnerWrapper from "../loginPage/components/loginInnerWrapper";
import LoginWrapper from "../loginPage/components/loginWrapper";
import VerifyButton from "../loginPage/components/verifyButton";
import { useSelector, useDispatch } from "react-redux";
import LanguageSelection from "./components/LanguageSelection";
import LoginLang from "../loginPage/components/loginLang";
import React, { useEffect, useMemo, useState,useCallback, Suspense } from "react";
import Introduction from "./components/Introduction";
import ReviewingPage from "./components/ReviewingPage";
import SpotReason from "./components/SpotReason";
import Settlement from "./components/Settlement";
// import DataCompilation from "../rootfiles/DataCompilation";
import axios from 'axios';
import { getData,changeStage, insertToken } from "../../features/disputeStage/disputeSlice";
import AdharVerification from "./components/AdharVerification";
import ThankYou from "./components/Thankyou";
import http from '../../services/httpService';
import NotFound from "../rootfiles/NotFound";
import ServerOverload from "../rootfiles/ServerOverload";
const DataCompilation = React.lazy( () => import("../rootfiles/DataCompilation"));
const DisputePage=()=>{
    const [Loading,setLoading]=useState(true);
    const getUrlParameter = (name) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        const results = regex.exec(window.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
      };

      //Reading the accessToken 
    const getID = useMemo(() => getUrlParameter('q1'),[]);

    const dispatch = useDispatch();
    const disputeStage = useSelector(state => state.disputeStage.disputeStage);
    const token = useSelector(state => state.disputeStage.accessToken);
    const loanID = useSelector(state => state.disputeStage.data?.loanID);
    const lang = useSelector(state => state.lang.lang);
  
    const fetchDetails1 = useCallback(async () => {
      try {
        const res = await http.post2("/disputeDataNew", { accessToken: getID });
        if (res.data === "Not Found Token") {
          dispatch(changeStage("server"));
        } else {
          dispatch(getData(res.data));
          dispatch(insertToken(getID));
        }
        setLoading(false);
      } catch (err) {
        dispatch(changeStage("overload")); //error will check in our end
      }
    }, [dispatch, getID]);
  
    const fetchStage = useCallback(async () => {
      try {
        const res = await http.post2("/webData", { loanID });
        if (res.data.stage) {
          dispatch(changeStage(res.data.stage));
        }
      } catch (err) {
        dispatch(changeStage("overload"));      }
    }, [dispatch, loanID]);
  
    useEffect(() => {
      fetchDetails1();
    }, [fetchDetails1]);
  
    useEffect(() => {
      if (loanID) {
        fetchStage();
      }
    }, [loanID, fetchStage]);
    return (
        <>
        {Loading ?
        <>
         <LoginInnerWrapper>
         {/* <LoginHeader/>   */}
         <Suspense fallback={<h1>Loading....</h1>}>
         <DataCompilation/>
         <LoginLang/>
         </Suspense>
          {/* <DataCompilation/> */}
         </LoginInnerWrapper>
         </>
        :
        <LoginWrapper>
       {disputeStage !=='server' && <BgLogo/>} 
        <LoginInnerWrapper>
            {disputeStage !=='server' && <LoginHeader/>} 
            {disputeStage =="selectlanguage" && <LanguageSelection />} 
            {disputeStage =="introduction" && <Introduction />}
            {disputeStage =="disputeReview" && <ReviewingPage />}
            {disputeStage =="spotreason" && <SpotReason />}
            {disputeStage =="settlement" && <Settlement />}
            {disputeStage =='AdharVerification' && <AdharVerification />}
            {disputeStage =='verified' && <ThankYou />}
            {disputeStage =='server' && <NotFound />}
            {disputeStage == 'overload' && <ServerOverload />}
             <LoginLang/>  
        </LoginInnerWrapper>
        </LoginWrapper>
}
        </>
    )
}
export default DisputePage;