import React, { useEffect } from "react";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";

const BarChart = (props) => {
  const user = props?.user;
  // console.log(user);
  const gstArray = props?.user?.GSTDetails;
  const combinedTurnOver = gstArray.reduce((acc, gst) => {
    gst.gstTurnOver5year.forEach(({ pan_estimated_total, year }) => {
      if (!acc[year]) {
        acc[year] = 0;
      }
      acc[year] += parseFloat(pan_estimated_total);
    });
    return acc;
  }, {});
  
  const resultArray = Object.keys(combinedTurnOver).map((year) => ({
    year,
    totalEstimated: combinedTurnOver[year],
  }));
  
  // console.log(resultArray);
  const labels = resultArray.map(item => item.year);
  const dataValues = resultArray.map(item => parseFloat(item.totalEstimated));

// Create the data object for Chart.js
const data = {
  labels: labels,
  datasets: [
    {
      label: "Company Turnover Each Year",
      backgroundColor: "#2f4b7c",
      borderColor: "rgb(255, 99, 132)",
      data: dataValues,
    },
  ],
};

  return (
    <div>
      <Bar data={data} />
    </div>
  );
};
export default BarChart;