import { useDispatch, useSelector } from "react-redux";
import { changeStage } from "../../../features/disputeStage/disputeSlice";
import VerifyButton from "../../loginPage/components/verifyButton";
import useLanguage from "../../../local/saveLanguage";
import { useCallback, useState } from "react";
import axios from "axios";
import http from "../../../services/httpService";
import Loading from "../../../components/loading";
const Settlement = () => {
  const dispatch = useDispatch();
  const loanID = useSelector((state) => state.disputeStage.data?.loanID);
  const [familyIncome, setFamilyIncome] = useState("");
  const [firstAsk, setFirstAsk] = useState("");
  const [oneTime, setOneTime] = useState("");
  const [amountus, setAmountUs] = useState("");
  const [loading, setLoading] = useState("");
  const [relief, setRelief] = useState("");
  const translate = useLanguage.getLang();

  // console.log(relief);
  const handleSubmit = () => {
    dispatch(changeStage("AdharVerification"));
  };
  const handleSubmitToSave = async () => {
    setLoading(true);
    // await http.post2("/AddFinancialIssue",{loanID:loanID,SettlementDiscuss:{OpenToDiscuss:firstAsk,FamilyIncome:familyIncome,OneTimePayment:oneTime,AmountPay:amountus},stage:"AdharVerification"})
    await http.post2("/AddFinancialIssue", {
          loanID: loanID,
          SettlementDiscuss: {
          OpenToDiscuss: firstAsk,
          FamilyIncome: familyIncome,
          OneTimePayment: oneTime,
          AmountPay: amountus
        },
        stage: "AdharVerification",
        reliefDate: relief
      })
      .then((res) => {
        if (res.data === "Updated Successfully") {
          setLoading(false);
          handleSubmit();
        }
      })
      .catch((err) => {
        dispatch(changeStage("overload"));
  });
  };

  const handleFirstAsk = useCallback((e) => {
    if (e.target.value === "No") {
      setFamilyIncome("");
      setOneTime("");
    }
    if (e.target.value === "Yes") {
      setAmountUs("");
    }
    setFirstAsk(e.target.value);
  }, []);
  const handleFamilyIncome = useCallback((e) => {
    setFamilyIncome(e.target.value);
  }, []);
  const handleOneTime = useCallback((e) => {
    setOneTime(e.target.value);
  }, []);
  const handleAmountUs = useCallback((e) => {
    setAmountUs(e.target.value);
  }, []);
  console.log(firstAsk, familyIncome, oneTime, amountus);
  return (
    <>
      {loading && <Loading />}
      <div class="login-mid">
        <div class="container">
          <div class="row">
            <div class="col-xl-7 col-lg-5 login-mid-left">
              <h2 data-translate="esp-1">
                {translate["Exploring Settlement Possibilities"] ??
                  "Exploring Settlement Possibilities"}
              </h2>
              <p data-translate="esp-2">
                {translate[
                  "As our discussion progresses, I appreciate your openness in explaining the reason for the default. It's crucial to address your outstanding dues promptly to prevent any legal complexities. We recommend exploring different avenues, such as seeking assistance from family or investigating alternative financing options, to fulfill your obligations without delay."
                ] ??
                  "As our discussion progresses, I appreciate your openness in explaining the reason for the default. It's crucial to address your outstanding dues promptly to prevent any legal complexities. We recommend exploring different avenues, such as seeking assistance from family or investigating alternative financing options, to fulfill your obligations without delay."}
              </p>
              <p data-translate="esp-3">
                {translate[
                  "Timely settlement not only mitigates legal risks but also strengthens trust and credibility in your financial interactions. Your cooperation in this matter is truly valued."
                ] ??
                  "Timely settlement not only mitigates legal risks but also strengthens trust and credibility in your financial interactions. Your cooperation in this matter is truly valued."}
              </p>
            </div>
            <div class="col-xl-5 col-lg-7">
              <div class="card pb-normal bank-verification">
                <div class="vf-content">
                  <h1 data-translate="esp-4">
                    {translate["Settlement Options"] ?? "Settlement Options"}
                  </h1>
                  <p data-translate="esp-5">
                    {translate[
                      "In our efforts to facilitate a swift resolution, we seek your cooperation."
                    ] ??
                      "In our efforts to facilitate a swift resolution, we seek your cooperation."}
                  </p>
                  <p>
                    <strong data-translate="esp-6">
                      {translate[
                        "Are you open to discussing the settlement of your outstanding dues?"
                      ] ??
                        "Are you open to discussing the settlement of your outstanding dues?"}
                    </strong>
                  </p>
                </div>
                <select
                  class="form-select mb-3"
                  aria-label="Default select example"
                  id="settlement-options"
                  onChange={(e) => {
                    handleFirstAsk(e);
                  }}
                >
                  <option value="" data-translate="esp-7">
                    {translate["Please Select"] ?? "Please Select"}
                  </option>
                  <option value="Yes" data-translate="esp-8">
                    {translate["Yes"] ?? "Yes"}
                  </option>
                  <option value="No" data-translate="esp-9">
                    {translate["No"] ?? "No"}
                  </option>
                  <option
                    value="I am unable to pay by any means"
                    data-translate="esp-10"
                  >
                    {translate["I am unable to pay by any means"] ??
                      "I am unable to pay by any means"}
                  </option>
                </select>
                {firstAsk === "Yes" ? (
                  <div class="settlement-options-yes">
                    <p data-translate="esp-11">
                      {translate[
                        "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly,"
                      ] ??
                        "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly,"}{" "}
                      <strong>
                        {translate[
                          "could you kindly provide details on your current family income?"
                        ] ??
                          "could you kindly provide details on your current family income?"}
                      </strong>
                    </p>
                    <select
                      class="form-select mb-3"
                      aria-label="Default select example"
                      value={familyIncome}
                      onChange={(e) => {
                        handleFamilyIncome(e);
                      }}
                    >
                      <option value="" data-translate="esp-12">
                        {translate["Please Select"] ?? "Please Select"}
                      </option>
                      <option value="No Income" data-translate="esp-13">
                        {translate["No Income"] ?? "No Income"}
                      </option>
                      <option
                        value="less than Rs. 10000/- per month"
                        data-translate="esp-14"
                      >
                        {translate["less than Rs. 10000/- per month"] ??
                          "less than Rs. 10000/- per month"}
                      </option>
                      <option
                        value="less than Rs. 20,000/- per month"
                        data-translate="esp-15"
                      >
                        {translate["less than Rs. 20,000/- per month"] ??
                          "less than Rs. 20,000/- per month"}
                      </option>
                      <option
                        value="less than Rs. 40,000/- per month"
                        data-translate="esp-16"
                      >
                        {translate["less than Rs. 40,000/- per month"] ??
                          "less than Rs. 40,000/- per month"}
                      </option>
                      <option
                        value="less than Rs. 60,000/- per month"
                        data-translate="esp-17"
                      >
                        {translate["less than Rs. 60,000/- per month"] ??
                          "less than Rs. 60,000/- per month"}
                      </option>
                      <option
                        value="less than Rs. 1,00,000/- per month"
                        data-translate="esp-17"
                      >
                        {translate["less than Rs. 1,00,000/- per month"] ??
                          "less than Rs. 1,00,000/- per month"}
                      </option>
                      <option
                        value="more than Rs. 1,00,000/- per month"
                        data-translate="esp-18"
                      >
                        {translate["more than Rs. 1,00,000/- per month"] ??
                          "more than Rs. 1,00,000/- per month"}
                      </option>
                    </select>
                    <p data-translate="esp-19">
                      {translate[
                        "In our efforts to address your outstanding dues, we'd like to know if a"
                      ] ??
                        "In our efforts to address your outstanding dues, we'd like to know if a"}{" "}
                      <strong>
                        {translate[
                          "one-time payment settlement is an option for you."
                        ] ??
                          "one-time payment settlement is an option for you."}
                      </strong>
                    </p>
                    <select
                      class="form-select mb-3"
                      aria-label="Default select example"
                      value={oneTime}
                      onChange={(e) => {
                        handleOneTime(e);
                      }}
                    >
                      <option value="" data-translate="esp-20">
                        {translate["Please Select"] ?? "Please Select"}
                      </option>
                      <option value="Yes" data-translate="esp-21">
                        {translate["Yes"] ?? "Yes"}
                      </option>
                      <option value="No" data-translate="esp-22">
                        {translate["No"] ?? "No"}
                      </option>
                    </select>
                    <label
                      htmlFor="relief-date"
                      className="form-label form-label-new mt-4 mx-2"
                      title="The Relief Date is the specific date on which relief or assistance is provided or expected."
                    >
                      {translate['Payment Date'] ?? 'Payment Date'}
                      <i
                        class="bi bi-info-circle mx-2"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-custom-class="custom-tooltip"
                        title="The Relief Date is the specific date on which relief or assistance is provided or expected."
                      ></i>
                    </label>
                    <input
                      className="form-select"
                      type="date"
                      id="relief-date"
                      name="relief-date"
                      value={relief}
                      onChange={(e) => setRelief(e.target.value)}
                    />
                  </div>

                  
                ) : (
                  ""
                )}
                {firstAsk === "No" ? (
                  <div class="settlement-options-no">
                    <p data-translate="esp-23">
                      {translate[
                        "We appreciate your feedback. If a one-time payment is not viable,"
                      ] ??
                        "We appreciate your feedback. If a one-time payment is not viable,"}{" "}
                      <strong>
                        {translate[
                          "could you consider arranging payment in three equal instalments?"
                        ] ??
                          "could you consider arranging payment in three equal instalments?"}
                      </strong>
                    </p>
                    <p data-translate="esp-24">
                      {translate[
                        "This approach could help ease any immediate financial strain while still addressing the outstanding dues."
                      ] ??
                        "This approach could help ease any immediate financial strain while still addressing the outstanding dues."}
                    </p>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      value={amountus}
                      onChange={(e) => {
                        handleAmountUs(e);
                      }}
                    >
                      <option value="" data-translate="esp-25">
                        {translate["Please Select"] ?? "Please Select"}
                      </option>
                      <option value="Amount with Yes" data-translate="esp-26">
                        {translate["Amount with Yes"] ?? "Amount with Yes"}
                      </option>
                      <option value="No" data-translate="esp-27">
                        {translate["No"] ?? "No"}
                      </option>
                    </select>

                    <label
                      htmlFor="relief-date"
                      className="form-label form-label-new mt-4 mx-2"
                      title="The Relief Date is the specific date on which relief or assistance is provided or expected."
                    >
                      Payment Date
                      <i
                        class="bi bi-info-circle mx-2"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-custom-class="custom-tooltip"
                        title="The Relief Date is the specific date on which relief or assistance is provided or expected."
                      ></i>
                    </label>
                    <input
                      className="form-select"
                      type="date"
                      id="relief-date"
                      name="relief-date"
                      value={relief}
                      onChange={(e) => setRelief(e.target.value)}
                    />
                  </div>
                ) : (
                  ""
                )}
                {firstAsk === "I am unable to pay by any means" ? (
                  <div class="unable-to-pay text-center mt-2">
                    <h4 data-translate="esp-28">
                      {translate["We Appreciate Your Honesty."] ??
                        "We Appreciate Your Honesty."}
                    </h4>
                  </div>
                ) : (
                  ""
                )}
                <div class="ms-auto">
                  <VerifyButton
                    btnName="Next"
                    onSubmit={handleSubmitToSave}
                    disabled={firstAsk === ""}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Settlement;
