import { useDispatch, useSelector } from "react-redux";
import VerifyButton from "../../loginPage/components/verifyButton";
import { changeStage } from "../../../features/disputeStage/disputeSlice";
import useLanguage from "../../../local/saveLanguage";

const Introduction = () => {
    const dispatch=useDispatch();
    const translate = useLanguage.getLang();
    const storedData = useSelector(state => state.disputeStage.data);
    const handleSubmit=()=>{
        dispatch(changeStage('disputeReview'));
    }
   return (
        <div class="login-mid">
          <div class="container">
            <div class="row">
              <div class="col-xl-7 col-lg-5 login-mid-left">
                <h2 data-translate="pvtcourt-info-1">{translate["Welcome to PrivateCourt, where Helping you is our priority."] ?? "Welcome to PrivateCourt, where Helping you is our priority."}</h2>                
                <p data-translate="pvtcourt-info-2">{translate["Now, let me introduce you to PrivateCourt – your reliable partner in resolving lending disputes effectively and efficiently."] ?? "Now, let me introduce you to PrivateCourt – your reliable partner in resolving lending disputes effectively and efficiently."}</p>
              </div>
              <div class="col-xl-5 col-lg-7">
                <div class="card pb-normal bank-verification">
                  <div class="vf-content">
                    <h1 data-translate="pvtcourt-info-3"><strong>{translate['PrivateCourt'] ?? "PrivateCourt"}:</strong> {translate['Your trusted partner in resolving lending disputes with care and expertise.'] ?? "Your trusted partner in resolving lending disputes with care and expertise."}</h1>
                    <p data-translate="pvtcourt-info-4">{translate["Welcome to PrivateCourt, where your case details are in good hands. With the lending company initiating arbitration or conciliation, it's essential for us to understand your situation fully. Please share all the details you can."] ?? "Welcome to PrivateCourt, where your case details are in good hands. With the lending company initiating arbitration or conciliation, it's essential for us to understand your situation fully. Please share all the details you can."}</p>
                    <p data-translate="pvtcourt-info-5">{translate["The more information we have, the better we can help. Your input is crucial in finding the right solution for you."] ?? "The more information we have, the better we can help. Your input is crucial in finding the right solution for you."} </p> 
                    <p data-translate="pvtcourt-info-6">{translate["Trust us to carefully examine your case and work together to find the best way forward."] ?? "Trust us to carefully examine your case and work together to find the best way forward."}</p>
                  </div>                  
                  <div class="ms-auto">
                  <VerifyButton btnName={`${translate["Next"]}` ?? "Next"} onSubmit={handleSubmit}/>    
                    {/* <script>
                      function openUrl() {
                        var urlToOpen = '/html/call-discussion/step-3.html';
                        window.open(urlToOpen, '_self');
                      }
                    </script>                    */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
   )
}

export default Introduction;