import {configureStore} from '@reduxjs/toolkit';
import themeReducer from "../features/theme/themeSlice";
import fontReducer from "../features/font/fontSlice";
import stageReducer from "../features/verificationStage/verificationSlice"
import langReducer from "../features/language/langSlice"
import emailReducer from "../features/email/emailSlice"
import disputeStage from "../features/disputeStage/disputeSlice"

const rootReducer = {
    theme: themeReducer,
    font: fontReducer,
    verificationStage:stageReducer,
    lang:langReducer,
    email:emailReducer,
    disputeStage:disputeStage
  };

export const store=configureStore({
    reducer:rootReducer
});