import React, { useState } from 'react';
import http from '../services/httpService.js';
import Papa from 'papaparse';
import auth from '../services/authService.js';
import axios from 'axios';
import useHandleModal from "./useModal1.js";
const CsvUploader = (props) => {
  const { handleModal2, handleModal3} = useHandleModal();
  let {type}=props;
  const [file, setFile] = useState(null);
  const [data, setData]=useState([]);
  const [flag, setFlag] = useState(true);
  const [sheetName, setSheetName]=useState("");
  // const user=auth.getUser().user;
  // const email=user.email;
  const userEmail='pratyush@privatecourt.in';


  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    // console.log(uploadedFile)
    setSheetName(uploadedFile.name);
    setFile(uploadedFile);
  };

  const handleSubmit = async () => {
    if (!file) {
      alert("Please select a CSV file.");
      return;
    }
    setFlag(false);
    const formData = new FormData();
    formData.append("csvFile", file);
    try {
      const now = new Date();
      const timeStamp = now.getTime();
       await axios.post("http://localhost:3004/api/addemail1", {
        userEmail,
        data: { csvData: data },
        timeStamp
      })
      .then((res)=>{
        console.log(res.data, "response");
        if (res.data === "Data Saved successfully") {
          setFlag(true);
          setSheetName("");
          handleModal3("CSV data uploaded Successfully");
          window.location.reload();
        } else {
          handleModal2("Failed to save data");
        }
      })
      .catch((err)=>{
        console.log(err);
      })
      const response2 = await axios.post("http://localhost:3004/api/setCsvInfo", {
        data: {
          email:userEmail,
          timeStamp,
          sheetName:sheetName
        },
      });
      console.log(response2.data, "response2");
     
    } catch (error) {
      setFlag(true);
      console.error("Error uploading CSV file:", error);
      alert("Failed to upload CSV file. Please try again.");
    }
  };

  const handleFileRead = (event) => {
    const csvData = event.target.result;
    const parsedData = Papa.parse(csvData, { header: true });
    console.log(parsedData.data, 'parseData');
    setData(parsedData.data);
  };

  const handleFileUpload = () => {
    if (!file) {
      alert('Please select a CSV file.');
      return;
    }
    const reader = new FileReader();
    reader.onloadend = handleFileRead;
    reader.readAsText(file);
  };

  return (
    <div className="container">
    <div className="row">
      <div className="col-md-6 offset-md-3">
        <h4 className="text-center m-4">CSV Uploader</h4>
        <div className="form-group m-2">
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="csv-file-input"
          />
          <label htmlFor="csv-file-input" className="btn btn-primary">Select CSV File
            {/* <button   >Select CSV File</button> */}
          </label>
        </div>
        <div className="form-group m-2" style={{ display: 'flex', alignItems: 'center' }}>
          <button
            className="btn btn-primary "
            disabled={!file}
            onClick={handleFileUpload}
          >
            Upload
          </button>
          <h6 className="mx-2 mt-2">{sheetName ? sheetName : ""}</h6>
        </div>
        <div className="form-group m-2">
          <button
            className="btn btn-primary "
            disabled={!file}
            onClick={handleSubmit}
          >
            Submit to MongoDB
          </button>
        </div>
      </div>
    </div>
  </div>
  );
};

export default CsvUploader;
