import React, { useState } from 'react';
import http from "../services/httpService";
import auth from "../services/authService";
import BrevoTemplate from '../Objects/template';
import axios from 'axios';
import useHandleModal from "./useModal1";
const AddEmailCombo = () => {
  const { handleModal2, handleModal3} = useHandleModal();
  const formDatas = {
    email: '',
    name:'',
    remarks:''
  }
  const [formData, setFormData] = useState(formDatas);
  const userEmail='pratyush@privatecourt.in';
  // const user=auth.getUser().user;
  // const email=user.email;
// console.log(BrevoTemplate)
  const handleSubmit = async () => {
    console.log(formData);
    try {
      await axios.post("http://localhost:3004/api/addemail1",{userEmail:userEmail,data:{csvData:[formData]},timeStamp:Date.now()})
      .then((res)=>{
        handleModal3("Email added Succesfully");
        setFormData(formDatas);
        window.location.reload();
      })
      .catch((err)=>{
        handleModal2("Data is not Added");
      })
    } catch (error) {
      console.error("Error uploading CSV file:", error);
      alert("Failed Please try again.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <label htmlFor="phone" className="form-label">Email:</label>
          <input
            type="email"
            value={formData.email}
            name="email"
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="col">
          <label htmlFor="template" className="form-label">Name:</label>
          <input
            type="text"
            value={formData.name}
            name="name"
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="col">
          <label htmlFor="uPhone" className="form-label">Remarks :</label>
          <input
            type="email"
            value={formData.remarks}
            name="remarks"
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className='col'>
            <br></br>
            <button className='btn btn-primary btn-sm mt-2' onClick={handleSubmit}>
                Save
            </button>
        </div>
      </div>
    </div>
  );
};

export default AddEmailCombo;
