function timestampToDate(timestamp) {
    // Create a new Date object from the timestamp (in milliseconds)
    const date = new Date(timestamp);
    
    // Get the day, month, and year from the date object
    const day = date.getDate();
    const month = date.getMonth() + 1; // Adding 1 because getMonth() returns 0-based month (0 = January)
    const year = date.getFullYear();
    
    // Format the date components into day/month/year format
    const formattedDate = `${day}/${month}/${year}`;
    
    // Return the formatted date
    return formattedDate;
}
export default timestampToDate;