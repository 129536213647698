import React, { useEffect, useState } from "react";
const AccountModal = (props) => {
  const [accounts,setAccounts] = useState([]);
  const TimelyPayment = (data) =>{
  let countSTD = 0;
  let totalPaymentStatus = 0;

  data.forEach(record => {
    if (record.PaymentStatus) {
      totalPaymentStatus++;
      if (record.PaymentStatus === "STD" ||record.PaymentStatus === "000") {
        countSTD++;
      }
    }
  });
  return {
    totalPaymentStatus,
    countSTD
  };
  }
  useEffect(() => {
      fetch('/CibilScore.json')
      .then(response => response.json())
      .then(data => {
        const filterAccounts = data.credit_report.CCRResponse.CIRReportDataLst[0].CIRReportData.RetailAccountDetails.map((item)=>({
            AccountNumber:item.AccountNumber,
            BankName:item.Institution,
            AccountType:item.AccountType,
            Active:item.Open,
            PendingAmount:item.Balance,
            LastPaymentDate:item.LastPaymentDate,
            history:item.History48Months
        }))
        setAccounts(filterAccounts);
      });
  }, []);
  const latePayment = props?.PaymentStatus.totalcount - props?.PaymentStatus.paidcount;
  const percent = props?.PaymentStatus.percent;
  return (
    <div
      className="modal fade"
      id="accountsmodal"
      tabIndex="-1"
      aria-labelledby="accountsmodal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Account Details</h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <div class="modal-body">
            <div class="card card-new">
              <div class="d-flex payment-header">
                <div class="payment-header-left w-50">
                  <h2 class="mb-0">{percent > 80 ?  'High' : percent > 60 ? 'Medium' : 'Low'} Impact</h2>
                  <p className="p-accounts">
                    {/* Last updated on <strong>13 Jun 2024</strong> */}
                  </p>
                </div>
                <div class="payment-header-right w-50 text-end  align-items-center">
                  <span class="badge text-bg-success mt-3">{percent > 80 ? 'Excellence' : percent > 60 ? 'Good' : percent > 50  ? 'Average' : 'Bad'}</span>
                </div>
              </div>
              <div class="d-flex mt-2">
                <div class="">
                  <h2 class="mb-0">{latePayment}</h2>
                  <p class="mb-0">Late Payment</p>
                </div>
                <div class="ps-5">
                  <h2 class="mb-0">{percent}%</h2>
                  <p class="mb-0">Payment on time</p>
                </div>
              </div>
            </div>
            <h4>Your Accounts</h4>
            {accounts.map((item,index)=> {
              const isClosed = item.Active !== "Yes";
              const { totalPaymentStatus, countSTD } = TimelyPayment(item.history);
              return (
              <div className={`d-flex border-bottom pb-3 pt-3 ${isClosed ? 'blur' : ''}`}>
              <div class="flex-shrink-0">
                <img src="https://placehold.co/80x80/EEE/31343C" alt="..." />
              </div>
              <div class="flex-grow-1 ms-3">
                <h4 class="mb-0">{item.BankName}</h4>
                <p class="mb-0 text-muted">{item.AccountType}: {item.AccountNumber}</p>
                <p class="text-muted mb-0">
                  Timily Payment: <strong class="text-primary">{countSTD}/{totalPaymentStatus}</strong>
                </p>
                <p class="mb-0 text-muted">
                  Status: <strong class="text-primary">{item.Active == "Yes" ? "Active" : "closed"}</strong>
                </p>
              </div>
            </div>
              );
            })}     
            {/* <div class="d-flex border-bottom pb-3 pt-3">
              <div class="flex-shrink-0">
                <img src="https://placehold.co/80x80/EEE/31343C" alt="..." />
              </div>
              <div class="flex-grow-1 ms-3">
                <h4 class="mb-0">SBI</h4>
                <p class="mb-0 text-muted">Home Loan: ****1867</p>
                <p class="mb-0 text-muted">
                  Status: <strong class="text-muted">Closed</strong>
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountModal;
