import { useSelector } from 'react-redux';
import processLoader from '../../images/process-loader.gif'
import useLanguage from "../../local/saveLanguage";

const DataCompilation=()=>{
    const translate = useLanguage.getLang();
    console.log(translate)
    return (
        <div class="too-many-request">
        <div class="container">
        <div class="row h-auto">
                <div class="col-lg-6">
                    <h1 data-translate="tran-1">{translate ?.["Data Compilation in Progress: Please Allow Processing Time"] ?? "Data Compilation in Progress: Please Allow Processing Time" }</h1>
                    <p data-translate="tran-2">{translate ?.["Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile."] ?? "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile."}</p>
                    <p data-translate="tran-3">{translate ?.["This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process."] ?? "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process."}</p>
                    <p data-translate="tran-4">{translate ?.["Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience."] ?? "Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience."}</p>
                    <p data-translate="tran-5">{translate ?.["For urgent matters or assistance, feel free to call us at +91-9699900111."] ?? "For urgent matters or assistance, feel free to call us at +91-9699900111."}</p>
                    <p><strong data-translate="tran-6">{translate?.["Thank you for your patience and entrusting PrivateCourt. Your security is our priority."] ?? "Thank you for your patience and entrusting PrivateCourt. Your security is our priority."}</strong></p>
                </div>
                <div class="col-lg-6 align-self-center">
                    <img class="img-fluid" src={processLoader} alt=""/>
                </div>
            </div>
            </div>
            </div>
    )
}
export default DataCompilation;