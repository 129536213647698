import Cookies from 'js-cookie';
const keyName1 = "user";
const keyName2 = 'attempts';

function login(obj) {
  let expTime=1*24*60*60*1000;
  const expirationDate = new Date(Date.now() + expTime);
  Cookies.set(keyName1, obj, { expires: expirationDate, path: '/' });
}

function logout() {
  Cookies.remove(keyName1);
}

function getUser() {
  let token = Cookies.get(keyName1);
    try{
        const [header, payload, signature] = token.split(".");
        const decodedPayload = JSON.parse(atob(payload));
        return decodedPayload;
    }
    catch(err){
        console.log(err);
    }
  return false;
}

export default {
  login,
  logout,
  getUser,
};