import { useRef } from 'react';
import Swal from 'sweetalert2';

const useHandleModal = () => {
  const flagRef = useRef(false); // Initialize flagRef with an initial value
  let timerInterval;

  const handleModal = (flag) => {
    // Update flagRef whenever flag changes
    flagRef.current = flag;

    Swal.fire({
      title: "",
      html: "",
      timerProgressBar: false,
      allowOutsideClick: false,
      backdrop: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          if (flagRef.current) {
            clearInterval(timerInterval);
            Swal.close();
          }
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    });
  };

  const handleModal2 = (str="") => {
    let timerInterval;
    Swal.fire({
      icon: "error",
      title: "",
      showConfirmButton: false,
      timer: 2000,
      footer: str,
      didOpen: () => {
        timerInterval = setInterval(() => {}, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
      customClass: {
        popup: "invisible-modal",
      },
    }).then((result) => {
    //   if (result.dismiss === Swal.DismissReason.timer) {
    //     console.log("");
    //   }
    console.log("Modal closed by user interaction");
    });
  };

  const handleModal3 = (str) => {
    let timerInterval;
    Swal.fire({
    //   position: "top-end",
      icon: "success",
      title:str,
      showConfirmButton: false,
      timer: 1500,
      didOpen: () => {
        timerInterval = setInterval(() => {}, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
      customClass: {
        popup: "invisible-modal",
      },
    }).then((result) => {
    //   if (result.dismiss === Swal.DismissReason.timer) {
    //     console.log("");
    //   }
    console.log("Modal closed by user interaction");
    });
  };

  return {handleModal, handleModal2, handleModal3};
};

export default useHandleModal;
